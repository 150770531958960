import { useMemo } from 'react';
import classNames from 'classnames';
import Grid from '@mui/material/Grid';

import useInvoiceItemStyle from './style';
import Price from '../Format';
import { Bill, Discount, Dollar, ItemBill, ItemPercent, LoyaltyDiscountIcon, Percent, Rial, Yen } from '../../svg';
import type { IKeyValue, IKeyValueOrder } from '../../types';
import { ColorEnum, IconEnum, StyleEnum } from '../../types';

export interface IInvoiceItemProps {
    val: IKeyValue | IKeyValueOrder;
    currencyCode: string;
    currencySymbol: string;
    currencyPrecision?: number;
    lang: string | null;
    type?: 'bill' | 'order' | 'topping' | 'split';
    isNew?: boolean;
    selected?: boolean;
    onClick?: (item: IKeyValueOrder) => void;
    Extra?: any;
    useBillItemTranslation?: (lang: any) => any;
    useOrderItemTranslation?: (lang: any) => any;
}

const iconColor = '#EBECF2';

export default function InvoiceItem({
    val,
    currencyCode,
    currencySymbol,
    currencyPrecision,
    lang,
    type,
    isNew,
    selected,
    onClick,
    Extra,
    useBillItemTranslation,
    useOrderItemTranslation,
}: IInvoiceItemProps) {
    const {
        invoiceItem,
        colorPrimary,
        colorSecondary,
        colorHighEmphasis,
        colorMediumEmphasis,
        colorLowEmphasis,
        colorBackground,
        colorGreen,
        styleTitleMedium,
        styleTitleSmall,
        styleBodyMedium,
        styleBodySmall,
        styleStrike,
    } = useInvoiceItemStyle();
    const billMode = !type || type === 'bill';
    const { getTrans } = (billMode ? useBillItemTranslation?.(lang) : useOrderItemTranslation?.(lang)) || {
        getTrans: undefined,
    };

    const newAltDesign = useMemo(() => {
        if (!val.layout?.format) {
            return true;
        }
        return val.layout.format.includes('f');
    }, [val.layout?.format]);

    const getIcon = () => {
        switch (val.layout?.icon) {
            case IconEnum.Dollar:
                return <Dollar color={iconColor} />;
            case IconEnum.Yen:
                return <Yen color={iconColor} />;
            case IconEnum.Rial:
                return <Rial color={iconColor} />;
            case IconEnum.Percent:
                return <Percent color={iconColor} />;
            case IconEnum.Bill:
                return <Bill color={iconColor} />;
            case IconEnum.Discount:
                return <Discount color={iconColor} />;
            case IconEnum.ItemPercent:
                return <ItemPercent color={iconColor} />;
            case IconEnum.ItemBill:
                return <ItemBill color={iconColor} />;
            case IconEnum.LoyaltyDiscount:
                return <LoyaltyDiscountIcon color={iconColor} />;
            default:
                if (type === 'topping' || type === 'order') {
                    let qty = (val as IKeyValueOrder).qty || '0';
                    qty = Number(qty) === Math.floor(Number(qty)) ? Number(qty).toFixed(0) : qty;
                    return (
                        <div className={classNames('itemQty', type === 'topping' && 'itemQtyTopping')}>
                            {qty !== '0' ? (type === 'order' ? `${qty}x` : qty) : ''}
                        </div>
                    );
                }
                if (type === 'split') {
                    return <div className="splitItemQty">1</div>;
                }
                return null;
        }
    };

    const getStyleClass = () => {
        switch (val.layout?.style) {
            case StyleEnum.TitleMedium:
                return styleTitleMedium;
            case StyleEnum.TitleSmall:
                return styleTitleSmall;
            case StyleEnum.BodyMedium:
                return styleBodyMedium;
            case StyleEnum.BodySmall:
                return styleBodySmall;
            case StyleEnum.BodyMediumStrike:
                return { ...styleBodyMedium, ...styleStrike };
            case StyleEnum.BodySmallStrike:
                return { ...styleBodySmall, ...styleStrike };
            default:
                return styleBodyMedium;
        }
    };

    const getColorClass = () => {
        switch (val.layout?.color) {
            case ColorEnum.Primary:
                return colorPrimary;
            case ColorEnum.Secondary:
                return colorSecondary;
            case ColorEnum.HighEmphasis:
                return colorHighEmphasis;
            case ColorEnum.MediumEmphasis:
                return colorMediumEmphasis;
            case ColorEnum.LowEmphasis:
                return colorLowEmphasis;
            case ColorEnum.Background:
                return colorBackground;
            case ColorEnum.Green:
                return colorGreen;
            default:
                return type === 'topping' ? colorLowEmphasis : null;
        }
    };

    const getTitle = () => {
        if (billMode) {
            return getTrans(val.key, val.title, val.layout?.locales, val.layout?.useTitle);
        }
        return (
            (lang && (val as IKeyValueOrder).titleTrans?.[lang]) ||
            (val as IKeyValueOrder).title ||
            getTrans(val.key, val.title, val.layout?.locales, val.layout?.useTitle)
        );
    };

    const clickHandler = () => {
        onClick?.(val);
    };

    return (
        <Grid
            container
            sx={[invoiceItem, getColorClass(), getStyleClass()]}
            className={classNames(
                type === 'topping' && 'topping',
                isNew && 'newRow',
                selected && 'selectedStyle',
                type === 'split' && selected && 'paid',
                type !== 'split' && selected && 'styleStrike',
                type !== 'split' && selected && 'styleStrikeLabel',
            )}
            onClick={clickHandler}
            justifyContent="space-between"
        >
            <Grid item className="billTitle">
                {type !== 'split' && <div className="iconContainer">{getIcon()}</div>}
                <span>{getTitle()}</span>
            </Grid>
            <div className="priceContainer">
                {!val.layout?.hideAltValue && (type === 'topping' || (type === 'order' && !newAltDesign)) && (
                    <Grid
                        item
                        className={classNames('itemValue', 'noWrap', {
                            altValue: !newAltDesign,
                            toppingAltValue: type === 'topping',
                        })}
                    >
                        <Price
                            format={val.layout?.format}
                            value={(val as IKeyValueOrder).altValue}
                            currencyPrecision={currencyPrecision}
                            params={{
                                cs: currencySymbol,
                                cc: currencyCode,
                            }}
                        />
                    </Grid>
                )}
                <Grid item className={classNames('itemValue', 'finalPrice', 'noWrap', type === 'split' && 'split')}>
                    <Price
                        bold
                        format={val.layout?.format}
                        value={type === 'split' ? (val as IKeyValueOrder).secondAltValue : val.value}
                        currencyPrecision={currencyPrecision}
                        params={{
                            cs: currencySymbol,
                            cc: currencyCode,
                        }}
                    />
                </Grid>
                {Boolean(Extra) && (
                    <Grid item className="itemExtraStyle">
                        {Extra}
                    </Grid>
                )}
            </div>
            {!billMode && !val.layout?.hideAltValue && type !== 'split' && type !== 'topping' && newAltDesign && (
                <Grid
                    item
                    md={12}
                    xs={12}
                    className={classNames('itemValue', 'noWrap', 'altValue', {
                        newAlt: newAltDesign,
                    })}
                    sx={{ pt: 1 }}
                >
                    <Price
                        format={val.layout?.format}
                        value={(val as IKeyValueOrder).altValue}
                        currencyPrecision={currencyPrecision}
                        params={{
                            cs: currencySymbol,
                            cc: currencyCode,
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
}
