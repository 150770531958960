import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Warning from '@mui/icons-material/Warning';

import { useTranslation } from '@/hooks/translations';
import CustomModal from '@/components/CustomModal';
import AuthService from '@/services/auth';
import { useRestaurantContext } from '@/contexts/restaurant';

const BrandVerificationBanner = ({ brandId }: { brandId?: string }) => {
    const { t } = useTranslation('common');
    const { enqueueSnackbar } = useSnackbar();
    const authService = AuthService.getInstance();
    const { palette } = useTheme();
    const { restaurant, setRestaurant } = useRestaurantContext();

    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                px={3}
                sx={{
                    width: '100%',
                    backgroundColor: palette.warning.light,
                    color: palette.common.white,
                    fontWeight: 500,
                    fontSize: 14,
                }}
            >
                <Warning sx={{ marginInlineEnd: 2 }} />
                <p>
                    {t(`You haven't verified your email`)}
                    <Box
                        component="span"
                        sx={{
                            marginInlineStart: 0.5,
                            textDecoration: 'underline',
                            ':hover': {
                                cursor: 'pointer',
                                color: palette.primary.main,
                            },
                        }}
                        onClick={() => setOpen(true)}
                    >
                        {t('Click here to view details and verify')}
                    </Box>
                </p>
            </Stack>
            <CustomModal
                open={open}
                title={t('Send verification email')}
                actionText={t('Send')}
                maxWidth="sm"
                actionMethod={() => {
                    if (!brandId) {
                        return;
                    }

                    authService
                        .verify(brandId)
                        .then(() => {
                            enqueueSnackbar(t('Verification email sent successfully'), { variant: 'success' });
                            setOpen(false);
                            if (restaurant) {
                                setRestaurant({
                                    ...restaurant,
                                    brand_verification_sent: true,
                                });
                            }
                        })
                        .catch((e) => {
                            enqueueSnackbar(t('Error sending verification email. Please try again later'), {
                                variant: 'error',
                            });
                            console.warn(e);
                        });
                }}
                onClose={() => setOpen(false)}
                keepMounted
            >
                {t('We will sent an email to {{email}} with a verification link', {
                    email: restaurant?.brand?.email || '',
                })}
            </CustomModal>
        </>
    );
};

export default BrandVerificationBanner;
