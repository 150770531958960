import axios from '@/config/axios';
import { transformError } from '.';

export interface IRefundParams {
    amount: number;
    countryCode: string;
    gatewayVendor: string;
    reference: string;
    refundReason: string;
    restaurantUnique: string;
    paymentRecordId: string;
    restaurantId: string;
}

const endpoints = {
    REFUND: 'vendor/refund/:restaurant_id',
    REFUND_CONFIG: 'vendor/refund/config/:restaurant_id',
};

class RefundService {
    restaurantId = localStorage.getItem('restaurantId') || '';

    public static getInstance() {
        if (!this.instance) {
            this.instance = new RefundService();
        }

        return this.instance;
    }

    private static instance: RefundService;

    public async makeRefund({ ...params }: IRefundParams) {
        try {
            const res = await axios.post<IRefundParams>(
                endpoints.REFUND.replace(':restaurant_id', params.restaurantId),
                {
                    amount: params.amount,
                    countryCode: params.countryCode,
                    gatewayVendor: params.gatewayVendor,
                    reference: params.reference,
                    refundReason: params.refundReason,
                    restaurantUnique: params.restaurantUnique,
                    id: params.paymentRecordId,
                },
            );
            return res.data;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async getRefundConfig(restaurantId: string) {
        try {
            const res = await axios.get(endpoints.REFUND_CONFIG.replace(':restaurant_id', restaurantId));
            return res.data?.data;
        } catch (err) {
            throw transformError(err);
        }
    }
}

export default RefundService;
