import { FC, ReactNode, useCallback, useMemo } from 'react';
import { merge } from 'merge';
import { SxProps, TypographyProps } from '@mui/material';
import Typography from '../Typography';
import usePriceStyle from './style';
import type { typography } from '../Typography/typographyTypo';

interface IPart {
    type: 'text' | 'format';
    text: string;
}

export const processFormat = (
    str: string,
    params: { [key: string]: string | JSX.Element | undefined },
): Array<string | ReactNode> => {
    const found: IPart[] = [];
    const rxp = /{([^}]+)}/g;
    let curMatch: RegExpExecArray | null = null;
    let lastLength = 0;
    // eslint-disable-next-line no-cond-assign
    while ((curMatch = rxp.exec(str))) {
        if (curMatch.index !== 0 && found.length === 0) {
            found.push({
                type: 'text',
                text: str.substring(0, curMatch.index),
            });
        } else if (lastLength !== str.length && lastLength !== curMatch.index) {
            found.push({
                type: 'text',
                text: str.substring(lastLength, curMatch.index),
            });
        }
        found.push({
            type: 'format',
            text: curMatch[1],
        });
        lastLength = curMatch.index + curMatch[0].length;
    }
    if (lastLength !== str.length) {
        found.push({
            type: 'text',
            text: str.substring(lastLength),
        });
    }
    return found.map((item) => {
        if (item.type === 'text') {
            return item.text;
        }
        return params[item.text] || item.text;
    });
};

export interface IProps extends TypographyProps {
    format?: string | null;
    params: {
        cs?: string;
        cc?: string;
    };
    currencyPrecision?: number;
    value?: string;
    typo?: keyof typeof typography;
    bold?: boolean;
    secondStyle?: boolean;
    sx?: SxProps;
    zeroPriceType?: ZeroTypeEnum;
}
export enum ZeroTypeEnum {
    Zero = 'zero',
    Free = 'free',
    Null = 'null',
}

const Symbol = (val?: string) => {
    return <Typography component="span">{val}</Typography>;
};

export const Format: FC<IProps> = ({
    value,
    typo,
    currencyPrecision = 2,
    sx,
    zeroPriceType,
    params,
    format,
    bold,
    secondStyle,
}) => {
    const { container, boldPrice } = usePriceStyle(secondStyle);
    const { cc, cs } = params;

    const formatValue = useCallback(
        (val: any) => {
            if (!val) {
                return null;
            }
            if (val === '0') {
                return String(
                    Number(val)?.toLocaleString('en-US', {
                        minimumIntegerDigits: currencyPrecision,
                        minimumFractionDigits: currencyPrecision,
                    }),
                );
            }
            return String(Number(value)?.toFixed(currencyPrecision));
        },
        [value, currencyPrecision],
    );

    const node = useMemo(() => {
        if (!value) {
            return null;
        }
        if (value === '0' && zeroPriceType === ZeroTypeEnum.Null) {
            return '';
        }
        if (value === '0' && zeroPriceType === ZeroTypeEnum.Free) {
            return 'Free';
        }
        const newValue = formatValue(value) || '';
        const f = format || '{csf}{0}';

        const elem = processFormat(f, {
            '0': newValue,
            cc,
            cs,
            csf: Symbol(cs),
            ccf: Symbol(cc),
        });

        return elem || newValue || '';
    }, [value, format, cs, cc]);

    return (
        <Typography sx={merge(true, container, bold && boldPrice, sx)} component="span" typo={typo}>
            {node}
        </Typography>
    );
};
export default Format;
