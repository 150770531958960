import { SVGProps } from 'react';
import { Gb, Jp, Pt, Ar, Tr, Es, Cn, Kr } from 'react-flags-select';

export const countries = ['ae', 'jp', 'tr', 'br'];

export const supportedLanguages = ['en', 'ja', 'pt', 'ar', 'tr', 'zh', 'es', 'kr'];

interface Language {
    value: string;
    title: any;
    icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
}

export const getIcon = (value: string) => {
    switch (value) {
        case 'en':
            return Gb;
        case 'ar':
            return Ar;
        case 'de':
            return Gb;
        case 'ja':
            return Jp;
        case 'pt':
            return Pt;
        case 'tr':
            return Tr;
        case 'zh':
            return Cn;
        case 'es':
            return Es;
        case 'kr':
            return Kr;
        default:
            return Gb;
    }
};

export const useLanguage = () => {
    const getTitle = (value: string) => {
        switch (value) {
            case 'en':
                return 'English';
            case 'ar':
                return 'عربي';
            case 'de':
                return 'Deutsch';
            case 'ja':
                return '日本';
            case 'pt':
                return 'Português';
            case 'tr':
                return 'Türkçe';
            case 'zh':
                return '中文';
            case 'es':
                return 'Español';
            case 'kr':
                return '한국어';
            default:
                return null;
        }
    };

    const languages: Language[] = supportedLanguages.map((o) => ({
        value: o,
        title: getTitle(o),
        icon: getIcon(o),
    }));

    return {
        languages,
    };
};
