import { ConditionEnum, IKeyValue } from '../../types';

export const checkVisibilityCondition = (val: IKeyValue, qlubDiscount?: string) => {
    const qd = Number(qlubDiscount || '0');
    const v = Number.isNaN(val.value || '0') ? 1 : Number(val.value || '0');
    switch (val?.layout?.vcond || ConditionEnum.Default) {
        default:
        case ConditionEnum.Default:
            return true;
        case ConditionEnum.GT:
            return v > 0;
        case ConditionEnum.GTE:
            return v >= 0;
        case ConditionEnum.LT:
            return v < 0;
        case ConditionEnum.LTE:
            return v < 0;
        case ConditionEnum.E:
            return v === 0;
        case ConditionEnum.NE:
            return v !== 0;
        case ConditionEnum.GTD:
            return v > 0 && qd > 0;
    }
};
