import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useLanguageQuery, useTranslation } from 'next-export-i18n';
import DataTable from 'react-data-table-component';
import { cloneDeep, debounce, uniqBy } from 'lodash';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import EventBus from '@/config/event-handler';
import { useRestaurantContext } from '@/contexts/restaurant';
import { vendorOrderEvent } from '@/contexts/event.const';
import { useUserContext } from '@/contexts/user';
import QsrOrdersService from '@/services/qsrOrders';
import { timeUnix } from '@/services/utils/k_time';
import RestaurantService from '@/services/restaurant';
import { covertUtcToRestaurantDateTime, getCurrencyWithAmount } from '@/common/utility';
import { IOrderNotificationEvent } from '@/views/Payments';

import QsrGridView from '@/components/QsrOrders/qsrGridView';
import { type ITimeRangeForm, TimeRangeTypes } from '@/components/TimeRange/Form/types';
import { IRestaurant, ISelectedTable, ITableParams, OrderPriceRoundingModeEnum } from '@/views/OrdersTableView/types';
import { IOrderItemStatus } from '@/components/QsrOrderAction';
import { PageTitle, Section } from '@/components/common';
import { timeRangeTitles } from '@/components/TimeRange/Form/hooks/useData';
import QsrOrdersDetails from '@/components/QsrOrderDetails';
import KKTablePagination from '@/components/Table/pagination';
import QSREditOrder, { IQSREditOrderFunctions, QSREditOrderType } from '@/components/QSREditOrder';
import QsrFilterChip from '@/components/QsrFilterChip';

import { useSnackbar } from 'notistack';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useColumns } from './useColumns';
import { IQsrOrder, IQsrOrderNotification, OrderPaymentStatusEnum, OrderPosStatusEnum, OrderStatusEnum } from './types';
import { getIcon, useFilters } from './useFilters';

export function convertOrderToTableRow(
    orderItem: IQsrOrder,
    lang: string,
    currencyCode: string,
    currencySymbol: string,
    roundingMode?: OrderPriceRoundingModeEnum,
): IQsrOrderNotification {
    const date =
        lang !== 'ja'
            ? covertUtcToRestaurantDateTime(orderItem.createdAt, 'MM/DD/YYYY HH:mm a')
            : covertUtcToRestaurantDateTime(orderItem.createdAt, 'YYYY/MM/DD HH:mm');

    return {
        ...orderItem,
        totalStr: getCurrencyWithAmount(orderItem.total, currencyCode, currencySymbol, true, roundingMode),
        subTotalStr: orderItem.subTotal
            ? getCurrencyWithAmount(orderItem.subTotal, currencyCode, currencySymbol, true, roundingMode)
            : undefined,
        date,
    };
}

const transformOrders = (
    orders: IQsrOrderNotification[],
    qrMap: {
        [key: string]: string;
    },
    defaultStatus?: OrderStatusEnum,
) => {
    return uniqBy(orders, 'id').map((o) => ({
        ...o,
        orderStatus: o.orderStatus || { name: defaultStatus || OrderStatusEnum.Pending },
        ...(o.urlData
            ? {
                  urlData: {
                      ...o.urlData,
                      name:
                          o.urlData.name ||
                          qrMap[`${o.urlData.tableId || '_'}/${o.urlData.f1 || '_'}/${o.urlData.f2 || '_'}`],
                  },
              }
            : {}),
    }));
};

const getQrNameMap = (list: ITableParams[]) => {
    return list.reduce<{ [key: string]: string }>((acc, curr) => {
        acc[`${curr.id}/${curr.f1 || '_'}/${curr.f2 || '_'}`] = curr.name || curr.id;
        return acc;
    }, {});
};

interface IOrderOptions {
    statuses: OrderStatusEnum[];
    notInStatuses: OrderStatusEnum[];
    paymentStatuses: OrderPaymentStatusEnum[];
    posStatuses: OrderPosStatusEnum[];
    defaultStatus?: OrderStatusEnum;
}

const trimOrders = (
    orders: IQsrOrderNotification[],
    qrMap: { [key: string]: string },
    { statuses, notInStatuses, paymentStatuses, posStatuses, defaultStatus }: IOrderOptions,
) => {
    if (
        statuses.length === 0 &&
        notInStatuses.length === 0 &&
        paymentStatuses.length === 0 &&
        posStatuses.length === 0
    ) {
        return transformOrders(orders, qrMap, defaultStatus);
    }
    return transformOrders(
        orders.filter((o) => {
            return (
                (statuses.length === 0 ||
                    (statuses.length > 0 && o.orderStatus?.name && statuses.includes(o.orderStatus.name))) &&
                (notInStatuses.length === 0 ||
                    (notInStatuses.length > 0 && o.orderStatus?.name && !notInStatuses.includes(o.orderStatus.name))) &&
                (paymentStatuses.length === 0 ||
                    (paymentStatuses.length > 0 && paymentStatuses.includes(o.paymentStatus))) &&
                (posStatuses.length === 0 || (posStatuses.length > 0 && posStatuses.includes(o.posStatus)))
            );
        }),
        qrMap,
        defaultStatus,
    );
};

export enum QSRCardModeEnum {
    Normal = 'normal',
    Pending = 'pending',
    NotPending = 'notPending',
    Failed = 'failed',
    Scheduled = 'scheduled',
}

interface IProps {
    mode: QSRCardModeEnum;
    restaurant: IRestaurant;
    view: string;
    timeRange: ITimeRangeForm;
    selectedStatus: OrderStatusEnum[];
    notInSelectedStatus?: OrderStatusEnum[];
    paymentStatus: OrderPaymentStatusEnum[];
    posStatus: OrderPosStatusEnum[];
    refId?: string;
    refreshTrigger?: number;
    hide?: boolean;
    qsrEditOrderRef: QSREditOrderType;
    onReloadOrder: (refId: string) => Promise<any>;
    selectedTable?: ISelectedTable;
    isTableAvailable: (order: IQsrOrder) => boolean;
}

interface GetOrderOptions {
    isRefresh?: boolean;
    noLoading?: boolean;
    forceRequest?: boolean;
}

function QsrOrdersInner({
    mode,
    restaurant,
    view,
    timeRange,
    selectedStatus,
    notInSelectedStatus,
    paymentStatus,
    posStatus,
    refId,
    refreshTrigger,
    hide,
    qsrEditOrderRef,
    onReloadOrder,
    selectedTable,
    isTableAvailable,
}: IProps) {
    const priceRoundingMode = restaurant?.order_config?.priceRoundingMode;
    const { t } = useTranslation('common');
    const [query] = useLanguageQuery();
    const theme = useTheme();
    const lang = query ? query.lang : 'en';
    const qsrOrdersService = QsrOrdersService.getInstance();
    const restaurantService = RestaurantService.getInstance();
    const { user } = useUserContext();
    const [data, setData] = useState<IQsrOrderNotification[]>([]);
    const [loading, setLoading] = useState(true);
    const loadingRef = useRef(false);
    const [selectedRow, setSelectedRow] = useState<IQsrOrder | null>(null);
    const [qrNameMap, setQrNameMap] = useState<{ [key: string]: string }>({});
    const columns = useColumns({
        setSelectedRow,
        currencySymbol: restaurant?.restaurant_country?.currency_symbol || '',
        currencyCode: restaurant?.restaurant_country?.currency_code || '',
        priceRoundingMode,
    });
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [totalRow, setTotalRow] = useState<number>(0);
    const { isReady } = useRouter();
    const lastUpdateRef = useRef(0);
    const isFirstRender = useRef(true);
    const fetchFnRef = useRef<any>(null);
    const [displayedColumns, setDisplayedColumns] = useState(columns);
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const tablePageChangeHandler = (p: number) => {
        setPage(p);
    };

    const tableRowsPerPageChangeHandler = (val: number) => {
        setLimit(val);
    };

    const getQrs = (): Promise<{ [key: string]: string }> => {
        if (!restaurant || Object.keys(qrNameMap).length > 0) {
            return Promise.resolve(qrNameMap);
        }

        return restaurantService.getQrs(restaurant.id).then((res) => {
            const qrm = getQrNameMap(res.rows.map((o) => o.params) || []);
            setQrNameMap(qrm);
            return qrm;
        });
    };

    const filterStr = JSON.stringify([
        selectedStatus,
        notInSelectedStatus,
        paymentStatus,
        posStatus,
        timeRange,
        query,
        selectedTable,
    ]);

    const getOrderHandler = useCallback(
        debounce(({ noLoading = false, forceRequest = false }: GetOrderOptions) => {
            const { ids: qrIds } = selectedTable || {};

            if (!forceRequest) {
                loadingRef.current = false;
            }

            if (!restaurant || loadingRef.current) {
                return;
            }

            fetchFnRef.current = null;

            loadingRef.current = true;
            if (!noLoading) {
                setLoading(true);
            }
            setData([]);

            Promise.all([
                qsrOrdersService.getQsrOrders({
                    restaurantId: restaurant.id,
                    page: refId ? 0 : page - 1,
                    limit,
                    refId,
                    ...(selectedStatus.length > 0 ? { statuses: selectedStatus } : {}),
                    ...(notInSelectedStatus?.length ? { notInStatuses: notInSelectedStatus } : {}),
                    ...(paymentStatus?.length ? { paymentStatuses: paymentStatus } : {}),
                    ...(posStatus?.length ? { posStatuses: posStatus } : {}),
                    ...(timeRange && { startDate: timeRange.from, endDate: timeRange.to }),
                    ...(qrIds?.length ? { qrIds: qrIds.join(',') } : {}),
                }),
                getQrs(),
            ])
                .then(([res, qrMap]) => {
                    lastUpdateRef.current = timeUnix();
                    const orderList =
                        res.orders
                            ?.map((item) =>
                                convertOrderToTableRow(
                                    item,
                                    lang,
                                    restaurant?.restaurant_country?.currency_symbol || '',
                                    restaurant?.restaurant_country?.currency_code || '',
                                    restaurant?.order_config?.priceRoundingMode || OrderPriceRoundingModeEnum.Round,
                                ),
                            )
                            .sort((a: any, b: any) => {
                                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                            }) || [];

                    const defaultOrderStatus = restaurant?.order_config?.defaultOrderStatus;
                    setData(
                        trimOrders(orderList, qrMap, {
                            statuses: selectedStatus || [],
                            notInStatuses: notInSelectedStatus || [],
                            paymentStatuses: paymentStatus || [],
                            posStatuses: posStatus || [],
                            defaultStatus: defaultOrderStatus,
                        }),
                    );
                    setTotalRow(res?.pagination?.count || 0);
                })
                .catch((err: any) => {
                    console.log(err);
                })
                .finally(() => {
                    if (!noLoading) {
                        setLoading(false);
                    }
                    loadingRef.current = false;
                    isFirstRender.current = false;
                });
        }, 500),
        [restaurant, page, limit, refId, filterStr],
    );

    useEffect(() => {
        if (!isReady) {
            return;
        }

        getOrderHandler({
            forceRequest: true,
        });
    }, [restaurant.id, isReady, page, limit, refId, filterStr]);

    useEffect(() => {
        if (!isReady) {
            return;
        }

        setTimeout(() => {
            getOrderHandler?.flush();
        }, 50);
    }, [page, limit, refId]);

    useEffect(() => {
        if (isFirstRender.current) {
            return;
        }

        getOrderHandler({
            isRefresh: true,
        });

        getOrderHandler.flush();
    }, [refreshTrigger]);

    useEffect(() => {
        if (
            (!user || (user.userData.role || '').indexOf('Vendor') === -1 || page > 1) &&
            !restaurant?.order_config?.vendorEditOrderEnable
        ) {
            return () => {
                //
            };
        }

        const fetchDataHandler = ({ detail: msgList }: IOrderNotificationEvent) => {
            lastUpdateRef.current = timeUnix();
            setData((li) => {
                const tempLi = cloneDeep(li);
                msgList.forEach((msg) => {
                    if (!isTableAvailable(msg)) {
                        return;
                    }
                    const order = convertOrderToTableRow(
                        msg,
                        lang,
                        restaurant?.restaurant_country?.currency_symbol || '',
                        restaurant?.restaurant_country?.currency_code || '',
                        restaurant?.order_config?.priceRoundingMode || OrderPriceRoundingModeEnum.Round,
                    );
                    const idx = tempLi.findIndex((o) => o.id === order.id);
                    if (idx > -1) {
                        tempLi[idx] = { ...tempLi[idx], ...order };
                    } else {
                        tempLi.unshift(order);
                    }
                });
                return trimOrders(tempLi, qrNameMap, {
                    statuses: selectedStatus || [],
                    notInStatuses: notInSelectedStatus || [],
                    paymentStatuses: paymentStatus || [],
                    posStatuses: posStatus || [],
                    defaultStatus: restaurant?.order_config?.defaultOrderStatus,
                });
            });
        };

        EventBus.on(vendorOrderEvent, fetchDataHandler);
        return () => {
            EventBus.remove(vendorOrderEvent, fetchDataHandler);
        };
    }, [user, page, restaurant, lang, qrNameMap, isTableAvailable, filterStr]);

    useEffect(() => {
        if (!restaurant || Number(restaurant.order_config?.fetchOrderInterval || 0) <= 0) {
            return () => {
                //
            };
        }
        const intervalAmount = Math.max(restaurant?.order_config?.fetchOrderInterval || 0, 10);
        const interval = setInterval(() => {
            if (timeUnix() - lastUpdateRef.current < intervalAmount * 1.5) {
                return;
            }
            console.info('reload with interval');
            getOrderHandler({
                isRefresh: true,
                noLoading: true,
            });
        }, intervalAmount * 1000);
        return () => {
            clearInterval(interval);
        };
    }, [restaurant]);

    const batchChangeHandler = (payload: IOrderItemStatus) => {
        setData((li) => {
            const cloneList = cloneDeep(li);
            const idx = cloneList.findIndex((o) => o.id === payload.id);
            if (idx > -1 && cloneList?.[idx]) {
                cloneList[idx].orderData.items = cloneList[idx].orderData?.items?.map((item) => {
                    if (item.uid === payload.uid) {
                        return {
                            ...item,
                            status: payload.status,
                        };
                    }
                    return item;
                });
            }
            return cloneList;
        });
    };

    const doneHandler = (status: OrderStatusEnum, ticketId: string, referenceId?: string) => {
        setData((li) => {
            const cloneList = cloneDeep(li);
            const idx = cloneList.findIndex((o) => o.refId === referenceId);
            if (idx > -1 && cloneList?.[idx]) {
                cloneList[idx].orderStatus = { name: status };
                cloneList[idx].ticketId = ticketId;
            }
            return cloneList;
        });
    };

    const printHandler = (id: string) => {
        const item = data.find((o) => o.id === id);
        if (!item) {
            return;
        }

        const payload: IQsrOrder[] = [
            {
                ...item,
                force: true,
                silent: true,
            },
        ];
        EventBus.dispatch(vendorOrderEvent, payload);
    };

    const editHandler = (id: string) => {
        if (!qsrEditOrderRef.current) {
            return;
        }

        const item = data.find((o) => o.id === id);
        if (!item) {
            return;
        }

        qsrEditOrderRef.current?.edit(item);
    };

    useEffect(() => {
        function reOrderColumns() {
            const newColumns = [...columns];

            if (isTablet) {
                const last = newColumns.pop();
                const secondLast = newColumns.pop();
                if (last && secondLast) {
                    newColumns.splice(2, 0, secondLast);
                    newColumns.splice(3, 0, last);
                }
            } else {
                setDisplayedColumns([...columns]);
                return;
            }

            setDisplayedColumns(newColumns);
        }

        reOrderColumns();
        window.addEventListener('resize', reOrderColumns);
        return () => window.removeEventListener('resize', reOrderColumns);
    }, [isTablet]);

    const noOrderComponent = () => {
        return (
            <Box
                sx={{
                    width: '100%',
                    paddingRight: '1.2em',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        paddingRight: '1.2em',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '20px',
                    }}
                >
                    {t('No Order Found!')}
                </Box>
                <KKTablePagination rowsPerPage={limit} rowCount={totalRow} currentPage={page} />
            </Box>
        );
    };

    if (hide) {
        return null;
    }

    if (view === 'table') {
        return (
            <Section>
                <DataTable
                    customStyles={{
                        table: {
                            style: {
                                fontFamily: 'Inter',
                            },
                        },
                        rows: {
                            style: {
                                borderBottom: 'none !important',
                            },
                        },
                    }}
                    striped
                    columns={displayedColumns}
                    data={data}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationPerPage={limit}
                    paginationTotalRows={totalRow}
                    onChangeRowsPerPage={tableRowsPerPageChangeHandler}
                    onChangePage={tablePageChangeHandler}
                    paginationComponent={KKTablePagination}
                    noDataComponent={noOrderComponent()}
                />
                <QsrOrdersDetails
                    open={Boolean(selectedRow)}
                    data={selectedRow}
                    currencySymbol={restaurant?.restaurant_country?.currency_symbol || ''}
                    currencyCode={restaurant?.restaurant_country?.currency_code || ''}
                    restaurant={restaurant}
                    lang={lang}
                    onClose={() => {
                        setSelectedRow(null);
                    }}
                    onDone={doneHandler}
                    onEdit={editHandler}
                    onReloadOrder={onReloadOrder}
                />
            </Section>
        );
    }
    return (
        <Grid
            container
            spacing={1}
            sx={{
                padding: mode !== QSRCardModeEnum.Pending ? '0 0 54px' : undefined,
            }}
        >
            {loading && data.length > 0 && (
                <Grid item xs={12}>
                    <LinearProgress variant="indeterminate" />
                </Grid>
            )}
            <Grid item xs={12}>
                {!loading && data.length === 0 && (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '20px',
                        }}
                    >
                        {t('There is no order with selected filters')}
                    </Box>
                )}
                {loading && (mode !== QSRCardModeEnum.Pending || data.length === 0) ? (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '20px',
                        }}
                    >
                        {t('Loading...')}
                    </Box>
                ) : (
                    <QsrGridView
                        data={data}
                        currencySymbol={restaurant?.restaurant_country?.currency_symbol || ''}
                        currencyCode={restaurant?.restaurant_country?.currency_code || ''}
                        restaurant={restaurant}
                        onBatchChange={batchChangeHandler}
                        onDone={doneHandler}
                        onPrint={printHandler}
                        onEdit={editHandler}
                        onReloadOrder={onReloadOrder}
                        loading={loading}
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: '#fff',
                        padding: '8px 16px',
                    }}
                >
                    <KKTablePagination
                        rowsPerPage={limit}
                        rowCount={totalRow}
                        currentPage={page}
                        onChangeRowsPerPage={tableRowsPerPageChangeHandler}
                        onChangePage={tablePageChangeHandler}
                    />
                </Box>
            </Grid>
        </Grid>
    );
}

function generateTimeRangeLabel(from: string | null, to: string | null) {
    if (!from || !to) return '';
    return `From ${new Date(from).getDate()} ${new Date(from).toLocaleDateString('en-US', {
        month: 'short',
    })} to ${new Date(to).getDate()} ${new Date(to).toLocaleDateString('en-US', { month: 'short' })}`;
}

export default function QsrOrders() {
    const { t } = useTranslation('common');
    const { enqueueSnackbar } = useSnackbar();
    const { restaurant } = useRestaurantContext();
    const [tab, setTab] = useState<QSRCardModeEnum>(QSRCardModeEnum.Normal);
    const qsrEditOrderRef = useRef<IQSREditOrderFunctions | null>(null);

    const {
        renderFilters,
        selectedStatus,
        paymentStatus,
        posStatus,
        timeRange,
        refId,
        refreshTrigger,
        view,
        removeFilter,
        selectedTable,
        statusFilterDetails,
        paymentStatusFilterDetails,
        posStatusFilterDetails,
        tableFilterDetails,
        isTableAvailable,
    } = useFilters(tab, restaurant?.order_config?.vendorQsrCardViewPendingStatuses || []);

    const hasPendingSection = Boolean(restaurant?.order_config?.vendorQsrCardViewPendingStatuses?.length);
    const hasFailedSection = Boolean(restaurant?.order_config?.vendorQsrCardViewFailedSectionEnable);
    const hasScheduledSection = Boolean(restaurant?.order_config?.scheduledOrderEnable);

    useEffect(() => {
        setTab(!hasPendingSection && !hasScheduledSection ? QSRCardModeEnum.Normal : QSRCardModeEnum.Pending);
    }, [restaurant]);

    const reloadOrderHandler = useCallback(
        (orderRefId: string) => {
            if (!restaurant?.id) {
                return Promise.resolve(new Error('no restaurant'));
            }

            return QsrOrdersService.getInstance()
                .getQsrOrderByRefId(restaurant.id, orderRefId)
                .then((data) => {
                    if (!data) {
                        return;
                    }

                    const payload: IQsrOrder[] = [
                        {
                            ...data.order,
                            silent: true,
                            skipPrint: true,
                        },
                    ];
                    EventBus.dispatch(vendorOrderEvent, payload);
                })
                .catch((err) => {
                    enqueueSnackbar(err?.message || err, { variant: 'error' });
                });
        },
        [restaurant],
    );

    if (!restaurant) {
        return null;
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <PageTitle
                        button={renderFilters()}
                        sx={{
                            paddingBottom: 0,
                        }}
                    />
                    <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                            overflowX: 'auto',
                            overflowY: 'hidden',
                            height: '50px',
                        }}
                    >
                        <QsrFilterChip
                            {...(timeRange.type !== TimeRangeTypes.TODAY
                                ? {
                                      onDelete: () => removeFilter('timeRange'),
                                  }
                                : {})}
                            label={
                                <span>
                                    <b>{t('Date:')}</b>{' '}
                                    {timeRange.type === 'custom'
                                        ? generateTimeRangeLabel(timeRange.from, timeRange.to)
                                        : timeRangeTitles(t)[timeRange.type]}
                                </span>
                            }
                        />
                        {selectedStatus.length > 0 && (
                            <QsrFilterChip
                                onDelete={() => {
                                    removeFilter('status');
                                }}
                                label={statusFilterDetails}
                            />
                        )}

                        {paymentStatus.length > 0 && (
                            <QsrFilterChip
                                onDelete={() => {
                                    removeFilter('paymentStatus');
                                }}
                                label={paymentStatusFilterDetails}
                            />
                        )}

                        {posStatus.length > 0 && (
                            <QsrFilterChip
                                onDelete={() => {
                                    removeFilter('posStatus');
                                }}
                                label={posStatusFilterDetails}
                            />
                        )}

                        {selectedTable.ids?.length > 0 && (
                            <QsrFilterChip
                                onDelete={() => {
                                    removeFilter('table');
                                }}
                                label={tableFilterDetails}
                            />
                        )}

                        <Chip
                            icon={getIcon({ value: view }, true)}
                            label={`${view.charAt(0).toUpperCase()}${view.slice(1)} view`}
                            sx={{
                                borderRadius: '8px',
                                margin: '5px',
                                padding: '10px',
                                color: '#1A1C23',
                                background: '#EBECF2',
                                borderColor: '#EBECF2',
                            }}
                        />
                    </Box>
                </Grid>
                {(hasPendingSection || hasFailedSection || hasScheduledSection) && (
                    <Grid item xs={12}>
                        <Tabs
                            variant="fullWidth"
                            value={tab}
                            onChange={(e, val) => {
                                setTab(val as QSRCardModeEnum);
                            }}
                        >
                            {hasFailedSection && (
                                <Tab value={QSRCardModeEnum.Failed} label={t('Failed Orders')} color="error" />
                            )}
                            {(hasPendingSection || hasScheduledSection) && (
                                <Tab value={QSRCardModeEnum.Pending} label={t('Pending Orders')} />
                            )}
                            {hasScheduledSection && <Tab value={QSRCardModeEnum.Scheduled} label={t('Scheduled')} />}
                            {hasPendingSection && (
                                <Tab value={QSRCardModeEnum.NotPending} label={t('All Other Orders')} />
                            )}
                            {!hasPendingSection && <Tab value={QSRCardModeEnum.Normal} label={t('All Orders')} />}
                        </Tabs>
                    </Grid>
                )}
                {hasFailedSection && (
                    <Grid item xs={12} sx={{ display: tab === QSRCardModeEnum.Failed ? 'block' : 'none' }}>
                        <QsrOrdersInner
                            selectedTable={selectedTable}
                            hide={tab !== QSRCardModeEnum.Failed}
                            mode={QSRCardModeEnum.Failed}
                            restaurant={restaurant}
                            view={view}
                            timeRange={timeRange}
                            selectedStatus={selectedStatus}
                            paymentStatus={paymentStatus}
                            posStatus={[OrderPosStatusEnum.Failed]}
                            refId={refId}
                            refreshTrigger={refreshTrigger}
                            qsrEditOrderRef={qsrEditOrderRef}
                            onReloadOrder={reloadOrderHandler}
                            isTableAvailable={isTableAvailable}
                        />
                    </Grid>
                )}
                {(hasPendingSection || hasScheduledSection) && (
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: [QSRCardModeEnum.Pending].includes(tab) ? 'block' : 'none',
                        }}
                    >
                        <QsrOrdersInner
                            selectedTable={selectedTable}
                            hide={![QSRCardModeEnum.Pending].includes(tab)}
                            mode={QSRCardModeEnum.Pending}
                            restaurant={restaurant}
                            view={view}
                            timeRange={timeRange}
                            selectedStatus={[
                                ...(restaurant.order_config?.vendorQsrCardViewPendingStatuses || []),
                                ...(hasScheduledSection ? [OrderStatusEnum.Pending] : []),
                            ]}
                            paymentStatus={paymentStatus}
                            posStatus={posStatus}
                            refId={refId}
                            refreshTrigger={refreshTrigger}
                            qsrEditOrderRef={qsrEditOrderRef}
                            onReloadOrder={reloadOrderHandler}
                            isTableAvailable={isTableAvailable}
                        />
                    </Grid>
                )}
                {hasScheduledSection && (
                    <Grid item xs={12} sx={{ display: tab === QSRCardModeEnum.Scheduled ? 'block' : 'none' }}>
                        <QsrOrdersInner
                            selectedTable={selectedTable}
                            hide={tab !== QSRCardModeEnum.Scheduled}
                            mode={QSRCardModeEnum.Scheduled}
                            restaurant={restaurant}
                            view={view}
                            timeRange={timeRange}
                            selectedStatus={[OrderStatusEnum.Scheduled]}
                            paymentStatus={paymentStatus}
                            posStatus={posStatus}
                            refId={refId}
                            refreshTrigger={refreshTrigger}
                            qsrEditOrderRef={qsrEditOrderRef}
                            onReloadOrder={reloadOrderHandler}
                            isTableAvailable={isTableAvailable}
                        />
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                    sx={{
                        display:
                            [QSRCardModeEnum.Normal, QSRCardModeEnum.NotPending].includes(tab) ||
                            (!hasPendingSection && !hasFailedSection && !hasScheduledSection)
                                ? 'block'
                                : 'none',
                    }}
                >
                    <QsrOrdersInner
                        selectedTable={selectedTable}
                        hide={
                            !(
                                [QSRCardModeEnum.Normal, QSRCardModeEnum.NotPending].includes(tab) ||
                                (!hasPendingSection && !hasFailedSection && !hasScheduledSection)
                            )
                        }
                        mode={hasPendingSection ? QSRCardModeEnum.NotPending : QSRCardModeEnum.Normal}
                        restaurant={restaurant}
                        view={view}
                        timeRange={timeRange}
                        selectedStatus={selectedStatus}
                        notInSelectedStatus={[
                            ...(restaurant.order_config?.vendorQsrCardViewPendingStatuses || []),
                            ...(hasScheduledSection ? [OrderStatusEnum.Scheduled, OrderStatusEnum.Pending] : []),
                        ]}
                        paymentStatus={paymentStatus}
                        posStatus={posStatus}
                        refId={refId}
                        refreshTrigger={refreshTrigger}
                        qsrEditOrderRef={qsrEditOrderRef}
                        onReloadOrder={reloadOrderHandler}
                        isTableAvailable={isTableAvailable}
                    />
                </Grid>
            </Grid>
            {restaurant?.order_config?.vendorEditOrderEnable && (
                <QSREditOrder ref={qsrEditOrderRef} restaurantId={restaurant.id} onReloadOrder={reloadOrderHandler} />
            )}
        </>
    );
}
