interface TypographyProperties {
    fontFamily: string;
    fontWeight: string;
    fontSize: string;
    letterSpacing: string;
    textDecorationLine?: string;
    lineHeight?: string;
}

interface Typography {
    display_lg: TypographyProperties;
    display_md: TypographyProperties;
    display_sm: TypographyProperties;
    headline_lg: TypographyProperties;
    headline_md: TypographyProperties;
    headline_sm: TypographyProperties;
    title_lg: TypographyProperties;
    title_md: TypographyProperties;
    title_sm: TypographyProperties;
    body_md: TypographyProperties;
    body_sm: TypographyProperties;
    body_sm_strike: TypographyProperties;
    button: TypographyProperties;
    caption_overline: TypographyProperties;
    price: TypographyProperties;
    caption_overline_medium: TypographyProperties;
    tip_single_line_percentage: TypographyProperties;
    tip_single_line_multi_line_top_currency_unit: TypographyProperties;
    tip_single_line_multi_line_top_currency_value: TypographyProperties;
    tip_multi_line_top_line_percentage: TypographyProperties;
    tip_multi_line_bottom_line_percentage: TypographyProperties;
    tip_multi_line_bottom_line_currency_unit: TypographyProperties;
    tip_multi_line_bottom_line_currency_value: TypographyProperties;
}

export const typography: Typography = {
    display_lg: {
        fontFamily: 'inherit',
        fontWeight: '400',
        fontSize: '3.6875rem',
        letterSpacing: '0',
    },
    display_md: {
        fontFamily: 'inherit',
        fontWeight: '400',
        fontSize: '2.9375rem',
        letterSpacing: '0',
    },
    display_sm: {
        fontFamily: 'inherit',
        fontWeight: '400',
        fontSize: '2.5rem',
        letterSpacing: '0',
    },
    headline_lg: {
        fontFamily: 'inherit',
        fontWeight: '400',
        fontSize: '2.0625rem',
        letterSpacing: '0.0025em',
    },
    headline_md: {
        fontFamily: 'inherit',
        fontWeight: '400',
        fontSize: '1.4375rem',
        letterSpacing: '0',
    },
    headline_sm: {
        fontFamily: 'inherit',
        fontWeight: '500',
        fontSize: '1.1875rem',
        letterSpacing: '0.0015em',
    },
    title_lg: {
        fontFamily: 'inherit',
        fontWeight: '500',
        fontSize: '1.4375rem',
        letterSpacing: '0',
    },
    title_md: {
        fontFamily: 'inherit',
        fontWeight: '500',
        fontSize: '1rem',
        letterSpacing: '0.0015em',
    },
    title_sm: {
        fontFamily: 'inherit',
        fontWeight: '500',
        fontSize: '0.875rem',
        letterSpacing: '0.001em',
    },
    body_md: {
        fontFamily: 'inherit',
        fontWeight: '400',
        fontSize: '1rem',
        letterSpacing: '0.005em',
    },
    body_sm: {
        fontFamily: 'inherit',
        fontWeight: '400',
        fontSize: '0.875rem',
        letterSpacing: '0.0025em',
    },
    body_sm_strike: {
        fontFamily: 'inherit',
        fontWeight: '400',
        fontSize: '0.875rem',
        letterSpacing: '0.0025em',
        textDecorationLine: 'line-through',
    },
    button: {
        fontFamily: 'inherit',
        fontWeight: '500',
        fontSize: '0.875rem', // 14px
        letterSpacing: '0.25px',
    },
    caption_overline: {
        fontFamily: 'inherit',
        fontWeight: '400',
        fontSize: '0.75rem', // 12px
        letterSpacing: '0.004em',
    },
    caption_overline_medium: {
        fontFamily: 'inherit',
        fontWeight: '500',
        fontSize: '0.75rem', // 12px
        letterSpacing: '0.004em',
    },
    price: {
        fontFamily: 'inherit',
        fontWeight: '500',
        fontSize: '0.813rem', // 13px
        letterSpacing: '0.004em',
    },
    tip_single_line_percentage: {
        fontFamily: 'inherit',
        fontWeight: '600',
        fontSize: '1rem', // 16px
        letterSpacing: '0.004em',
        lineHeight: '1.4rem', // 22.4px
    },
    tip_single_line_multi_line_top_currency_unit: {
        fontFamily: 'inherit',
        fontWeight: '400',
        fontSize: '0.5rem', // 8px
        letterSpacing: '0.004em',
        lineHeight: '0.7rem', // 11.2px
    },
    tip_single_line_multi_line_top_currency_value: {
        fontFamily: 'inherit',
        fontWeight: '600',
        fontSize: '0.75rem !important', // 12px
        letterSpacing: '0.004em',
        lineHeight: '1.05rem', // 16.8px
    },
    tip_multi_line_top_line_percentage: {
        fontFamily: 'inherit',
        fontWeight: '600',
        fontSize: '0.75rem', // 12px
        letterSpacing: '0.004em',
        lineHeight: '1.05rem', // 16.8px
    },
    tip_multi_line_bottom_line_percentage: {
        fontFamily: 'inherit',
        fontWeight: '400',
        fontSize: '0.688rem', // 11px
        letterSpacing: '0.004em',
        lineHeight: '0.782rem', // 12.51px
    },
    tip_multi_line_bottom_line_currency_unit: {
        fontFamily: 'inherit',
        fontWeight: '400',
        fontSize: '0.5rem', // 8px
        letterSpacing: '0.004em',
        lineHeight: '0.7rem', // 11.2px
    },
    tip_multi_line_bottom_line_currency_value: {
        fontFamily: 'inherit',
        fontWeight: '400',
        fontSize: '0.75rem !important', // 11px
        letterSpacing: '0.004em',
        lineHeight: '0.963rem', // 15.4px
    },
};
