import { IContainerProps } from '.';

export type ISize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export const useContainerStyle = ({ centered, gap, margin, padding, direction }: IContainerProps) => {
    const centeredProps = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        flexDirection: direction || 'row',
    };

    const sizes: { [key: string]: string } = {
        xs: '0.5rem',
        sm: '1rem',
        md: '2rem',
        lg: '3rem',
        xl: '4rem',
        xxl: '5rem',
    };
    const mainContainerStyles = {
        // props.gutterBlock and props.gutterInline in padding
        padding: `${padding ? sizes[padding] || padding : '0'}`,
        margin: `${margin ? sizes[margin] || margin : '0'}`,
        gap: `${gap ? sizes[gap] || gap : '0'}`,
        // props.variant in width
        ...(centered && centeredProps),
    };

    return {
        mainContainerStyles,
    };
};
