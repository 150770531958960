export enum EpsonStatusEnum {
    ASB_NO_RESPONSE = 0x00000001,
    ASB_DRAWER_KICK = 0x00000004,
    ASB_OFF_LINE = 0x00000008,
    ASB_COVER_OPEN = 0x00000020,
    ASB_PAPER_FEED = 0x00000040,
    ASB_WAIT_ON_LINE = 0x00000100,
    ASB_PANEL_SWITCH = 0x00000200,
    ASB_MECHANICAL_ERR = 0x00000400,
    ASB_AUTOCUTTER_ERR = 0x00000800,
    ASB_UNRECOVER_ERR = 0x00002000,
    ASB_AUTORECOVER_ERR = 0x00004000,
    ASB_RECEIPT_NEAR_END = 0x00020000,
    ASB_RECEIPT_END = 0x00080000,
    ASB_WAIT_REMOVE_LABEL = 0x01000000,
    ASB_REMOVAL_DETECT_PAPER_NONE = 0x04000000,
    ASB_REMOVAL_DETECT_UNKNOWN = 0x08000000,
    ASB_SPOOLER_IS_STOPPED = 0x80000000,
}

export const getStatusText = (status: any) => {
    let s = 'Status: \n';
    /* eslint-disable no-bitwise */
    if (status & EpsonStatusEnum.ASB_NO_RESPONSE) {
        s += ' No printer response\n';
    }
    if (status & EpsonStatusEnum.ASB_DRAWER_KICK) {
        s += ' Status of the drawer kick number 3 connector pin = "H"\n';
    }
    if (status & EpsonStatusEnum.ASB_OFF_LINE) {
        s += ' Offline status\n';
    }
    if (status & EpsonStatusEnum.ASB_COVER_OPEN) {
        s += ' Cover is open\n';
    }
    if (status & EpsonStatusEnum.ASB_PAPER_FEED) {
        s += ' Paper feed switch is feeding paper\n';
    }
    if (status & EpsonStatusEnum.ASB_WAIT_ON_LINE) {
        s += ' Waiting for online recovery\n';
    }
    if (status & EpsonStatusEnum.ASB_PANEL_SWITCH) {
        s += ' Panel switch is ON\n';
    }
    if (status & EpsonStatusEnum.ASB_MECHANICAL_ERR) {
        s += ' Mechanical error generated\n';
    }
    if (status & EpsonStatusEnum.ASB_AUTOCUTTER_ERR) {
        s += ' EpsonStatusEnum.Auto cutter error generated\n';
    }
    if (status & EpsonStatusEnum.ASB_UNRECOVER_ERR) {
        s += ' Unrecoverable error generated\n';
    }
    if (status & EpsonStatusEnum.ASB_AUTORECOVER_ERR) {
        s += ' Auto recovery error generated\n';
    }
    if (status & EpsonStatusEnum.ASB_RECEIPT_NEAR_END) {
        s += ' No paper in the roll paper near end detector\n';
    }
    if (status & EpsonStatusEnum.ASB_RECEIPT_END) {
        s += ' No paper in the roll paper end detector\n';
    }
    if (status & EpsonStatusEnum.ASB_WAIT_REMOVE_LABEL) {
        s += ' Sounding the buzzer (certain model) or printer waits for removing paper (certain model)\n';
    }
    if (status & EpsonStatusEnum.ASB_REMOVAL_DETECT_UNKNOWN) {
        s +=
            ' Paper taken sensor not working (certain model). Please check if not ambient light reaches paper outlet and if paper taken sensor status in the printer is enable if you need to use paper taken sensor status.\n';
    } else if (status & EpsonStatusEnum.ASB_REMOVAL_DETECT_PAPER_NONE) {
        s += ' Paper taken sensor do not detect paper (certain model)\n';
    } else {
        s += ' Paper taken sensor detects paper (certain model). Please take the receipt\n';
    }
    if (status & EpsonStatusEnum.ASB_SPOOLER_IS_STOPPED) {
        s += ' Stop the spooler\n';
    }
    /* eslint-enable no-bitwise */
    return s;
};

export const escapeText = (s: string) => {
    const escape = /\\[tnr\\]|\\/g;
    if (escape.test(s)) {
        s = s.replace(escape, (c) => {
            let r = '';
            switch (c) {
                case '\\t':
                    r = '\x09';
                    break;
                case '\\n':
                    r = '\x0a';
                    break;
                case '\\r':
                    r = '\x0d';
                    break;
                case '\\\\':
                    r = '\\';
                    break;
                default:
                    break;
            }
            return r;
        });
    }
    return s;
};
