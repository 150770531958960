import EventBus from '@/config/event-handler';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useLanguageQuery } from 'next-export-i18n';

import Fade from '@mui/material/Fade';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip, { type TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

type IListItem = {
    text: string;
    link: string;
    Icon?: any;
    children?: { text: string; link: string; restrictRoles?: string[] }[];
    query?: string;
    restrictRoles?: string[];
    badgeAllow?: boolean;
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} TransitionComponent={Fade} TransitionProps={{ timeout: 300 }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        display: 'inline-flex',
        minHeight: ' 24px',
        padding: '4px 8px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        borderRadius: '4px',
        background: 'var(--light-mode-neutral-black, #060201)',
    },
}));

const ListItem = ({ item }: { item: IListItem; isMobile: boolean; compact: boolean }) => (
    <ListItemIcon
        sx={{
            width: '56px',
        }}
    >
        <item.Icon />
    </ListItemIcon>
);
const SingleLevel = ({
    item,
    link,
    queries,
    notificationBadge,
    compact,
    isMobile,
}: {
    item: IListItem;
    link: string;
    queries: any;
    notificationBadge?: number;
    isMobile?: boolean;
    compact?: boolean;
}) => {
    const router = useRouter();
    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';

    return (
        <Link
            key={item.text}
            href={{ pathname: link, query: queries ? { [queries]: item.link, lang } : { lang } }}
            passHref
        >
            <CustomTooltip
                title={item.text}
                placement="right"
                enterTouchDelay={0}
                enterDelay={0}
                disableHoverListener={isMobile || !compact}
            >
                <ListItemButton
                    id={item.text}
                    selected={queries ? router.query[queries] === item.link : router.pathname === link}
                    onClick={() => {
                        EventBus.dispatch('close_menu');
                    }}
                    sx={{
                        height: '56px',
                        ...(compact
                            ? {
                                  borderRadius: '8px',
                              }
                            : {
                                  borderRadius: '100px',
                              }),
                    }}
                >
                    {!item.badgeAllow && item.Icon && (
                        <ListItem item={item} isMobile={isMobile || false} compact={compact || false} />
                    )}
                    <ListItemText primary={item.text} sx={{ color: '#616475' }} />
                    {notificationBadge !== 0 && (
                        <Typography
                            sx={{
                                color: '#DF1D17',
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '20px',
                                textAlign: 'right',
                            }}
                        >
                            {notificationBadge}
                        </Typography>
                    )}
                </ListItemButton>
            </CustomTooltip>
        </Link>
    );
};

export const MenuItem = ({
    item,
    link,
    query,
    notificationBadge,
    compact,
    isMobile,
}: {
    item: IListItem;
    link: string;
    query?: string;
    notificationBadge?: number;
    isMobile?: boolean;
    compact?: boolean;
}) => (
    <SingleLevel
        item={item}
        link={link}
        queries={query}
        notificationBadge={notificationBadge}
        compact={compact}
        isMobile={isMobile}
    />
);
