/*
    Creation Time: 2021 - Sep - 26
    Created by:  (hamidrezakk)
    Maintainers:
       1.  HamidrezaKK (hamidrezakks@gmail.com)
    Auditor: HamidrezaKK
    Copyright Qlub_ 2021
*/

/* tslint:disable */
/* eslint-disable */
import React from 'react';
import { TablePagination, IconButton } from '@mui/material';
import {
    FirstPageRounded,
    KeyboardArrowLeftRounded,
    KeyboardArrowRightRounded,
    LastPageRounded,
} from '@mui/icons-material';
import { useTranslation } from 'next-export-i18n';

function getNumberOfPages(rowCount: number, rowsPerPage: number): number {
    return Math.ceil(rowCount / rowsPerPage);
}

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }: any) {
    const handleFirstPageButtonClick = () => {
        onPageChange(1);
    };

    // RDT uses page index starting at 1, MUI starts at 0
    // i.e. page prop will be off by one here
    const handleBackButtonClick = () => {
        onPageChange(page);
    };

    const handleNextButtonClick = () => {
        onPageChange(page + 2);
    };

    const handleLastPageButtonClick = () => {
        onPageChange(getNumberOfPages(count, rowsPerPage));
    };

    return (
        <>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                <FirstPageRounded />
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                <KeyboardArrowLeftRounded />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRightRounded />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageRounded />
            </IconButton>
        </>
    );
}

function OrdersTablePaginationActions({ page, onPageChange, isNextButtonDisabled }: any) {
    // RDT uses page index starting at 1, MUI starts at 0
    // i.e. page prop will be off by one here
    const handleBackButtonClick = () => {
        onPageChange(page);
    };

    const handleNextButtonClick = () => {
        onPageChange(page + 2);
    };

    return (
        <>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                <KeyboardArrowLeftRounded />
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={isNextButtonDisabled} aria-label="next page">
                <KeyboardArrowRightRounded />
            </IconButton>
        </>
    );
}
export const OrdersTablePagination = ({ onChangePage, currentPage, isNextButtonDisabled, ...rest }: any) => {
    return (
        <TablePagination
            component="nav"
            count={-1}
            rowsPerPage={10}
            rowsPerPageOptions={[]}
            page={currentPage - 1}
            onPageChange={onChangePage}
            ActionsComponent={(props) => (
                <OrdersTablePaginationActions {...props} isNextButtonDisabled={isNextButtonDisabled} />
            )}
            {...rest}
        />
    );
};

const KKTablePagination = ({ rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage, currentPage }: any) => {
    const { t } = useTranslation('common');

    return (
        <TablePagination
            variant="footer"
            component="nav"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={currentPage - 1}
            onPageChange={onChangePage}
            onRowsPerPageChange={({ target }) => onChangeRowsPerPage(Number(target.value))}
            ActionsComponent={TablePaginationActions}
            labelRowsPerPage={t('Rows per page')}
        />
    );
};

export default KKTablePagination;
