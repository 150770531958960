import { IQsrOrder } from '@/views/QsrOrders/types';
import QsrOrderStatus from '@/components/QsrOrderStatus';
import QsrPaymentStatus from '@/components/QsrPaymentStatus';
import QsrOrderPosStatus from '@/components/QsrOrderPosStatus';
import QsrOrderEditStatus from '@/components/QsrOrderEditStatus';
import { IRestaurant } from '@/views/OrdersTableView/types';

import styles from './index.module.scss';

interface IProps {
    order: IQsrOrder;
    restaurant?: IRestaurant | null;
    itemsCount: number;
    changedStatusCount: number;
}

const QsrStatus = ({ order, restaurant, itemsCount, changedStatusCount }: IProps) => {
    const common = () => {
        return (
            <>
                <QsrPaymentStatus order={order} restaurant={restaurant} />
                <QsrOrderPosStatus order={order} />
                {!!order?.panelUserId && <QsrOrderEditStatus order={order} restaurant={restaurant} />}
            </>
        );
    };

    if (itemsCount === 1) {
        return (
            <div className={styles.main}>
                <QsrOrderStatus order={order} />
                {common()}
            </div>
        );
    }

    return (
        <div className={styles.main}>
            {changedStatusCount === itemsCount ? (
                <QsrOrderStatus order={order} />
            ) : (
                <div className={styles.label}>
                    <div className={styles.status}>{`${changedStatusCount} / ${itemsCount}`}</div>
                </div>
            )}
            {common()}
        </div>
    );
};

export default QsrStatus;
