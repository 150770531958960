import type { SxProps } from '@mui/material/styles';

const useInvoiceItemStyle = () => {
    const colorSecondary: SxProps = { color: '#eed7ff' };
    const colorHighEmphasis: SxProps = { color: '#1A1C23' };
    const colorMediumEmphasis: SxProps = { color: '#616475' };
    const colorLowEmphasis: SxProps = { color: '#9B9EAB' };
    const colorBackground: SxProps = { color: '#f9f9f9' };
    const colorGreen: SxProps = { color: '#1c8659' };
    const currencyStyle = {
        fontSize: '10px',
        marginRight: '2px',
        "html[dir='rtl']": {
            marginLeft: '2px',
        },
    };

    const invoiceItem: SxProps = {
        padding: '0.5rem 0px 0.5rem',
        alignItems: 'center',
        '.rtl-support': {
            display: 'inline-block',
        },
        '.itemExtraStyle': {
            width: '96px',
            paddingLeft: '4px',
        },
        '& .itemValue': {
            '>span': {
                display: 'inline-block',
            },
        },
        '&.styleStrike': {
            '& .itemValue': {
                textDecorationLine: 'line-through',
            },
        },

        '&.styleStrikeLabel': {
            '&:first-of-type': {
                textDecorationLine: 'line-through',
            },
        },
        '>div.priceContainer': {
            display: 'flex',
            '>div': {
                '&:last-of-type': {
                    minWidth: '60px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                },
            },
        },
        '>div': {
            color: 'inherit',
            fontSize: 'inherit',
            fontWeight: 'inherit',
            textDecorationLine: 'inherit',
            verticalAlign: 'middle',
            // padding: '0.5rem 0px 0.5rem',
            borderBottom: 'none',
            '&:first-of-type': {
                display: 'flex',
                alignItems: 'center',
            },

            '& .noWrap': {
                whiteSpace: 'nowrap',
            },

            '& .value': {
                width: '56px',

                '& .split': {
                    fontSize: ' 0.9em',
                },
            },

            '& .altValue': {
                color: '#616475',
                // TODO cahnge it in standard styles
                fontSize: '12px',
                paddingRight: '0.5rem',
                display: 'flex',
                alignItems: 'center',
            },
            '&.newAlt': {
                width: '48px',
                color: '#616475',
                // TODO cahnge it in standard styles
                fontSize: '12px',
                padding: '4px 0 0 2rem',
                display: 'inline-block',
            },

            '& .itemExtra': {
                width: '96px',
            },

            '&.billTitle': {
                maxWidth: '70%',
                flex: '1 1',
                '>span': {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: '-webkit-box !important',
                    '-webkit-line-clamp': '2',
                    '-webkit-box-orient': 'vertical',
                    whiteSpace: 'normal',
                },
            },

            '& .iconContainer': {
                display: 'inline-flex',
                width: '24px',
                height: '24px',
                margin: '0 8px 0 0',
                alignItems: 'center',
                justifyContent: 'center',

                '& svg': {
                    height: '24px',
                    width: '24px',
                },
                '& .splitItemQty': {
                    display: 'inline-flex',
                    backgroundColor: '#f9f9f9',
                    height: '24px',
                    width: '24px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#1A1C23',
                    fontSize: '12px',
                    lineHeight: '1em',
                    borderRadius: '24px',
                },

                '& .itemQty': {
                    display: 'inline-flex',
                    backgroundColor: '#EBECF2',
                    height: '24px',
                    width: '24px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#1A1C23',
                    fontSize: '12px',
                    lineHeight: '1em',
                    borderRadius: '24px',

                    '& .itemQtyTopping': {
                        backgroundColor: 'transparent',
                    },
                },

                "html[dir='rtl'] &": {
                    margin: '0 0 0 8px',
                },
            },
        },

        '&.newRow': {
            '@keyframes fadeBg': {
                from: {
                    backgroundColor: 'rgba(125,0,212,0.08)',
                },
                to: {
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                },
            },
            animationName: 'fadeBg',
            animationDuration: '10s',
            animationIterationCount: '1',
            '&:first-child': {
                position: 'relative',
            },
        },

        '&.topping': {
            padding: ' 0 0 8px 0',
            '.toppingAltValue': {
                padding: '0 0.5rem 0 0 !important',
            },
            '>div': {
                minHeight: '20px',
                padding: '0 4px 0 !important',
                display: 'flex',
                alignItems: 'center',
                fontSize: '12px',

                '& .iconContainer': {
                    '& svg': {
                        height: '20px',
                        width: '20px',
                    },

                    '& .itemQty': {
                        height: '20px',
                        width: '24px',
                        fontSize: '10px',
                        lineHeight: '1em',
                        background: 'unset',
                        color: '#616475',
                    },
                },

                "html[dir='rtl'] &": {
                    '&:first-of-type': {
                        paddingLeft: '24px !important',
                    },
                },
                '&:first-of-type': {
                    padding: '0 4px 0 24px !important',
                },
                '&:last-of-type': {
                    minWidth: '60px',
                    justifyContent: 'flex-end',
                    padding: '0 !important',
                },
            },
        },

        '&.paid': {
            backgroundColor: 'rgba(125,0,212,0.08)',
            color: '#363636',
        },

        '&.MuiTableRow-footer': {
            '&:first-of-type': {
                '& td': {
                    paddingTop: '16px !important',
                },
            },
        },

        '& .indicator': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        },
    };

    const colorPrimary: SxProps = {
        color: '#7d00d4',
    };

    const styleTitleMedium: SxProps = {
        fontWeight: 500,
        fontSize: '16px',
        '& td': {
            '&.altValue': {
                fontSize: '14px',
            },
        },
    };
    const styleTitleSmall: SxProps = {
        fontWeight: 500,
        fontSize: '14px',
        '& td': {
            '&.altValue': {
                fontSize: '12px',
            },
        },
    };
    const styleBodyMedium: SxProps = {
        fontSize: '14px',
        '&.altValue': {
            fontSize: '12px',
        },
    };
    const styleBodySmall: SxProps = {
        fontSize: '14px',
        '& td': {
            '&.altValue': {
                fontSize: '12px',
            },
        },
    };
    const styleStrike: SxProps = {
        '& .itemValue': {
            textDecorationLine: 'line-through',
        },
    };

    return {
        invoiceItem,
        colorPrimary,
        colorSecondary,
        colorHighEmphasis,
        colorMediumEmphasis,
        colorLowEmphasis,
        colorBackground,
        colorGreen,
        styleTitleMedium,
        styleTitleSmall,
        styleBodyMedium,
        styleBodySmall,
        styleStrike,
        currencyStyle,
    };
};

export default useInvoiceItemStyle;
