import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { ITransaction } from '@/views/Payments';

const handleDownloadPdf = (receipt: ITransaction, onDone?: () => void) => {
    const tableId = `${receipt.restaurant_unique}-${receipt.id}`;
    const content = document.getElementById(tableId);

    if (content) {
        content.setAttribute('id', 'pdf-clone');
        document.body.appendChild(content);

        const pdfEl = document.getElementById('pdf-clone');
        content.setAttribute('id', tableId);

        if (pdfEl) {
            html2canvas(pdfEl, { allowTaint: true }).then((canvas) => {
                onDone?.();
                const docWidth = canvas.width + 40;
                const docHeight = canvas.height + 40;

                const doc = new JsPDF({
                    orientation: 'l',
                    format: [0, 0],
                    unit: 'px',
                    compress: true,
                });

                doc.internal.pageSize.width = docWidth;
                doc.internal.pageSize.height = docHeight;

                const marginX = (docWidth - canvas.width) / 2;
                const marginY = (docHeight - canvas.height) / 2;

                pdfEl.remove();
                const img = canvas.toDataURL('image/png');

                doc.addImage(img, 'PNG', marginX, marginY, canvas.width, canvas.height);
                doc.save(`${tableId}-payment-receipt.pdf`);
            });
        }
    }
};

export default handleDownloadPdf;
