import { useCallback, useEffect, useRef, useState } from 'react';
// @ts-ignore
import createPlayer from 'web-audio-player';
import { browserName } from 'react-device-detect';

const soundSwitch = (sound: string) => {
    switch (sound) {
        case 'option1':
            return '/audio/option1.ogg';
        case 'option2':
            return '/audio/option2.ogg';
        case 'option3':
            return '/audio/option3.ogg';
        case 'option4':
            return '/audio/option4.ogg';
        default:
            return '/audio/new_payment.ogg';
    }
};

const audioPaymentClosedPath = '/audio/closed.ogg';

const notify = (title: string, body: string) => {
    try {
        const options = {
            body,
            icon: '/icons/icon-192x192.png',
        };
        // @ts-ignore
        const notification = new Notification(title, options);
        notification.onclick = () => {
            window.focus();
        };
    } catch (e) {
        console.warn(e);
    }
};

export enum NotificationType {
    NewPayment = 1,
    PaymentClosed = 2,
    NewOrder = 3,
}

const requestPermission = () => {
    if (!process.browser) {
        return;
    }
    if (!window.Notification) {
        return;
    }
    try {
        Notification.requestPermission()
            .then(() => {
                window.console.warn('Notification permission granted.');
            })
            .catch((err) => {
                window.console.info('Unable to get permission to notify.', err);
            });
    } catch (e) {
        window.console.info('Unable to get permission to notify.', e);
    }
};

interface RefObject {
    setCurrentTime: number;
    node: GainNode;
    context: AudioContext;
    play: () => void;
    stop: () => void;
    on: (eventName: string | symbol, listener: (...args: any[]) => void) => void;
}

export const useNotification = () => {
    const audioNewPayment = useRef<RefObject>();
    const audioPaymentClosed = useRef<RefObject>();
    const [option, setOption] = useState(soundSwitch(''));
    const [isLoop, setIsLoop] = useState(false);
    const [notifTimeout, setNotifTimeout] = useState(10);
    const timeoutRef = useRef<any>(null);

    const stopPlaying = useCallback(() => {
        if (audioNewPayment.current) {
            audioNewPayment.current?.stop();
            audioNewPayment.current.setCurrentTime = 0;
        }
        if (audioPaymentClosed.current) {
            audioPaymentClosed.current?.stop();
        }
    }, []);

    useEffect(() => {
        if (browserName !== 'Safari') {
            try {
                audioPaymentClosed.current = createPlayer(audioPaymentClosedPath);
                audioPaymentClosed?.current?.on('load', () => {
                    audioPaymentClosed?.current?.node?.connect(audioPaymentClosed.current.context.destination);
                });
            } catch (e) {
                console.log('createPlayer', e);
            }
        }
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, []);

    useEffect(() => {
        if (browserName !== 'Safari') {
            try {
                const newPaymentSoundPath = soundSwitch(option);
                audioNewPayment.current = createPlayer(newPaymentSoundPath, { loop: isLoop });
                audioNewPayment?.current?.on('load', () => {
                    audioNewPayment?.current?.node.connect(audioNewPayment.current.context.destination);
                });
            } catch (e) {
                console.log('create Player', e);
            }
        }
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [option, isLoop]);

    const notifyHandler = useCallback(
        (mode: NotificationType, title: string, body: string) => {
            notify(title, body);
            try {
                switch (mode) {
                    case NotificationType.NewPayment:
                        if (audioNewPayment.current) {
                            audioNewPayment.current.play();
                        }
                        break;
                    case NotificationType.PaymentClosed:
                        if (audioPaymentClosed.current) {
                            audioPaymentClosed.current.play();
                        }
                        break;
                    case NotificationType.NewOrder:
                        if (audioNewPayment.current) {
                            audioNewPayment.current.play();
                        }
                        break;
                    default:
                        break;
                }
            } catch (e) {
                console.log('play', e);
            }
            clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                // stopPlaying();
            }, notifTimeout * 1000);
        },
        [notifTimeout],
    );

    const setSound = (sound: string) => {
        setOption(sound);
    };

    const setLoop = (loop: boolean) => {
        setIsLoop(loop);
    };

    const setNotificationTimeout = (timeout: number) => {
        setNotifTimeout(timeout || 10);
    };

    return {
        notify: notifyHandler,
        setSound,
        requestPermission,
        stopPlaying,
        setLoop,
        setNotificationTimeout,
    };
};
