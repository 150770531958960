import { ReactNode } from 'react';
import type { ITimeRangeForm, TimeRangeFormProps } from '@/components/TimeRange/Form/types';
import { AnySchema } from 'yup';

export enum FileFormats {
    CSV = 'csv',
    XLSX = 'xlsx',
}

type RadioOption = {
    disabled?: boolean;
    label: string;
    value: string;
};

export type ExportForm = {
    fileFormat: FileFormats;
} & ITimeRangeForm;

export type Props<T> = {
    buttonType?: 'old' | 'new';
    children?: ReactNode;
    extraValidations?: Record<string, AnySchema>;
    initialValues: T;
    onConfirm: (values: T) => Promise<void>;
    reportTypes?: RadioOption[];
    title: string;
} & TimeRangeFormProps;
