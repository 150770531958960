import { Grid, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import { CustomerGrid } from './Container';
import Typography from './Typography';

interface ISubHeaderIdentifierPropsQA {
    titleId?: string;
    subTitleId?: string;
}

export interface IProps {
    title: string | ReactNode;
    subTitle: string | ReactNode;
    CTA?: ReactNode;
    sx?: SxProps;
    initialIcon?: ReactNode;
    qaIds?: ISubHeaderIdentifierPropsQA;
}

const SubHeader: FC<IProps> = ({ title, subTitle, CTA, sx, initialIcon, qaIds }) => (
    <CustomerGrid container justifyContent="space-between" alignItems="center" sx={sx}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {initialIcon}
            <Grid sx={{ maxWidth: 'calc(100vw - 190px)' }}>
                <Typography
                    typo="title_md"
                    sx={{
                        overflowX: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}
                    data-qa-id={qaIds?.titleId}
                >
                    {title}
                </Typography>
                <Typography
                    typo="body_sm"
                    sx={{
                        color: '#666',
                        overflowX: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}
                    data-qa-id={qaIds?.subTitleId}
                >
                    {subTitle}
                </Typography>
            </Grid>
        </div>
        <Grid>{CTA}</Grid>
    </CustomerGrid>
);
export default SubHeader;
