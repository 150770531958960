import Chip from '@mui/material/Chip';
import { ChipProps } from '@mui/material/Chip/Chip';

const QsrFilterChip = (props: ChipProps) => {
    return (
        <Chip
            {...props}
            sx={{
                borderRadius: '8px',
                margin: '5px',
                padding: '10px',
                color: '#1A1C23',
                background: '#EBECF2',
                borderColor: '#EBECF2',
                '& .MuiChip-deleteIcon': {
                    color: '#7D00D4',
                    fontSize: '20px',
                },
            }}
        />
    );
};

export default QsrFilterChip;
