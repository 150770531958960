import { useEffect, useState } from 'react';
import { Field, FormikProvider, FormikValues, useFormik, type FieldProps } from 'formik';
import * as Yup from 'yup';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Autocomplete, TextField } from '@mui/material';

import CloseOutlined from '@mui/icons-material/CloseOutlined';
import AddIcon from '@mui/icons-material/Add';

import { onPushEvent } from '@/services/lib/gtm';
import { useTranslation } from '@/hooks/translations';
import { PrimaryButton } from '@/components/common';

import { ArrowRightAlt } from '@mui/icons-material';
import { FileFormats, type Props } from '../types';
import { TimeRangeTypes } from '../../TimeRange/Form/types';
import GenerateReportNow from './GenerateReportNow';
import GenerateReportOverScheduled from './GenerateReportScheduled';

export default function CreateReportModal<T extends FormikValues>({
    extraValidations,
    initialValues,
    onConfirm,
    reportTypes,
    title,
    showGenerateForm,
    handleGenerateCheckboxChange,
    showScheduleForm,
    handleCheckboxChange,
}: Props<T> & {
    showGenerateForm: boolean;
    handleGenerateCheckboxChange: (event: any) => void;
    showScheduleForm: boolean;
    handleCheckboxChange: (event: any) => void;
}) {
    const { t } = useTranslation('common');
    const [open, setOpen] = useState<boolean>(false);

    const formik = useFormik<T>({
        initialValues,
        validationSchema: Yup.object({
            fileFormat: Yup.string()
                .oneOf(Object.values(FileFormats), t('Please select one of the given values'))
                .required(t('File type is required')),
            // @ts-ignore
            type: Yup.string()
                .oneOf(Object.values(TimeRangeTypes), t('Please select one of the given values'))
                .required(t('Time range is required')),
            from: Yup.string().nullable(),
            to: Yup.string().nullable(),
            ...extraValidations,
        }),
        onSubmit: (values, { resetForm, setSubmitting }) => {
            onPushEvent('user_use_export_function');
            onConfirm(values).finally(() => {
                setSubmitting(false);
                setOpen(false);
                resetForm();
            });
        },
    });

    const { handleSubmit, handleReset, isSubmitting, resetForm, values } = formik;
    const { fileFormat, reportType, from, to, type } = values;

    const timeRange = { from, to, type };

    useEffect(() => {
        onPushEvent(`user_choose_${fileFormat}_view_${timeRange}${reportType ? `_${reportType}` : ''}`);
    }, [fileFormat, type, from, to, reportType]);

    const onClose = () => {
        resetForm();
        setOpen(false);
    };

    return (
        <>
            <PrimaryButton
                onClick={() => setOpen(true)}
                endIcon={<AddIcon />}
                size="large"
                disabled={false}
                text={t('New Report')}
                styles={{ borderRadius: '12px' }}
            />
            <Dialog onClose={onClose} open={open} fullScreen>
                <DialogTitle
                    sx={{
                        position: 'sticky',
                        top: '0px',
                        backgroundColor: '#fff',
                        zIndex: 100,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBlockEnd: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    <IconButton onClick={onClose}>
                        <CloseOutlined />
                    </IconButton>
                    {title}
                    <form onSubmit={handleSubmit}>
                        <PrimaryButton
                            id="action-btn"
                            text={t('Generate Report')}
                            disabled={isSubmitting || (!showGenerateForm && !showScheduleForm)}
                            type="submit"
                            endIcon={<ArrowRightAlt />}
                        />
                    </form>
                </DialogTitle>
                <FormikProvider value={formik}>
                    <form onReset={handleReset}>
                        <DialogContent sx={{ paddingTop: '20px !important' }}>
                            <Grid container spacing={2}>
                                {reportTypes && (
                                    <>
                                        <Grid item xs={12}>
                                            <Field name="reportType">
                                                {({ field, form }: FieldProps) => (
                                                    <Autocomplete
                                                        {...field}
                                                        options={reportTypes}
                                                        getOptionLabel={(option) => option.label}
                                                        value={reportTypes.find((x) => x.value === field.value) || null}
                                                        onChange={(event, value) => {
                                                            form.setFieldValue(field.name, value ? value.value : '');
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={t('Report Type')}
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                        multiple={false}
                                                        disabled={isSubmitting}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field name="fileFormat">
                                                {({ field, form }: FieldProps) => (
                                                    <Autocomplete
                                                        {...field}
                                                        options={Object.values(FileFormats)}
                                                        getOptionLabel={(option) => option.toUpperCase()}
                                                        value={
                                                            Object.values(FileFormats).find((x) => x === field.value) ||
                                                            null
                                                        }
                                                        onChange={(event, value) => {
                                                            form.setFieldValue(field.name, value);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={t('File Format')}
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                        multiple={false}
                                                        disabled={isSubmitting}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                    </>
                                )}

                                <Grid item xs={12}>
                                    <GenerateReportNow
                                        showGenerateForm={showGenerateForm}
                                        handleGenerateCheckboxChange={handleGenerateCheckboxChange}
                                    />
                                    <GenerateReportOverScheduled
                                        showScheduleForm={showScheduleForm}
                                        handleCheckboxChange={handleCheckboxChange}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </form>
                </FormikProvider>
            </Dialog>
        </>
    );
}
