import { UserContextProvider } from '@/contexts/user';
import { NavbarContextProvider } from '@/contexts/navbar';
import type { AppProps } from 'next/app';
import Layout from '@/components/Layout';
import CustomThemeProvider from '@/theme';
import { RestaurantContextProvider } from '@/contexts/restaurant';
import { SnackbarProvider } from 'notistack';
import { DigitalMenuContextProvider } from '@/views/Menus/context';
import { LoyaltyContextProvider } from '@/contexts/loyalty';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import '@/styles/globals.scss';

// makeServer();

function QlubVendorApp({ Component, pageProps }: AppProps) {
    const router = useRouter();

    useEffect(() => {
        if (router.asPath.indexOf('[') === -1) {
            router.push(router.asPath);
        }
    }, []);

    return (
        <NavbarContextProvider>
            <UserContextProvider>
                <RestaurantContextProvider>
                    <DigitalMenuContextProvider>
                        <LoyaltyContextProvider>
                            <CustomThemeProvider>
                                <SnackbarProvider
                                    maxSnack={3}
                                    autoHideDuration={3000}
                                    disableWindowBlurListener
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                >
                                    <Layout>
                                        <Component {...pageProps} />
                                    </Layout>
                                </SnackbarProvider>
                            </CustomThemeProvider>
                        </LoyaltyContextProvider>
                    </DigitalMenuContextProvider>
                </RestaurantContextProvider>
            </UserContextProvider>
        </NavbarContextProvider>
    );
}

export default QlubVendorApp;
