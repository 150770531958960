import { IQsrOrder } from '@/views/QsrOrders/types';
import { useMemo } from 'react';
import { isEqual, pick } from 'lodash';
import { useTranslation } from '@/hooks/translations';
import CopyToClipboard from '@/common/CopyToClipboard';
import classNames from 'classnames';
import { IRestaurant } from '@/views/OrdersTableView/types';
import { MultiLocale, parseJSON } from '@/components/TicketModal/utils';

import styles from './index.module.scss';

interface IProps {
    order: IQsrOrder;
    restaurant: IRestaurant | null | undefined;
    lang?: string;
    normalMargin?: boolean;
}

export default function QsrUserInfo({ order, restaurant, lang, normalMargin }: IProps) {
    const { t } = useTranslation('common');
    const [fields, fieldMap] = useMemo<[string[], { [key: string]: string }]>(() => {
        if (!restaurant) {
            return [[], {}];
        }

        const f = restaurant.order_config?.vendorQsrCardViewUserInfoField || [];
        if (!restaurant.order_config?.customerExtraFields) {
            return [f, {}];
        }

        const cef = parseJSON(restaurant.order_config?.customerExtraFields) as any[];
        const fm = cef?.reduce<{ [key: string]: string }>((acc, { key, value }) => {
            acc[key] = value as string;
            return acc;
        }, {});
        return [f, fm];
    }, [restaurant]);

    const info = useMemo<{ [key: string]: string }>(() => {
        const { meta, ...rest } = order.userInfo || {};
        return pick({ ...rest, ...meta }, fields);
    }, [order]);

    if (isEqual(info, {}) || !fields.length) {
        return null;
    }

    const getLabel = (fieldName: string) => {
        switch (fieldName) {
            case 'name':
                return t('Name');
            case 'email':
                return t('Email');
            case 'phone':
                return t('Phone');
            case 'address':
                return t('Shipping Address');
            case 'carPlate':
                return t('Car Plate');
            case 'cpf':
                return t('CPF');
            default:
                if (fieldMap.hasOwnProperty(fieldName)) {
                    return <MultiLocale value={fieldMap[fieldName]} lang={lang} />;
                }
                return '';
        }
    };

    const getFieldContent = (fieldName: string) => {
        const field = info[fieldName];
        if (!field) {
            return null;
        }
        return (
            <div className={styles.field}>
                <CopyToClipboard value={field} />
                <div className={styles.label}>{getLabel(fieldName)}</div>
                <div className={styles.value}>{field}</div>
            </div>
        );
    };

    return (
        <div className={classNames(styles.main, { [styles.fit]: !normalMargin })}>
            {fields.map((field) => {
                return getFieldContent(field);
            })}
        </div>
    );
}
