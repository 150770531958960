import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useLanguageQuery, useTranslation } from 'next-export-i18n';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { useLanguage } from '@/constants/translations';
import { onPushEvent } from '@/services/lib/gtm';
import { updateQueryStringParameter } from '@/services/utils/k_url';
import { isMobile } from '@/services/utils/k_user_agent';

const CustomSelect = styled('select')({
    padding: '6px',
    borderRadius: '8px',
    width: '100%',
});

export default function LanguageSelector() {
    const { t } = useTranslation('common');
    const { asPath, push } = useRouter();
    const [query] = useLanguageQuery();
    const { languages } = useLanguage();
    const [native, setNative] = useState(false);
    const lang = query ? query.lang : 'en';

    useEffect(() => {
        setNative(isMobile());
    }, []);

    const langChangeHandler = (e: any) => {
        onPushEvent(`Language Switched to ${e.target.value}`);
        push(updateQueryStringParameter(asPath, 'lang', e.target.value));
    };

    if (native) {
        return (
            <CustomSelect value={lang} onChange={langChangeHandler}>
                {languages.map(({ value, title }) => {
                    return (
                        <option key={value} value={value}>
                            {title}
                        </option>
                    );
                })}
            </CustomSelect>
        );
    }

    return (
        <Select
            placeholder={t('Language')}
            fullWidth
            margin="none"
            size="small"
            value={lang}
            onChange={langChangeHandler}
        >
            {languages.map(({ value, title }) => {
                return (
                    <MenuItem key={value} value={value}>
                        {title}
                    </MenuItem>
                );
            })}
        </Select>
    );
}
