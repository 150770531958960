import React from 'react';
import { PrintConnectionStatusEnum } from '@/components/PosPrinteCenter/Printers/Epson';
import classNames from 'classnames';
import { CircularProgress, Tooltip } from '@mui/material';
import { useTranslation } from '@/hooks/translations';

import styles from './index.module.scss';

interface IProps {
    status?: PrintConnectionStatusEnum;
    onReconnect?: () => void;
}

function PrintIndicator({ status, onReconnect }: IProps) {
    const { t } = useTranslation('common');

    return (
        <div className={styles.wrapper}>
            <div
                className={classNames(styles.itemIndicator, {
                    [styles.statusConnected]: status === PrintConnectionStatusEnum.Connected,
                    [styles.statusConnecting]: status === PrintConnectionStatusEnum.Connecting,
                    [styles.statusDisconnected]: status === PrintConnectionStatusEnum.Disconnected,
                    [styles.statusReconnect]: status === PrintConnectionStatusEnum.Reconnect,
                })}
            >
                {[PrintConnectionStatusEnum.Connecting, PrintConnectionStatusEnum.Reconnect].includes(
                    status as PrintConnectionStatusEnum,
                ) && (
                    <Tooltip title={t('Click to reconnect!')}>
                        <div
                            className={styles.loading}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onReconnect?.();
                            }}
                        >
                            <CircularProgress
                                size={16}
                                thickness={4}
                                color={status === PrintConnectionStatusEnum.Connecting ? 'warning' : 'info'}
                            />
                        </div>
                    </Tooltip>
                )}
            </div>
        </div>
    );
}

export default PrintIndicator;
