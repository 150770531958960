import { useState, useEffect, useMemo } from 'react';
import {
    Box,
    FormControl,
    InputAdornment,
    MenuItem,
    Select,
    SelectChangeEvent,
    TableCell,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { useTranslation } from '@/hooks/translations';
import { getCurrencyWithAmount } from '@/common/utility';
import { IRefundConfig, IRefundData } from '@/views/Payments';
import { getCurrencyPrecision } from '@/services/utils/k_currency';
import { Cell, Section, TableContent } from './common';
import CustomRefundModal from './RefundConfirmationModal';

interface IProps {
    refresh: () => void;
    refundData: IRefundData;
    open: boolean;
    onClose: () => void;
    currencySymbol: string;
    currencyCode: string;
    refundConfig: IRefundConfig | null;
}

export default function RefundModal({
    refresh,
    refundData,
    currencySymbol,
    currencyCode,
    refundConfig,
    ...rest
}: IProps) {
    const { t } = useTranslation('common');
    const [totalRefundableAmount, setTotalRefundableAmount] = useState<number>(0);
    const [refundAmount, setRefundAmount] = useState<number>(0.0);
    const [refundReason, setRefundReason] = useState<string>('overpayment');
    const [refundAmountError, setRefundAmountError] = useState<boolean>(false);

    const handleChange = (event: SelectChangeEvent) => {
        setRefundReason(event.target.value as string);
    };

    useEffect(() => {
        if (refundData?.billAmount) {
            const precision = getCurrencyPrecision(currencyCode);
            const amounts = [refundData?.netTipAmount, refundData?.paidAmount, refundData?.customerCommission];
            const refundableAmount = amounts.reduce((acc, curr) => Number(acc) + Number(curr), 0);
            setTotalRefundableAmount(
                refundData?.refundableAmount !== undefined
                    ? Number(refundData.refundableAmount.toFixed(precision))
                    : Number(refundableAmount.toFixed(precision)),
            );
        }
        setRefundAmount(totalRefundableAmount);
    }, [refundData, totalRefundableAmount]);

    useEffect(() => {
        if (refundConfig && refundConfig.refundConfigs?.[0]?.refundType !== 'partial') {
            setRefundAmount(totalRefundableAmount);
        }
    }, [refundConfig]);

    const isPartial = useMemo(() => {
        const config = refundConfig?.refundConfigs?.find((o) => o.name === refundData.gatewayVendor);
        if (config) {
            return config.refundType === 'partial';
        }
        return refundConfig?.refundConfigs?.[0]?.refundType === 'partial';
    }, [refundConfig, refundData]);

    return (
        <CustomRefundModal
            {...rest}
            refundAmount={refundAmount}
            currencySymbol={currencySymbol}
            currencyCode={currencyCode}
            refundReason={refundReason}
            maxWidth="xs"
            disableButton={!(refundAmount > 0)}
            actionText={t('Confirm')}
            data={refundData}
            refresh={refresh}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography variant="h5">{t('Refund')}</Typography>
                    <Typography color="#A9A9A9">
                        {t('Ticket  #{{order_id}}, {{time}}, ', {
                            order_id: refundData?.orderId,
                            time: refundData?.time,
                        })}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        '& > *': {
                            margin: '0.1rem 0',
                        },
                    }}
                />
            </Box>
            {refundData && (
                <Section sx={{ margin: '0.5rem 0' }}>
                    <TableContent
                        large
                        headCells={
                            <>
                                <TableCell>{t('Paid Amount')}</TableCell>
                                <TableCell>{t('Tip Amount')}</TableCell>
                                <TableCell>{t('Qlub Diner Fee')}</TableCell>
                                <TableCell>{t('Total Paid Amount')}</TableCell>
                            </>
                        }
                    >
                        <TableRow>
                            <Cell>
                                {getCurrencyWithAmount(refundData?.paidAmount, currencySymbol, currencyCode, true)}
                            </Cell>

                            <Cell>
                                {getCurrencyWithAmount(refundData?.netTipAmount, currencySymbol, currencyCode, true)}
                            </Cell>
                            <Cell>
                                {getCurrencyWithAmount(
                                    refundData?.customerCommission,
                                    currencySymbol,
                                    currencyCode,
                                    true,
                                )}
                            </Cell>
                            <Cell>
                                {getCurrencyWithAmount(
                                    Number(refundData?.netTipAmount || 0) +
                                        Number(refundData?.paidAmount || 0) +
                                        Number(refundData?.customerCommission || 0),
                                    currencySymbol,
                                    currencyCode,
                                    true,
                                )}
                            </Cell>
                        </TableRow>
                    </TableContent>
                </Section>
            )}
            <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                {t('Enter the amount to be refunded')}
            </Typography>

            <TextField
                helperText={refundAmountError ? t('Refund more than paid amount is not allowed') : ' '}
                color="primary"
                required
                id="fullWidth"
                fullWidth
                onChange={(e) => {
                    const value = parseFloat(e.target.value);

                    if (value > totalRefundableAmount) {
                        setRefundAmountError(true);
                        setRefundAmount(totalRefundableAmount);
                    } else {
                        setRefundAmountError(false);
                        setRefundAmount(value);
                    }
                }}
                placeholder="00.00"
                name="amount"
                autoCorrect="off"
                autoComplete="off"
                InputProps={{
                    startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                    inputProps: {
                        inputMode: 'decimal',
                        pattern: '[0-9]*',
                        min: 0,
                        max: totalRefundableAmount,
                    },
                }}
                value={refundAmount || ''}
                type="number"
                disabled={!isPartial}
            />

            <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                {t('Reason')}
            </Typography>

            <FormControl fullWidth>
                <Select value={refundReason} onChange={handleChange}>
                    <MenuItem value="overpayment">{t('Overpayment')}</MenuItem>
                    <MenuItem value="discount_not_included">{t('Discount Not Included')}</MenuItem>
                    <MenuItem value="failed_order">{t('Failed Order')}</MenuItem>
                    <MenuItem value="duplicate_payment">{t('Duplicate Payment')}</MenuItem>
                </Select>
            </FormControl>
        </CustomRefundModal>
    );
}
