import React, { useEffect, useRef, useState } from 'react';
import { endOfDay, endOfToday, startOfDay } from 'date-fns';

import { useTranslation } from '@/hooks/translations';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

type Props = {
    disabled?: boolean;
    timeSelectEnabled?: boolean;
    modalTitle?: string;
    textFieldLabel?: string | any;
    onChange: (rangeDate: Date) => void;
    fromTo: 'from' | 'to';
    value?: Date;
    minDate?: Date;
    toRef?: any;
};

export default function CustomDateTimePicker({
    disabled,
    timeSelectEnabled,
    modalTitle,
    textFieldLabel,
    onChange,
    fromTo,
    value,
    minDate,
    toRef,
}: Props) {
    const { t } = useTranslation('common');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [time, setTime] = useState<string | null>('');
    const [date, setDate] = useState<Date | null>(new Date());
    const [open, setOpen] = useState(false);

    const init = useRef(true);

    useEffect(() => {
        if (!value || init.current === false) {
            return;
        }

        setDate(value);
        init.current = false;
    }, [value]);

    const changeTime = (e: any) => {
        setTime(e.target.value);
    };

    const handleClickOpen = () => {
        setOpen(!disabled);
    };

    const customizeDateTime = () => {
        if (date && time) {
            const formattedDate = date;
            const newTime = time.split(':');
            formattedDate.setHours(Number(newTime[0]), Number(newTime[1]), 0);

            if (typeof formattedDate.getMonth === 'function') {
                setDate(formattedDate);
            }
        } else {
            const initialTime = fromTo === 'from' ? startOfDay(new Date()) : endOfDay(new Date());
            const hours = initialTime.getHours();
            const mins = initialTime.getMinutes();
            const timeStr = hours === 0 && mins === 0 ? `${hours}${hours}:${mins}${mins}` : `${hours}:${mins}`;

            setTime(timeStr);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        customizeDateTime();
        if (date !== undefined && date !== null) {
            onChange(date);
        }
        setOpen(false);
    };

    useEffect(() => {
        customizeDateTime();
    }, [date, time]);

    return (
        <>
            <TextField
                ref={toRef}
                disabled={disabled}
                size="small"
                label={textFieldLabel}
                fullWidth
                value={!timeSelectEnabled ? date?.toDateString() : date || ''}
                onClick={handleClickOpen}
                InputProps={{
                    inputProps: {
                        style: { textAlign: 'center' },
                    },
                }}
            />
            <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title">{modalTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <StaticDatePicker
                            views={['day']}
                            displayStaticWrapperAs="desktop"
                            openTo="day"
                            value={date}
                            disableFuture={false}
                            maxDate={toRef ? endOfToday() : new Date()}
                            minDate={minDate}
                            onChange={(newValue: any) => {
                                setDate(newValue);
                            }}
                        />
                    </DialogContentText>
                    {timeSelectEnabled && (
                        <TextField
                            size="small"
                            value={time}
                            fullWidth
                            type="time"
                            onChange={changeTime}
                            InputProps={{
                                inputProps: {
                                    style: { textAlign: 'center' },
                                },
                            }}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        {t('Cancel')}
                    </Button>
                    <Button onClick={handleConfirm} autoFocus>
                        {t('Ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
