import { PrimaryButton } from '@/components/common';
import {
    Autocomplete,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Switch,
    TextField,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@/hooks/translations';
import { useLanguageQuery } from 'next-export-i18n';
import EventBus from '@/config/event-handler';
import { useSnackbar } from 'notistack';
import { accessTokenKey, notificationSettingsKey } from '@/config/axios';
import { notificationSettingsUpdated } from '@/contexts/event.const';
import { PlayCircleOutlineOutlined } from '@mui/icons-material';
// @ts-ignore
import createPlayer from 'web-audio-player';
import { defaults, pickBy } from 'lodash';
import UserService from '@/services/user';
import { onPushEvent } from '@/services/lib/gtm';
import Header from '../components/header';

export interface INotificationSettings {
    sound: string;
    type: string;
    timeout: number;
    loop: boolean;
    printMode: 'off' | 'printAfter';
}

const keys = ['sound', 'type', 'timeout', 'loop'];

const getLocalStorageNotificationSettings = () => {
    try {
        const d = localStorage.getItem(notificationSettingsKey);
        if (d) {
            return JSON.parse(d);
        }
        // eslint-disable-next-line no-empty
    } catch {}
    return null;
};

export const getNotificationSettings = (): INotificationSettings => {
    const val = getLocalStorageNotificationSettings();
    return pickBy(
        defaults(val, {
            sound: 'default',
            type: 'toast',
            timeout: 5,
            loop: false,
            printMode: 'off',
        }),
        (v, key) => keys.includes(key),
    ) as INotificationSettings;
};

export const setNotificationSettings = (config: INotificationSettings) => {
    const val = pickBy(
        defaults(config, {
            sound: 'default',
            type: 'toast',
            timeout: 5,
            loop: false,
            printMode: 'off',
        }),
        (v, key) => keys.includes(key),
    ) as INotificationSettings;
    localStorage.setItem(notificationSettingsKey, JSON.stringify(val));
};

export default function NotificationSettings() {
    const userService = UserService.getInstance();
    const { t } = useTranslation('common');
    const { enqueueSnackbar } = useSnackbar();
    const [isUpdating, setIsUpdating] = useState(false);
    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';

    const notificationSoundList = useMemo(
        () => [
            {
                label: t('Default'),
                value: 'default',
            },
            {
                label: t('Option 1'),
                value: 'option1',
            },
            {
                label: t('Option 2'),
                value: 'option2',
            },
            {
                label: t('Option 3'),
                value: 'option3',
            },
            {
                label: t('Option 4'),
                value: 'option4',
            },
        ],
        [lang],
    );

    const autoPrintOptions = useMemo(
        () => [
            {
                label: t('Off'),
                value: 'off',
            },
            {
                label: t('Print after notification'),
                value: 'printAfter',
            },
        ],
        [lang],
    );

    const soundSwitch = (sound: string) => {
        switch (sound) {
            case 'option1':
                return '/audio/option1.ogg';
            case 'option2':
                return '/audio/option2.ogg';
            case 'option3':
                return '/audio/option3.ogg';
            case 'option4':
                return '/audio/option4.ogg';
            default:
                return '/audio/new_payment.ogg';
        }
    };

    const [notifySettings, setNotifySettings] = useState<INotificationSettings>(getNotificationSettings());

    const updateNotificationSettings = async () => {
        const token = localStorage.getItem(accessTokenKey);
        if (!token) {
            return;
        }

        const payload = { config: notifySettings };

        await userService
            .setRemoteNotificationSettings(payload)
            .then(() => {
                enqueueSnackbar('Success', {
                    variant: 'success',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    autoHideDuration: 5000,
                });
            })
            .catch((err) => {
                enqueueSnackbar(`${err.response?.data?.error}`, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    autoHideDuration: 5000,
                });
            })
            .finally(() => {
                setIsUpdating(false);
            });
    };

    useEffect(() => {
        onPushEvent('user_visit_notification_settings_section');

        const getRemoteNotificationSettings = async () => {
            setIsUpdating(true);

            await userService
                .getRemoteNotificationSettings()
                .then((res) => {
                    if (res?.data?.config) {
                        setNotifySettings(res.data.config);
                    }
                })
                .catch((err) => {
                    enqueueSnackbar(`${err.response?.data?.error}`, {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        autoHideDuration: 5000,
                    });
                })
                .finally(() => {
                    setIsUpdating(false);
                });
        };

        getRemoteNotificationSettings();
    }, []);

    const changePresentationHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        onPushEvent(`user_choose_notification_presentation_${event.target.value}`);
        setNotifySettings({
            ...notifySettings,
            type: event.target.value,
        });
    };

    const changePresentationTimeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        onPushEvent('user_change_notification_presentation_time');
        setNotifySettings({
            ...notifySettings,
            timeout: Number(event.target.value),
        });
    };

    const changePresentationLoopHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        onPushEvent(`user_${event.target.checked ? 'enable' : 'disable'}_loop_option`);
        setNotifySettings({
            ...notifySettings,
            loop: event.target.checked,
        });
    };

    return (
        <>
            <Header title={t('Change Notification Settings')} />
            <Grid display="flex" marginBottom="1rem">
                <Autocomplete
                    options={notificationSoundList}
                    getOptionLabel={(option) => option.label}
                    style={{ width: '100%' }}
                    onChange={(event, value) => {
                        if (value) {
                            setNotifySettings({ ...notifySettings, sound: value.value });
                            onPushEvent(`user_change_sound_to_${value.value}`);
                        }
                    }}
                    value={notificationSoundList.find((o) => o.value === notifySettings.sound)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t('Notification Sound')}
                            variant="outlined"
                            inputProps={{
                                ...params.inputProps,
                            }}
                        />
                    )}
                    disabled={isUpdating}
                />

                <IconButton
                    onClick={() => {
                        onPushEvent('user_click_test_sound');
                        const newPaymentSoundPath = soundSwitch(notifySettings.sound);

                        const soundPlayer = createPlayer(newPaymentSoundPath);
                        soundPlayer.on('load', () => {
                            soundPlayer.node.connect(soundPlayer.context.destination);
                        });
                        soundPlayer.play();
                    }}
                >
                    <PlayCircleOutlineOutlined fontSize="large" />
                </IconButton>
                <FormControlLabel
                    control={
                        <Switch
                            checked={notifySettings.loop}
                            onChange={changePresentationLoopHandler}
                            color="primary"
                            disabled={isUpdating}
                        />
                    }
                    label={t('Loop')}
                />
            </Grid>
            <Grid
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <TextField
                    helperText={t('Please select duration time for notification close')}
                    label={t('Duration time before close')}
                    inputMode="numeric"
                    InputProps={{
                        endAdornment: t('seconds'),
                        type: 'number',
                    }}
                    sx={{ width: '100%' }}
                    onChange={changePresentationTimeHandler}
                    value={notifySettings.timeout}
                    disabled={isUpdating}
                />
                <FormControl component="fieldset" name="presentation-of-notification" disabled={isUpdating}>
                    <RadioGroup
                        onChange={changePresentationHandler}
                        value={notifySettings.type}
                        sx={{ flexDirection: 'row' }}
                    >
                        <FormControlLabel value="toast" control={<Radio />} label={t('Toast')} />
                        <FormControlLabel value="sticky" control={<Radio />} label={t('Sticky')} />
                        <FormControlLabel value="popup" control={<Radio />} label={t('Popup')} />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Autocomplete
                options={autoPrintOptions}
                getOptionLabel={(option) => option.label}
                style={{ width: '100%' }}
                onChange={(event, value) => {
                    if (value) {
                        setNotifySettings({ ...notifySettings, printMode: value.value as any });
                        onPushEvent(`user_change_auto_print_to_${value.value}`);
                    }
                }}
                value={autoPrintOptions.find((o) => o.value === notifySettings.printMode) || null}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t('Print Mode')}
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                        }}
                    />
                )}
                disabled={isUpdating}
            />
            <PrimaryButton
                id="update-btn"
                disabled={isUpdating}
                text={t('Update')}
                fullWidth
                styles={{
                    marginTop: '1rem',
                    fontWeight: 700,
                    fontSize: '1rem',
                    borderRadius: '0.5rem',
                }}
                onClick={() => {
                    setIsUpdating(true);

                    updateNotificationSettings().then(() => {
                        EventBus.dispatch(notificationSettingsUpdated, {});
                    });
                }}
            />
        </>
    );
}
