import SocketClient, { Socket } from 'socket.io-client';
import EventBus from '@/config/event-handler';
import { SOCKET_URL } from '@/config/websocket';
import {
    IOrderNotificationMessage,
    IPaymentNotificationMessage,
    NotifyVendorEnum,
    PaymentRecordType,
} from '@/views/Payments';
import { cloneDeep, debounce, isEqual } from 'lodash';
import { IPaymentTransaction } from '@/views/Orders/types';
import { IQsrOrder } from '@/views/QsrOrders/types';
import { vendorOrderEvent, vendorPaymentEvent } from './event.const';

export interface ITableParam {
    id: string;
    f1?: string;
    f2?: string;
}

export interface ISocketFilter {
    selectedTable?: ITableParam[];
    paymentType?: PaymentRecordType;
}

interface ISocketIOConnectParam {
    accessToken: string;
    restaurantId: string;
    filter?: ISocketFilter;
}

export enum VendorEventTypeEnum {
    PaymentRecord = 'paymentRecord',
    Order = 'order',
}

export enum SocketMessageEnum {
    VendorJoin = 'vendorJoin',
    Connect = 'connect',
    ConnectError = 'connect_error',
}

export class SocketConnector {
    private socket: Socket | undefined;

    static instance: SocketConnector;

    static getInstance = () => {
        if (!SocketConnector.instance) {
            SocketConnector.instance = new SocketConnector();
        }
        return SocketConnector.instance;
    };

    private init = false;

    private connected = false;

    private msgMap: { [key: string]: IPaymentTransaction } = {};

    private orderMsgMap: { [key: string]: IQsrOrder } = {};

    private lastConfig: any = null;

    private eventHandler = () => {
        EventBus.dispatch(vendorPaymentEvent, Object.values(this.msgMap));
        this.msgMap = {};
    };

    private orderEventHandler = () => {
        EventBus.dispatch(vendorOrderEvent, Object.values(this.orderMsgMap));
        this.orderMsgMap = {};
    };

    private eventDebounce = debounce(this.eventHandler, 256);

    private orderEventDebounce = debounce(this.orderEventHandler, 256);

    // public constructor() {
    //     setInterval(() => {
    //         this.messageHandler({
    //             eventName: '',
    //             details: {
    //                 id: '7503',
    //                 country_code: 'ae',
    //                 restaurant_unique: 'sapaadCommPutik',
    //                 ops_mode: 'uat',
    //                 table_id: '1',
    //                 order_id: '12905_1-218794568',
    //                 paid: true,
    //                 closed: false,
    //                 order_data: {
    //                     id: '12905_1-218794568',
    //                     vat: '0.06000000000000001',
    //                     items: [],
    //                     amount: '13.26',
    //                     subTotal: '12.0',
    //                     table_id: '12905_1',
    //                     additives: [],
    //                 },
    //                 table_data: {
    //                     id: '12905_1',
    //                     name: '3_1_',
    //                     extra: '',
    //                     floor: '',
    //                     section: '_',
    //                     activeOrderId: '12905_1-218794568',
    //                     revenueCenter: '3',
    //                 },
    //                 bill_amount: '13.26',
    //                 pos_extra_info: null,
    //                 created_at: '2022-08-15T09:16:44.281Z',
    //                 updated_at: '2022-08-15T09:22:07.729Z',
    //                 paymentRecord: [
    //                     {
    //                         id: '10521',
    //                         table_session_id: '7503',
    //                         party_id: '9b8239f0-1d59-474e-b503-2548f2bb5f30',
    //                         table_id: '1',
    //                         party_name: '',
    //                         paid_amount: '13.26',
    //                         tip_amount: '0.00',
    //                         reference: '3484734792',
    //                         gateway_vendor: 'stripe',
    //                         status: 'accepted',
    //                         pg_extra_info: {
    //                             last4: '4242',
    //                             txnID: '',
    //                             txType: 'SALES',
    //                             bankCode: '',
    //                             cardType: '',
    //                             currency: '',
    //                             cardBrand: 'visa',
    //                             gatewayID: 0,
    //                             reference: 'pi_3LWzPeKCIvcyBM580aY4qvab',
    //                             fingerPrint: 'YBi5KTX31D4FwTLN',
    //                             productType: '',
    //                             cardCategory: '',
    //                             billingCountry: 'US',
    //                         },
    //                         commission_amount: '0.33',
    //                         commission_id: '137',
    //                         created_at: '2022-08-15T09:16:58.356Z',
    //                         updated_at: '2022-08-15T09:17:02.026Z',
    //                     },
    //                 ],
    //             },
    //         });
    //     }, 5000);
    // }

    public disconnect() {
        if (this.socket) {
            this.socket.disconnect();
            this.connected = false;
        }
    }

    public destroy() {
        if (this.socket) {
            this.socket.disconnect();
            this.socket = undefined;
            this.connected = false;
        }
    }

    public socketOpen(params: any): Promise<Socket> {
        const selectedTables = params.selectedTables || [];

        return new Promise<Socket>((resolve, reject) => {
            if (!this.socket) {
                const socket = SocketClient(SOCKET_URL || '', {
                    auth: params.auth,
                    transports: ['websocket'],
                });

                this.socket = socket;

                socket.on(SocketMessageEnum.Connect, () => {
                    console.log('connected');
                    console.log('socket connected with id ', socket.id);
                    socket.emit(SocketMessageEnum.VendorJoin, selectedTables);
                    resolve(socket);
                });

                socket.on(VendorEventTypeEnum.PaymentRecord, this.paymentMessageHandler);

                socket.on(VendorEventTypeEnum.Order, this.orderMessageHandler);

                socket.on(SocketMessageEnum.ConnectError, (err: any) => {
                    console.error('Error in creating socket ', err);
                    reject(err);
                });
            } else if (!this.socket.connected) {
                this.socket.connect();

                this.socket.on(SocketMessageEnum.Connect, () => {
                    if (this.socket) {
                        console.log('reconnected with tables:', selectedTables);
                        this.socket.emit(SocketMessageEnum.VendorJoin, selectedTables);

                        resolve(this.socket);
                    } else {
                        reject();
                    }
                });

                this.socket.on(SocketMessageEnum.ConnectError, (err: any) => {
                    console.error('Error in creating socket ', err);
                    reject(err);
                });
            } else {
                resolve(this.socket);
            }
        });
    }

    public connect(params: ISocketIOConnectParam): Promise<Socket> {
        this.init = true;
        if (this.connected) {
            this.disconnect();
        }
        this.lastConfig = cloneDeep(params);
        return this.socketOpen({
            auth: {
                token: params.accessToken,
                restaurantId: params.restaurantId,
            },
            filter: params.filter,
        }).then((res) => {
            this.connected = true;
            return res;
        });
    }

    public modify(params: ISocketIOConnectParam, applySelectedTable?: boolean): Promise<any> {
        if (!this.init) {
            return Promise.resolve();
        }
        if (applySelectedTable) {
            params.filter = this.lastConfig?.filter;
        }
        if (isEqual(this.lastConfig, params)) {
            return Promise.resolve();
        }
        return this.connect(params);
    }

    private paymentMessageHandler = (msg: IPaymentNotificationMessage) => {
        if (this.msgMap.hasOwnProperty(msg.details.id)) {
            const m = this.msgMap[msg.details.id];
            const idx = m.paymentRecord?.findIndex((o) => o.id === msg.details?.paymentRecord?.[0].id) || -1;
            if (idx === -1) {
                this.msgMap[msg.details.id] = {
                    ...msg.details,
                    paymentRecord: [...(msg.details.paymentRecord || []), ...(m.paymentRecord || [])],
                };
            }
        } else {
            this.msgMap[msg.details.id] = { ...msg.details, eventName: msg.eventName };
        }
        this.eventDebounce();
    };

    private orderMessageHandler = (msg: IOrderNotificationMessage) => {
        this.orderMsgMap[msg.details.id] = { ...msg.details, silent: msg.eventName === NotifyVendorEnum.UpdateStatus };
        this.orderEventDebounce();
    };
}
