import { useState, useEffect } from 'react';
import { useLanguageQuery } from 'next-export-i18n';
import classNames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';

import TransactionService from '@/services/transaction';
import { useTranslation } from '@/hooks/translations';
import { getGstType } from '@/common/utility';
import { IPaymentDetailsResponse } from '@/components/InvoiceTable/types';
import { MultiLocale } from '@/components/TicketModal/utils';
import { Section } from '@/components/common';
import { IPaymentTransaction } from '@/views/Orders/types';
import { transformOrder } from '@/views/Orders';
import { IRestaurant } from '@/views/OrdersTableView/types';
import { IPayment, IRefundPaymentRecord } from '@/views/Payments';

import { Logo } from '../icon/logo';
import styles from './index.module.scss';

interface IProps {
    initReceipt?: IPaymentDetailsResponse | null;
    payment?: IPayment | null;
    vendor?: IRestaurant | null;
    currencySymbol: string;
    currencyCode: string;
    qrFile?: File | null;
    preview?: boolean;
    innerRef?: any;
}

export default function RefundReceipt({
    initReceipt,
    payment,
    vendor,
    currencySymbol,
    currencyCode,
    innerRef,
}: IProps) {
    const transactionService = TransactionService.getInstance();
    const { t } = useTranslation('common');
    const [transaction, setTransaction] = useState<IRefundPaymentRecord | null>(null);

    const [loading, setLoading] = useState(true);
    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';

    const findRefundRecordByReference = (refundTransaction: IPaymentTransaction) => {
        if (payment && refundTransaction) {
            const transactionRefundMatchedTransactionPayment = refundTransaction?.paymentRecord?.find((paymentRecord) =>
                payment.reference?.includes(paymentRecord?.reference),
            );

            if (transactionRefundMatchedTransactionPayment) {
                setTransaction({
                    ...transactionRefundMatchedTransactionPayment,
                    refund_amount: refundTransaction?.order?.refunded || '0.00',
                });
            }
        }
    };

    useEffect(() => {
        if (!vendor || (!payment && !initReceipt)) {
            return;
        }

        const transactionId = initReceipt?.dsi || payment?.transactionId || '';

        setLoading(true);
        transactionService
            .getTransaction(vendor.id, transactionId)
            .then((transactionRes) => {
                findRefundRecordByReference(
                    transformOrder(transactionRes.data.data, lang, currencyCode, currencySymbol),
                );
            })
            .finally(() => {
                setLoading(false);
            });
    }, [vendor, payment]);

    if (loading) {
        return (
            <div className={styles.loading}>
                <CircularProgress color="primary" size={24} />
            </div>
        );
    }

    return (
        <div className={classNames(styles.receipt)} ref={innerRef}>
            <div className={styles.container}>
                <header className={styles.header}>
                    <div className={styles.image}>
                        <Logo height="50" color="#ffffff" style={{ margin: '2.2rem' }} />
                    </div>
                    <h1 className={styles.title}>{vendor?.name || vendor?.title}</h1>
                    <p className={styles.pText} style={{ textAlign: 'center' }}>
                        {`${vendor?.address1}`}
                        <br />
                        {`${vendor?.address2}`}
                        <br />
                        <MultiLocale value={vendor?.config?.legalBusinessName || ''} lang={lang} />
                        <br />
                        {vendor?.config?.vatRegistrationNumber &&
                            t(`${getGstType(vendor?.country_code)}: {{vatRegistrationNumber}}`, {
                                vatRegistrationNumber: vendor?.config?.vatRegistrationNumber || '',
                            })}
                    </p>
                </header>
            </div>
            <Section sx={{ border: 'none', padding: 0, maxWidth: '420px', width: '100%' }}>
                <div className={styles.divider} />
                <div className={styles.infoContainer}>
                    <p className={styles.pText} style={{ fontWeight: 600 }}>
                        {t('Refunded')}
                    </p>
                    <p
                        className={styles.pText}
                        style={{ fontWeight: 600 }}
                    >{`${currencySymbol} ${payment?.paidAmount}`}</p>
                </div>
                <div className={styles.infoContainer} style={{ paddingBottom: '8px' }}>
                    <p className={styles.pText} style={{ fontWeight: 600 }}>
                        {t('Date Issued')}
                    </p>
                    <p className={styles.pText} style={{ fontWeight: 600 }}>
                        {payment?.date}
                    </p>
                </div>

                <div className={styles.divider} />
                <div className={styles.infoContainer} style={{ paddingTop: '10px' }}>
                    <p className={styles.pText}>{t('Order ID')}</p>

                    <p className={styles.pText}>{payment?.orderId}</p>
                </div>
                <div className={styles.infoContainer}>
                    <p className={styles.pText}>{t('Bill Payment')}</p>
                    <p className={styles.pText}>{`${currencySymbol} ${payment?.billAmount}`}</p>
                </div>
                <div className={styles.infoContainer}>
                    <p className={styles.pText}>{t('Tip Payment')}</p>
                    <p className={styles.pText}>{`${currencySymbol} ${transaction?.tip_amount}`}</p>
                </div>
                <div className={styles.infoContainer}>
                    <p className={styles.pText}>{t('Total Refunded Amount')}</p>
                    <p className={styles.pText}>{transaction?.refund_amount}</p>
                </div>
                <br />

                <p className={styles.pText}>
                    {t('Your refund amount will be credited to the channel you made the payment to.')}
                </p>
                <br />

                <p className={styles.pText}>
                    {t(
                        `This receipt is to confirm that your refund has been issued by ${
                            vendor?.name || vendor?.title
                        }`,
                    )}
                </p>
            </Section>
        </div>
    );
}
