interface ITrans {
    name?: string;
    subTitle?: string;
    desc?: string;
    nameTranslations?: Record<string, unknown>;
    subTitleTranslations?: Record<string, unknown>;
    descTranslations?: Record<string, unknown>;
}

export interface IProps {
    lang: string;
    obj: ITrans | undefined;
    def?: string;
    reverse?: boolean;
}

export const NameTrans = ({ lang, obj, def, reverse }: IProps) => {
    if (!lang) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{obj?.name}</>;
    }
    const out = String(obj?.nameTranslations?.[lang] || obj?.name || def || '');
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{reverse ? out.split('').reverse().join('') : out}</>;
};

export const SubTitleTrans = ({ lang, obj, def, reverse }: IProps) => {
    if (!lang) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{obj?.subTitle}</>;
    }
    const out = String(obj?.subTitleTranslations?.[lang] || obj?.name || def || '');
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{reverse ? out.split('').reverse().join('') : out}</>;
};

export const DescTrans = ({ lang, obj, def, reverse }: IProps) => {
    if (!lang) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{obj?.desc}</>;
    }
    const out = String(obj?.descTranslations?.[lang] || obj?.name || def || '');
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{reverse ? out.split('').reverse().join('') : out}</>;
};
