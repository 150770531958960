import CustomModal from '@/components/CustomModal';
import { useTranslation } from '@/hooks/translations';
import { Button, Alert, Grid } from '@mui/material';
import React from 'react';
import { IVendorQsrOrderConfig } from '@/views/OrdersTableView/types';

interface IProps {
    config: IVendorQsrOrderConfig;
    open: boolean;
    onClose: () => void;
}

const SSLModal = ({ config, open, onClose }: IProps) => {
    const { t } = useTranslation('common');
    return (
        <CustomModal open={open} title={t('Printer SSL Certificate')} maxWidth="sm" onClose={onClose}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Alert variant="outlined" color="warning">
                        {t(
                            'In order to connect to printer you have to accept the self-signed certificate in the browser, Please proceed to the link below and accept the unsafe certificate!',
                        )}
                    </Alert>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        onClick={() => {
                            onClose();
                        }}
                        variant="outlined"
                        target="_blank"
                        href={`https://${config.printerIP}:${config.printerPort}/cgi-bin/epos/service.cgi?devid=${config.printerDeviceId}&timeout=10000`}
                        fullWidth
                    >
                        {t('Printer interface')}
                    </Button>
                </Grid>
            </Grid>
        </CustomModal>
    );
};

export default SSLModal;
