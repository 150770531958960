import { ColorEnum, ConditionEnum, IconEnum, ILayout, StyleEnum } from './types';

export const getDefaultBillLayout = (cc: string): ILayout[] => {
    switch (cc) {
        default:
        case 'ae':
            return [
                {
                    key: 'def_subTotal',
                    format: `{cs} {0}`,
                    style: StyleEnum.TitleSmall,
                    vcond: ConditionEnum.GTD,
                },
                {
                    key: 'def_qlubDiscount',
                    format: `{cs} {0}`,
                    color: ColorEnum.Green,
                    style: StyleEnum.BodyMedium,
                    icon: IconEnum.Discount,
                    vcond: ConditionEnum.GT,
                },
                {
                    key: 'def_total',
                    format: `{cs} {0}`,
                    style: StyleEnum.TitleSmall,
                    icon: IconEnum.Dollar,
                },
            ];
        case 'sa':
            return [
                {
                    key: 'def_subTotal',
                    format: `{cs} {0}`,
                    style: StyleEnum.TitleSmall,
                    vcond: ConditionEnum.GTD,
                },
                {
                    key: 'def_qlubDiscount',
                    format: `{cs} {0}`,
                    color: ColorEnum.Green,
                    style: StyleEnum.BodyMedium,
                    icon: IconEnum.Discount,
                    vcond: ConditionEnum.GT,
                },
                {
                    key: 'def_total',
                    format: `{cs} {0}`,
                    style: StyleEnum.TitleSmall,
                    icon: IconEnum.Rial,
                },
            ];
        case 'in':
            return [
                {
                    key: 'def_subTotal',
                    format: `{cs} {0}`,
                    style: StyleEnum.TitleSmall,
                    vcond: ConditionEnum.GTD,
                },
                {
                    key: 'def_vat',
                    format: `{cs} {0}`,
                    style: StyleEnum.BodyMedium,
                    icon: IconEnum.Bill,
                    vcond: ConditionEnum.GT,
                },
                {
                    key: 'def_qlubDiscount',
                    format: `{cs} {0}`,
                    color: ColorEnum.Green,
                    style: StyleEnum.BodyMedium,
                    icon: IconEnum.Discount,
                    vcond: ConditionEnum.GT,
                },
                {
                    key: 'def_total',
                    format: `{cs} {0}`,
                    style: StyleEnum.TitleSmall,
                    icon: IconEnum.Dollar,
                },
            ];
        case 'sg':
        case 'br':
        case 'tr':
            return [
                {
                    key: 'def_qlubDiscount',
                    format: `{cs} {0}`,
                    color: ColorEnum.Green,
                    style: StyleEnum.BodyMedium,
                    icon: IconEnum.Discount,
                    vcond: ConditionEnum.GT,
                },
                {
                    key: 'def_total',
                    format: `{cs} {0}`,
                    style: StyleEnum.TitleSmall,
                    icon: IconEnum.Dollar,
                },
            ];
        case 'jp':
            return [
                {
                    key: 'def_subTotal',
                    format: `{cs} {0}`,
                    style: StyleEnum.BodyMedium,
                    icon: IconEnum.Bill,
                },
                {
                    key: 'def_vat',
                    format: `{cs} {0}`,
                    style: StyleEnum.BodyMedium,
                    icon: IconEnum.Bill,
                },
                {
                    key: 'def_qlubDiscount',
                    format: `{cs} {0}`,
                    color: ColorEnum.Green,
                    style: StyleEnum.BodyMedium,
                    icon: IconEnum.Discount,
                    vcond: ConditionEnum.GT,
                },
                {
                    key: 'def_total',
                    format: `{cs} {0}`,
                    style: StyleEnum.TitleSmall,
                    icon: IconEnum.Yen,
                },
            ];
        case 'au':
            return [
                {
                    key: 'sum_inclusive',
                    format: `{cs} {0}`,
                    style: StyleEnum.TitleSmall,
                    icon: IconEnum.Bill,
                },
                {
                    key: 'tax_exclusive',
                    format: `{cs} {0}`,
                    style: StyleEnum.BodyMedium,
                },
                {
                    key: 'tax_inclusive',
                    format: `{cs} {0}`,
                    style: StyleEnum.BodyMedium,
                    vcond: ConditionEnum.GT,
                },
                {
                    key: 'discount_inclusive',
                    format: `{cs} {0}`,
                    style: StyleEnum.BodyMedium,
                    vcond: ConditionEnum.NE,
                },
                {
                    key: 'fee_inclusive',
                    format: `{cs} {0}`,
                    style: StyleEnum.BodyMedium,
                    vcond: ConditionEnum.NE,
                },
                {
                    key: 'discount_exclusive',
                    format: `{cs} {0}`,
                    style: StyleEnum.BodyMedium,
                    vcond: ConditionEnum.NE,
                },
                {
                    key: 'def_qlubDiscount',
                    format: `{cs} {0}`,
                    color: ColorEnum.Green,
                    style: StyleEnum.BodyMedium,
                    icon: IconEnum.Discount,
                    vcond: ConditionEnum.GT,
                },
                {
                    key: 'def_total',
                    format: `{cs} {0}`,
                    style: StyleEnum.TitleMedium,
                    icon: IconEnum.Dollar,
                },
                {
                    key: 'def_vat',
                    format: `{cs} {0}`,
                    style: StyleEnum.TitleSmall,
                    locales: [
                        {
                            locale: 'en',
                            value: 'GST',
                        },
                    ],
                },
            ];
    }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getDefaultOrderLayout = (cc: string): ILayout[] => {
    switch (cc) {
        default:
        case 'ae':
        case 'sa':
            return [
                {
                    key: 'def_order',
                    format: `{cs} {0}`,
                    style: StyleEnum.BodyMedium,
                    color: ColorEnum.HighEmphasis,
                    hideAltValue: true,
                },
                {
                    key: 'def_order_topping',
                    format: `{cs} {0}`,
                    style: StyleEnum.BodyMedium,
                    color: ColorEnum.MediumEmphasis,
                    hideAltValue: true,
                },
            ];
        case 'in':
        case 'sg':
        case 'br':
        case 'tr':
            return [
                {
                    key: 'def_order',
                    format: `{cs} {0}`,
                    style: StyleEnum.BodyMedium,
                    color: ColorEnum.HighEmphasis,
                },
                {
                    key: 'def_order_topping',
                    format: `{cs} {0}`,
                    style: StyleEnum.BodyMedium,
                    color: ColorEnum.MediumEmphasis,
                },
            ];
        case 'jp':
            return [
                {
                    key: 'def_order',
                    format: `{cs} {0}`,
                    style: StyleEnum.BodyMedium,
                    color: ColorEnum.HighEmphasis,
                },
                {
                    key: 'def_order_topping',
                    format: `{cs} {0}`,
                    style: StyleEnum.BodyMedium,
                    color: ColorEnum.MediumEmphasis,
                },
                {
                    key: 'def_additives',
                    format: `{cs} {0}`,
                    style: StyleEnum.BodySmall,
                    color: ColorEnum.MediumEmphasis,
                    vcond: ConditionEnum.GT,
                },
            ];
        case 'au':
            return [
                {
                    key: 'def_order',
                    format: `{cs} {0}`,
                    style: StyleEnum.BodyMedium,
                    color: ColorEnum.HighEmphasis,
                },
                {
                    key: 'def_order_topping',
                    format: `{cs} {0}`,
                    style: StyleEnum.BodyMedium,
                    color: ColorEnum.MediumEmphasis,
                },
                {
                    key: 'def_additives',
                    format: `{cs} {0}`,
                    style: StyleEnum.BodySmall,
                    color: ColorEnum.MediumEmphasis,
                    vcond: ConditionEnum.NE,
                },
            ];
    }
};
