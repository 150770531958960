import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { TFunction } from 'i18next';
import {
    startOfYesterday,
    endOfMonth,
    startOfMonth,
    startOfWeek,
    endOfWeek,
    startOfQuarter,
    endOfQuarter,
    format,
    endOfToday,
} from 'date-fns';

import { useTranslation } from '@/hooks/translations';
import { TimeRangeTypes } from '../types';
import { getLocaleLang } from '../utils';

interface IOption {
    name: TimeRangeTypes;
    title: string;
    dateString: string;
}

export const timeRangeTitles = (t: TFunction): Record<TimeRangeTypes, string> => ({
    [TimeRangeTypes.TODAY]: t('Today'),
    [TimeRangeTypes.YESTERDAY]: t('Yesterday'),
    [TimeRangeTypes.WEEK]: t('This Week'),
    [TimeRangeTypes.LAST_7_DAYS]: t('Last 7 Days'),
    [TimeRangeTypes.MONTH]: t('This Month'),
    [TimeRangeTypes.LAST_MONTH]: t('Last Month'),
    [TimeRangeTypes.LAST_30_DAYS]: t('Last 30 Days'),
    [TimeRangeTypes.QUARTER]: t('This Quarter'),
    [TimeRangeTypes.ALL]: t('All'),
    [TimeRangeTypes.CUSTOM]: t('Custom'),
});

export const useData = (options?: TimeRangeTypes[]) => {
    const { t } = useTranslation('common');
    const router = useRouter();
    const lang: string = router.query.lang as string;

    const titles = timeRangeTitles(t);
    const defaultOptions: TimeRangeTypes[] = [
        TimeRangeTypes.TODAY,
        TimeRangeTypes.MONTH,
        TimeRangeTypes.LAST_30_DAYS,
        TimeRangeTypes.ALL,
    ];

    const timeRangeOptions = useMemo(() => {
        const date = new Date();
        const rangeOptions: IOption[] = [];

        const getRangeOption = (name: TimeRangeTypes) => {
            switch (name) {
                case TimeRangeTypes.YESTERDAY:
                    return format(startOfYesterday(), 'LLLL dd', { locale: getLocaleLang(lang) });
                case TimeRangeTypes.WEEK:
                    return `${format(startOfWeek(date, { weekStartsOn: 1 }), 'LLLL dd', {
                        locale: getLocaleLang(lang),
                    })}-${format(endOfWeek(date, { weekStartsOn: 1 }), 'LLLL dd', {
                        locale: getLocaleLang(lang),
                    })}`;
                case TimeRangeTypes.LAST_7_DAYS:
                    return `${format(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7), 'LLLL dd', {
                        locale: getLocaleLang(lang),
                    })}-${format(endOfToday(), 'LLLL dd', {
                        locale: getLocaleLang(lang),
                    })}`;
                case TimeRangeTypes.MONTH:
                    return `${format(startOfMonth(date), 'LLLL dd', { locale: getLocaleLang(lang) })}-${format(
                        endOfMonth(date),
                        'LLLL dd',
                        {
                            locale: getLocaleLang(lang),
                        },
                    )}`;
                case TimeRangeTypes.LAST_MONTH:
                    return `${format(new Date(date.getFullYear(), date.getMonth() - 1, date.getDate()), 'LLLL dd', {
                        locale: getLocaleLang(lang),
                    })}-${format(endOfToday(), 'LLLL dd', {
                        locale: getLocaleLang(lang),
                    })}`;
                case TimeRangeTypes.LAST_30_DAYS:
                    return `${format(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30), 'LLLL dd', {
                        locale: getLocaleLang(lang),
                    })}-${format(endOfToday(), 'LLLL dd', {
                        locale: getLocaleLang(lang),
                    })}`;
                case TimeRangeTypes.QUARTER:
                    return `${format(startOfQuarter(date), 'LLLL dd', {
                        locale: getLocaleLang(lang),
                    })}-${format(endOfQuarter(date), 'LLLL dd', { locale: getLocaleLang(lang) })}`;
                case TimeRangeTypes.ALL:
                    return '';
                case TimeRangeTypes.TODAY:
                default:
                    return format(date, 'LLLL dd', { locale: getLocaleLang(lang) });
            }
        };
        (options || defaultOptions).forEach((option) => {
            rangeOptions.push({ name: option, title: titles[option], dateString: getRangeOption(option) });
        });

        return rangeOptions;
    }, [router?.query?.lang, options]);

    return { timeRangeOptions };
};
