export const getCurrencyPrecision = (currencyCode: string | undefined): number => {
    switch (currencyCode?.toLowerCase()) {
        case 'jpy':
        case 'irr':
        case 'kmf':
        case 'krw':
        case 'pyg':
        case 'rwf':
        case 'ugx':
        case 'vuv':
        case 'vnd':
        case 'xaf':
        case 'xof':
        case 'xpf':
            return 0;
        case 'kwd':
        case 'jod':
        case 'bhd':
        case 'iqd':
        case 'lyd':
        case 'omr':
        case 'tnd':
            return 3;
        case 'aed':
        case 'usd':
        case 'sar':
        case 'qar':
        default:
            return 2;
    }
};
