import { IOrder, IOrderItem } from '@/views/QsrOrders/types';
import { uniq } from 'lodash';

const getProductIds = (it: IOrderItem): string[] => {
    const ids: string[] = [];

    const traverseItem = (item: IOrderItem) => {
        if (!item) {
            return;
        }

        ids.push(item.id);
        if (item.optionId) {
            ids.push(item.optionId);
        }
        if (item.additives && item.additives.length > 0) {
            item.additives.forEach((additive) => traverseItem(additive));
        }
    };

    traverseItem(it);

    return ids;
};

export const getAllProductsFromOrderData = (order: IOrder) => {
    return uniq(
        order.items.reduce<string[]>((a, b) => {
            a.push(...getProductIds(b));
            return a;
        }, []),
    );
};

const getCategoryIds = (it: IOrderItem): string[] => {
    const ids: string[] = [];

    const traverseItem = (item: IOrderItem) => {
        if (!item) {
            return;
        }

        if (item.mgId) {
            ids.push(item.mgId);
        }
        if (item.additives && item.additives.length > 0) {
            item.additives.forEach((additive) => traverseItem(additive));
        }
    };

    traverseItem(it);

    return ids;
};

export const getAllCategoriesFromOrderData = (order: IOrder) => {
    return uniq(
        order.items.reduce<string[]>((a, b) => {
            a.push(...getCategoryIds(b));
            return a;
        }, []),
    );
};
