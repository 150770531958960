import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { useTheme } from '@mui/material';
import { IProps } from '.';

const useDividerStyle = (props: IProps) => {
    const theme = useTheme();

    const dividerStyle: SxProps = {
        width: '100%',
        backgroundColor: '#EBECF2',
        height: props.type === 'medium' ? '2px' : props.type === 'large' ? '3px' : '1px',
        ...(props.type === 'fullWidth'
            ? {
                  height: '1px',
                  paddingLeft: 'inherit',
                  paddingRight: 'inherit',
                  position: 'absolute',
                  left: 0,
                  right: 0,
              }
            : {}),
        display: 'flex',
        justifyContent: 'center',
        '& p': {
            position: 'relative',
            top: '-10px',
            background: theme.palette.common.white,
            color: '#1A1C23',
            display: 'block',
            minWidth: '25px',
            padding: '0 12px',
            height: '20px',
            textAlign: 'center',
        },
    };

    return {
        dividerStyle,
    };
};

export default useDividerStyle;
