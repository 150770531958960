import { cloneDeep } from 'lodash';

interface ID {
    id?: any;
    code?: any;
}

interface IPromise {
    resolve: any;
    reject: any;
}

interface Many<T> {
    rows: T[];
    count?: number;
}

interface IOptions {
    useRequestCache?: boolean;
    useItemCache?: boolean;
}

interface IOptionsFull extends IOptions {
    keyFn?: (params: any) => string;
}

export class SingleFlight<T extends ID> {
    private singleFlightMap: { [key: string]: IPromise[] } = {};

    private cache: { [key: string]: T } = {};

    private requestCache: { [key: string]: Many<T> } = {};

    private readonly keyFn: (params: any) => string = (params: any) => JSON.stringify(params);

    private fetchManyFn?: (params: any) => Promise<Many<T>>;

    private fetchOneFn?: (params: any) => Promise<T>;

    private options: IOptions = {};

    constructor(
        fetchFns: {
            many?: (params: any) => Promise<Many<T>>;
            one?: (params: any) => Promise<T>;
        },
        options?: IOptionsFull,
    ) {
        this.fetchManyFn = fetchFns.many;
        this.fetchOneFn = fetchFns.one;
        if (options?.keyFn) {
            this.keyFn = options.keyFn;
        }
        this.options = {
            ...options,
        };
    }

    public clearCache() {
        this.cache = {};
        this.requestCache = {};
    }

    public getMany(params: any): Promise<Many<T>> {
        if (!this.fetchManyFn) {
            return Promise.reject(new Error('fetch many function is not being set'));
        }
        const key = `m_${this.keyFn(params)}`;
        if (this.options.useRequestCache && this.requestCache.hasOwnProperty(key)) {
            return Promise.resolve(cloneDeep(this.requestCache[key]));
        }
        return this.get<Many<T>>(this.fetchManyFn, params, key, (res) => {
            this.requestCache[key] = cloneDeep(res);
            if (this.options.useItemCache) {
                res.rows?.forEach((item) => {
                    if (item.id || item.code) {
                        this.cache[item.id || item.code] = item;
                    }
                });
            }
            return res;
        });
    }

    public getOne(id: any): Promise<T> {
        if (!this.fetchOneFn) {
            return Promise.reject(new Error('fetch one function is not being set'));
        }
        if (this.options.useItemCache && this.cache.hasOwnProperty(id)) {
            return Promise.resolve(cloneDeep(this.cache[id]));
        }
        return this.get<T>(this.fetchOneFn, id, id, (res) => {
            this.cache[id] = cloneDeep(res);
            return res;
        });
    }

    private get<P>(fn: any, params: any, key: string, middleware: (arg: P) => P): Promise<P> {
        return new Promise((resolve, reject) => {
            if (this.singleFlightMap.hasOwnProperty(key)) {
                this.singleFlightMap[key].push({
                    resolve,
                    reject,
                });
            } else {
                this.singleFlightMap[key] = [
                    {
                        resolve,
                        reject,
                    },
                ];
                fn(params)
                    .then((res: any) => {
                        return middleware(res);
                    })
                    .then((res: any) => {
                        this.singleFlightMap[key].forEach((promise) => {
                            promise.resolve(cloneDeep(res));
                        });
                    })
                    .catch((err: any) => {
                        this.singleFlightMap[key].forEach((promise) => {
                            promise.reject(err);
                        });
                    })
                    .finally(() => {
                        delete this.singleFlightMap[key];
                    });
            }
        });
    }
}
