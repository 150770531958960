import { createContext, ReactNode, useState, useContext } from 'react';

const NavbarContext = createContext<any>({
    isSettingsOpen: false,
    refresh: false,
});

type Props = {
    children?: ReactNode;
};

export function NavbarContextProvider({ children }: Props) {
    const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
    const [refresh, toggleRefresh] = useState<boolean>(false);

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <NavbarContext.Provider value={{ isSettingsOpen, setIsSettingsOpen, refresh, toggleRefresh }}>
            {' '}
            {children}{' '}
        </NavbarContext.Provider>
    );
}

export const useNavbarContext = () => useContext(NavbarContext);
