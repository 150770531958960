import axios from '@/config/axios';
import { ILoyaltyProgram, ILoyaltyMember, ILoyaltyTransaction } from '@/interfaces/loyalty/types';
import { transformError } from '.';

const endpoints = {
    GET_RESTAURANT_ENROLLED_PROGRAMS: 'vendor/loyalty-program/enrolled',
    CREATE_MEMBER: 'vendor/customer/create',
    GET_PROGRAM_MEMBERS: 'vendor/customer/programId/:programId?restaurantId=:restaurantId',
    UPDATE_MEMBER: 'vendor/customer/update/:memberId',
    GET_PROGRAM_MEMBER: 'vendor/customer/id/:memberId?restaurantId=:restaurantId',
    GET_MEMBER_TRANSACTIONS: 'vendor/customer/transactions/:memberId',
};

class LoyaltyService {
    public static getInstance() {
        if (!this.instance) {
            this.instance = new LoyaltyService();
        }

        return this.instance;
    }

    private static instance: LoyaltyService;

    public async getRestaurantEnrolledPrograms(data: Record<string, any>): Promise<{ programs: ILoyaltyProgram[] }> {
        try {
            const res = await axios.get<any>(endpoints.GET_RESTAURANT_ENROLLED_PROGRAMS, {
                params: data,
            });
            return res.data?.data;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async createMember(data: Record<string, any>): Promise<ILoyaltyMember> {
        try {
            const res = await axios.post<any>(endpoints.CREATE_MEMBER, data);
            return res.data?.data;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async getProgramMembers(
        programId: string,
        restaurantId: string,
        page = 0,
        limit = 10,
        search: string | undefined = undefined,
    ): Promise<{ customers: ILoyaltyMember[]; count: number }> {
        try {
            const res = await axios.get<any>(
                endpoints.GET_PROGRAM_MEMBERS.replace(':programId', programId).replace(':restaurantId', restaurantId),
                {
                    params: { page, limit, ...(search && { search }) },
                },
            );
            return res.data?.data;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async updateMember(memberId: string, data: Record<string, any>): Promise<ILoyaltyMember> {
        try {
            const res = await axios.patch<any>(endpoints.UPDATE_MEMBER.replace(':memberId', memberId), data);
            return res.data?.data;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async getProgramMember(memberId: string, programId: string, restaurantId: string): Promise<ILoyaltyMember> {
        try {
            const res = await axios.get<any>(
                endpoints.GET_PROGRAM_MEMBER.replace(':memberId', memberId).replace(':restaurantId', restaurantId),
                {
                    params: { programId },
                },
            );
            return res.data?.data;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async getMemberTransactions(
        memberId: string,
        programId: string,
        page = 0,
        limit = 10,
        program: string | undefined = 'qlub',
    ): Promise<{ transfers: ILoyaltyTransaction[]; count: number }> {
        try {
            const res = await axios.get<any>(endpoints.GET_MEMBER_TRANSACTIONS.replace(':memberId', memberId), {
                params: {
                    page,
                    pageSize: limit,
                    programId,
                    program,
                    transferTypes: ['loyalty-burn', 'loyalty-earn', 'loyalty-move'],
                },
            });
            return res.data?.data;
        } catch (err) {
            throw transformError(err);
        }
    }
}

export default LoyaltyService;
