import { onPushEvent } from '@/services/lib/gtm';
import { useState, useEffect, useRef } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import useDebounce from './useDebounce';

export type OrderTypes = SortOrder; /* 'desc' | 'asc' | undefined; */

interface UseDataTable<T, OrderByTypes> {
    withSearch?: boolean;
    withCountryCode?: boolean;
    withTerm?: boolean;
    withFilters?: boolean;
    dataFetcher: (requestData: TableRequestData) => Promise<{ list: T[]; total: number }>;
    defaultOrderByOrder?: SortOrder;
    defaultOrderByColumn?: OrderByTypes;
}

export interface TableRequestData {
    page?: number;
    limit?: number;
    search?: string | null;
    country_code?: string | null;
    type?: string | null;
    term?: string | null;
    terms?: string[];
    filters?: string[];
    start_date?: string;
    end_date?: string;
    sortBy?: {
        column: any;
        order: OrderTypes;
    } | null;
}

export default function useDataTable<T, OrderByTypes = string>({
    withSearch,
    withCountryCode,
    withTerm,
    withFilters,
    dataFetcher,
    defaultOrderByOrder,
    defaultOrderByColumn,
}: UseDataTable<T, OrderByTypes>) {
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [list, setList] = useState<T[]>([]);
    const [search, setSearch] = useState<string>('');
    const [countryCode, setCountryCode] = useState<string | null>(null);
    const [term, setTerm] = useState<string | null>(null);
    const [filters, setFilters] = useState<Array<string>>([]);
    const debouncedSearch = useDebounce(search, 1000);
    const [orderByOrder, setOrderByOrder] = useState<any>(defaultOrderByOrder || 'asc');
    const [orderByColumn, setOrderByColumn] = useState<TableColumn<T> | null>(defaultOrderByColumn || null);
    const lastUnix = useRef(0);

    const tablePageChangeHandler = (p: number) => {
        setPage(p);
    };

    const tableRowsPerPageChangeHandler = (val: number) => {
        setLimit(val);
        onPushEvent('user_click_on_choose_rows_per_page');
    };

    const getData = async () => {
        const time = Date.now();
        if (time - lastUnix.current < 500) {
            return;
        }
        lastUnix.current = time;

        if (loading) {
            return;
        }

        setLoading(true);

        dataFetcher({
            page: Number(page) - 1,
            limit,
            ...(search ? { search: debouncedSearch } : {}),
            ...(withCountryCode && countryCode ? { country_code: countryCode } : {}),
            ...(withTerm && term ? { term } : {}),
            ...(withFilters && filters ? { filters } : {}),
            ...(orderByOrder && orderByColumn
                ? {
                      sortBy: {
                          column: orderByColumn,
                          order: orderByOrder,
                      },
                  }
                : {}),
        })
            .then((res) => {
                setTotal(res.total);
                setList(res.list);
            })
            .finally(() => setLoading(false))
            .catch((err: any) => console.log(err));
    };

    useEffect(() => {
        getData();
    }, [
        page,
        limit,
        ...(withSearch ? [debouncedSearch] : []),
        ...(withCountryCode ? [countryCode] : []),
        ...(withTerm ? [term] : []),
        ...(withFilters ? [filters] : []),
        orderByOrder,
        orderByColumn,
    ]);

    return {
        page,
        setPage,
        tablePageChangeHandler,

        orderByOrder,
        setOrderByOrder,
        orderByColumn,
        setOrderByColumn,

        loading,
        setLoading,
        limit,
        setLimit,
        tableRowsPerPageChangeHandler,
        total,
        setTotal,
        list,
        setList,

        searchEnabled: withSearch,
        ...(withSearch
            ? {
                  search,
                  setSearch,
              }
            : {}),

        countryCodeEnabled: withCountryCode,
        ...(withCountryCode
            ? {
                  countryCode,
                  setCountryCode,
              }
            : {}),

        termEnabled: withTerm,
        ...(withTerm
            ? {
                  term,
                  setTerm,
              }
            : {}),

        filtersEnabled: withFilters,
        ...(withFilters
            ? {
                  filters,
                  setFilters,
              }
            : {}),

        getData,
    };
}
