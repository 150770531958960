import React from 'react';
import { Chip, Typography, Box, Grid, SxProps } from '@mui/material';
import { ITableParams } from '@/views/OrdersTableView/types';
import { isTableParamMatch } from '@/services/utils/table';

interface IProps {
    options: ITableParams[];
    values: ITableParams[];
    onChange: (values: ITableParams[]) => void;
}

const ChipProps: SxProps = {
    display: 'flex',
    borderRadius: '4px',
};

const BoxProps: SxProps = { height: 'calc(100vh - 196px)' };

const MultipleSelectChips = ({ options, values, onChange }: IProps) => {
    const handleClick = (val: ITableParams) => {
        const idx = values.findIndex((value) => isTableParamMatch(val, value));
        if (idx > -1) {
            const arr = [...values];
            arr.splice(idx, 1);
            onChange(arr);
        } else {
            onChange([...values, val]);
        }
    };
    return (
        <Box sx={BoxProps}>
            <Grid container spacing={1}>
                {options.map((option, key) => (
                    <Grid key={key} item xs={4}>
                        <Chip
                            color="primary"
                            variant={values.find((value) => isTableParamMatch(value, option)) ? 'filled' : 'outlined'}
                            label={<Typography variant="body2">{option.name || option.id}</Typography>}
                            clickable
                            onClick={() => handleClick(option)}
                            sx={ChipProps}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default MultipleSelectChips;
