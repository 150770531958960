import { Field, FieldProps } from 'formik';
import { Collapse, FormControlLabel, FormLabel, Checkbox, Grid, Box, TextField } from '@mui/material';
import { useTranslation } from '@/hooks/translations';
import TimeRangeForm from '@/components/TimeRange/Form';
import { TimeRangeTypes } from '@/components/TimeRange/Form/types';
import { Section } from '../../common';

interface GenerateReportSectionProps {
    showGenerateForm: boolean;
    handleGenerateCheckboxChange: (event: any) => void;
}

const GenerateReportNow = ({ showGenerateForm, handleGenerateCheckboxChange }: GenerateReportSectionProps) => {
    const { t } = useTranslation('common');

    return (
        <Section>
            <FormControlLabel
                control={<Checkbox checked={showGenerateForm} onChange={handleGenerateCheckboxChange} />}
                label={t('Generate report now')}
            />
            <Collapse in={showGenerateForm}>
                <Grid item xs={12}>
                    <Box sx={{ marginBottom: '10px' }}>
                        <FormLabel sx={{ fontWeight: '600' }}>{t('Covered Period')}</FormLabel>
                    </Box>
                    <TimeRangeForm timeSelectEnabled options={[TimeRangeTypes.TODAY, TimeRangeTypes.YESTERDAY]} />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel sx={{ fontWeight: '600' }}>{t('File Name')}</FormLabel>
                    <Field name="fileName">
                        {({ field }: FieldProps) => (
                            <TextField {...field} type="text" fullWidth size="small" sx={{ marginTop: '5px' }} />
                        )}
                    </Field>
                </Grid>
            </Collapse>
        </Section>
    );
};

export default GenerateReportNow;
