import { useMemo } from 'react';
import { OrderItemStatusEnum } from '@/views/QsrOrders/types';
import { useTranslation } from '@/hooks/translations';
import classNames from 'classnames';

import styles from './index.module.scss';

interface IProps {
    status?: OrderItemStatusEnum;
}

const QsrBatchStatus = ({ status }: IProps) => {
    const { t } = useTranslation('common');

    const title = useMemo(() => {
        switch (status) {
            default:
            case OrderItemStatusEnum.New:
                return t('new');
            case OrderItemStatusEnum.Approved:
                return t('approved');
            case OrderItemStatusEnum.Completed:
                return t('completed');
            case OrderItemStatusEnum.Rejected:
                return t('rejected');
        }
    }, [status, t]);

    const style = useMemo(() => {
        switch (status) {
            default:
            case OrderItemStatusEnum.New:
                return styles.new;
            case OrderItemStatusEnum.Approved:
                return styles.approved;
            case OrderItemStatusEnum.Completed:
                return styles.completed;
            case OrderItemStatusEnum.Rejected:
                return styles.rejected;
        }
    }, [status]);

    return <div className={classNames(styles.container, style)}>{title}</div>;
};

export default QsrBatchStatus;
