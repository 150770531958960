import { ja, enUS, ar, ptBR, zhCN, tr, es, ko } from 'date-fns/locale';

export const getLocale = (lang: string) => {
    switch (lang) {
        default:
        case 'en':
            return enUS;
        case 'ja':
            return ja;
        case 'ar':
            return ar;
        case 'pt':
            return ptBR;
        case 'tr':
            return tr;
        case 'zh':
            return zhCN;
        case 'es':
            return es;
        case 'kr':
            return ko;
    }
};
