import React, { useEffect, useState } from 'react';
import {
    Autocomplete,
    TextField,
    ListItemText,
    MenuItem,
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    Paper,
} from '@mui/material';
import { ITinyRestaurant } from '@/views/OrdersTableView/types';
import { useTranslation } from '@/hooks/translations';
import RestaurantService from '@/services/restaurant';

interface Props {
    onChange: (val: string[]) => void;
    restaurantListToExport?: string[];
}

export const SelectMultipleRestaurants = ({ onChange, restaurantListToExport }: Props) => {
    const restaurantService = RestaurantService.getInstance();
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(false);
    const [choices, setChoices] = useState<ITinyRestaurant[]>([]);
    const [selectAll, setSelectAll] = useState(false);

    const getRestaurantList = () => {
        setLoading(true);
        restaurantService
            .searchRestaurants({ search: '' })
            .then((data) => {
                setChoices(data || []);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getRestaurantList();
    }, []);

    const handleSelectAllChange = () => {
        setSelectAll(!selectAll);
        onChange(selectAll ? [] : choices.map((o) => o.id));
    };

    return (
        <Autocomplete
            multiple
            value={choices.filter((o) => restaurantListToExport?.includes(o.id))}
            loading={loading}
            options={choices}
            getOptionLabel={(option) => option.title || option.name || option.restaurant_unique}
            renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" margin="dense" placeholder={t('restaurants')} />
            )}
            renderOption={(props, option, { selected }) => (
                <MenuItem {...props} selected={selectAll ? true : selected}>
                    <ListItemText primary={option.name || option.title} secondary={option.restaurant_unique} />
                </MenuItem>
            )}
            PaperComponent={(paperProps) => {
                const { children, ...restPaperProps } = paperProps;
                return (
                    <Paper {...restPaperProps}>
                        <Box
                            onMouseDown={(e) => e.preventDefault()} // prevent blur
                            pl={1.5}
                            py={0.5}
                        >
                            <FormControlLabel
                                onClick={(e) => {
                                    e.preventDefault(); // prevent blur
                                    handleSelectAllChange();
                                }}
                                label="Select all"
                                control={<Checkbox id="select-all-checkbox" checked={selectAll} />}
                            />
                        </Box>
                        <Divider />
                        {children}
                    </Paper>
                );
            }}
            onChange={(e, val) => {
                if (val.length !== choices.length) {
                    setSelectAll(false);
                }
                onChange(val.map((o) => o.id));
            }}
        />
    );
};
