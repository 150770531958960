// @ts-ignore
const en = require('../public/locales/en/common.json');
const de = require('../public/locales/de/common.json');
const ja = require('../public/locales/ja/common.json');
const pt = require('../public/locales/pt/common.json');
const ar = require('../public/locales/ar/common.json');
const tr = require('../public/locales/tr/common.json');
const zh = require('../public/locales/zh/common.json');
const es = require('../public/locales/es/common.json');
const kr = require('../public/locales/kr/common.json');

const i18n = {
    translations: {
        ar,
        de,
        en,
        es,
        ja,
        kr,
        pt,
        tr,
        zh,
    },
    defaultLang: 'en',
};

module.exports = i18n;
