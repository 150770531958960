import { getIcon, useLanguage } from '@/constants/translations';
import { onPushEvent } from '@/services/lib/gtm';
import { updateQueryStringParameter } from '@/services/utils/k_url';
import { useLanguageQuery } from 'next-export-i18n';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Avatar, Box, IconButton, Menu, MenuItem } from '@mui/material';

export default function LanguageSelectorWithFlags() {
    const router = useRouter();
    const [query] = useLanguageQuery();
    const { languages } = useLanguage();
    const lang = query ? query.lang : 'en';

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const langChangeHandler = (value: string) => () => {
        onPushEvent(`Language Switched to ${value}`);
        router.push(updateQueryStringParameter(router.asPath, 'lang', value));
        handleClose();
    };

    const FlagIconComponent = getIcon(lang); // Invoke the getIcon function and assign the return value to IconComponent

    return (
        <>
            <IconButton id="language-selector" onClick={handleClick}>
                <Avatar
                    variant="circular"
                    sx={{
                        backgroundColor: 'white',
                        border: '1px solid #E5E5E5',
                    }}
                >
                    <FlagIconComponent />
                </Avatar>
            </IconButton>
            <Menu id="language-selector-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                {languages.map(({ value, title, icon: Icon }) => {
                    return (
                        <MenuItem key={value} value={value} onClick={langChangeHandler(value)}>
                            <Box sx={{ display: 'flex', marginRight: '0.5rem' }}>
                                <Icon />
                            </Box>

                            {title}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
}
