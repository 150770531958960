import { IRestaurant } from '@/views/OrdersTableView/types';

export enum BillPageDescriptionModeEnum {
    TableId = 'tableId',
    TableName = 'tableName',
    PreferredTableName = 'preferredTableName',
}

export enum SplitModeTypeEnum {
    empty = '',
    byItem = 'byItem',
    byShare = 'byShare',
    custom = 'custom',
    unknown = 'unknown',
}

export enum StyleEnum {
    TitleMedium = 'titleMedium',
    TitleSmall = 'titleSmall',
    BodyMedium = 'bodyMedium',
    BodySmall = 'bodySmall',
    BodyMediumStrike = 'bodyMediumStrike',
    BodySmallStrike = 'bodySmallStrike',
}

export enum IconEnum {
    Dollar = 'dollar',
    Yen = 'yen',
    Rial = 'rial',
    Percent = 'percent',
    Bill = 'bill',
    ItemPercent = 'itemPercent',
    ItemBill = 'itemBill',
    Discount = 'discount',
    LoyaltyDiscount = 'loyaltyDiscount',
}

export enum ColorEnum {
    Primary = 'primary',
    Secondary = 'secondary',
    HighEmphasis = 'highEmphasis',
    MediumEmphasis = 'mediumEmphasis',
    LowEmphasis = 'lowEmphasis',
    Background = 'background',
    Green = 'green',
}

export enum ConditionEnum {
    Default = '',
    GT = 'gt',
    GTE = 'gte',
    LT = 'lt',
    LTE = 'lte',
    NE = 'ne',
    E = 'e',
    GTD = 'gtd',
}

export type ILayoutLocale = {
    locale: string;
    value: string;
};

export interface ILayout {
    key: string;
    style?: StyleEnum | null;
    icon?: IconEnum | null;
    color?: ColorEnum | null;
    format?: string | null;
    vcond?: ConditionEnum | null;
    hideAltValue?: boolean;
    locales?: ILayoutLocale[];
    useTitle?: boolean;
    newFormat?: boolean;
}

export enum BillStatusEnum {
    partiallyPaid = 'partiallyPaid',
    fullyPaid = 'fullyPaid',
    error = 'unpaid',
}

export enum RecordStatusEnum {
    accepted = 'accepted',
    pending = 'pending',
    failed = 'failed',
}

export interface IGetPaymentDetailPayload {
    restaurantUnique: string;
    tableID: string;
    id: string;
    diningSessionID: string;
    forceFresh: boolean;
    cc: string;
    f1?: string;
    f2?: string;
    hash?: string;
    jwt?: string;
    fetchMode?: string;
}

export interface IGetReceiptPayload {
    dsi: string;
    pri: string;
}

export interface IGetLayoutConfig {
    cc: string;
    vendor: IRestaurant | null;
}

export interface IGetPaymentDetailConfig extends IGetLayoutConfig {
    ignoreCache?: boolean;
    qsr?: boolean;
}

export interface IKeyValue {
    key: string;
    value: any;
    layout?: ILayout;
    order?: number;
    title?: string;
    titleTrans?: Record<string, string>;
    id?: string;
}

export interface IKeyValueOrder extends IKeyValue {
    qty?: string;
    altValue?: string;
    secondAltValue?: string;
    updatedAt?: number;
    selectedItemsQty?: number;
    paidItemsQty?: number;
    sig?: string;
}

export enum AdditiveCategoryEnum {
    Tax = 'tax',
    Discount = 'discount',
    Fee = 'fee',
    Sum = 'sum',
    Commision = 'com',
    Unknown = 'unknown',
}

export interface IAdditiveView {
    key: string;
    amount: string;
    value: string;
    valueType: string;
    category: AdditiveCategoryEnum;
    title: string;
}

export interface IToppingView {
    qty: string;
    title: string;
    unitPrice: string;
    updatedAt: number;
    subTotal?: string;
    finalPrice?: string;
}

export interface IOrderItemView {
    qty: string;
    title: string;
    unitPrice: string;
    updatedAt: number;
    subTotal?: string;
    finalPrice?: string;
    finalUnitPrice?: string;
    toppings?: Array<IToppingView>;
    additives?: Array<IAdditiveView>;
    sig: string;
}

export interface ISetAmountRequestPayload {
    amount: string | null;
    diningSessionID: string;
    id: string;
    name: string;
    splitMode?: string;
    share?: number;
    totalShares?: number;
    items?: IPaidItem[];
}

export interface ISetAmountV2StringRequestPayload {
    amount: string | null;
    diningSessionID: string;
    id: string;
    name: string;
    split: {
        mode?: string;
        meta: any;
        itemsToPay: IPaidItemString[];
        shareToPay: {
            share?: string;
            total?: string;
        };
    };
    checkExplodedItems?: boolean;
}

export interface IPaidItem {
    id?: string;
    index: number;
    qty: number;
    sig: string;
}

export interface IPaidItemString {
    id?: string;
    index: number;
    qty: string;
    sig: string;
}

export interface ISetAmountStringRequestPayload {
    amount: string | null;
    diningSessionID: string;
    id: string;
    name: string;
    splitMode?: string;
    share?: string;
    totalShares?: string;
    items?: IPaidItemString[];
}

export interface IPartySplit {
    items?: Array<IPaidItemString>;
    meta?: any;
    splitMode: SplitModeTypeEnum;
    share: string;
    totalShares: string;
}

export interface IParty {
    amount: string;
    id: string;
    name: string;
    paymentDone?: boolean;
    paidAt?: number;
    nonQlub?: boolean;
    loyalty?: ILoyalty;
}

interface IPaymentParty extends IParty {
    billSplit: IPartySplit;
}

export interface IPaymentPartyUI extends IParty {
    amountNumber: number;
    billSplit: IPartySplitUI;
}

export interface IPartySplitUI {
    items?: Array<IPaidItem>;
    meta?: any;
    splitMode: SplitModeTypeEnum;
    share: number;
    totalShares: number;
}

export interface IPaymentDetailsBill {
    id: string;
    qlubDiscount: string;
    paid: string;
    remaining: string;
    subTotal: string;
    tax: string;
    total: string;
    vat: string;
    yourShare: string;
    yourCommission: string;
    additives?: Array<IAdditiveView>;
    billAmount?: string;
    payableAmount?: string;
    isDinerFeeEnabled?: boolean;
    updatedAt: string;
}

export interface IPaymentDetailsBillNumber {
    qlubDiscount: number;
    paid: number;
    remaining: number;
    subTotal: number;
    tax: number;
    total: number;
    vat: number;
    yourShare: number;
    yourShareCents: number;
    yourCommission: number;
    billAmount: number;
    payableAmount: number;
    voucherAmount: number;
}

export interface IPaymentDetailsOrderItems {
    item: IKeyValueOrder;
    items: IKeyValueOrder[];
}

export interface IPaymentSplit {
    availableModes: Array<SplitModeTypeEnum>;
    mode: SplitModeTypeEnum;
    totalShares: string;
    shareAmount: string;
}

export interface IVoucher {
    code: string;
    partyID: string;
    redeemed: boolean;
    isVoucherOwner?: boolean;
    amount?: string;
}

export interface IExplodedItem {
    index: number;
    price: string;
    qty: string;
    sig: string;
}

export interface IPaymentDetailsResponse {
    bill: IPaymentDetailsBill;
    billSplit: IPaymentSplit;
    currency: string;
    currencyPrecision: number;
    orderItems?: IOrderItemView[];
    parties?: IPaymentParty[];
    restaurantUnique: string;
    tableID: string;
    diningSessionID: string;
    dsi?: string;
    tableName: string;
    jwt?: string;
    campaign: {
        appliedVouchers: IVoucher[];
    };
    explodedItems?: IExplodedItem[];
    url?: string;
}

export interface IPaymentSplitUI extends IPaymentSplit {
    totalSharesNumber: number;
    shareAmountNumber: number;
    conflict?: boolean;
}

export interface ILoyalty {
    program: string;
    amount: number;
    points: number;
    confirmation: string;
    type: string;
    status: string;
}

export interface ILoyaltyRedemptions {
    redemptions?: ILoyalty[];
}

export enum CampaignVoucherStateEnum {
    Hidden = 'hidden',
    Used = 'used',
    Visible = 'visible',
}

export interface IPromoCode {
    isPromoDisabled: boolean;
    splitByOtherNotPaid: boolean;
    preventOpenModal: boolean;
    disableSplitButton: boolean;
}

export interface IPaymentDetailsLoyalty {
    tax: number;
    paid: number;
}

export interface IExplodedItemView extends IExplodedItem {
    item?: IKeyValueOrder | IKeyValue;
    selectedItemsQty?: number;
    paidItemsQty?: number;
}

export interface IStaff {
    id: string;
    name: string;
}

export interface IPaymentDetails {
    _billItems: IKeyValue[];
    _orderItems: IPaymentDetailsOrderItems[];
    _layoutInit?: boolean;
    bill: IPaymentDetailsBill;
    billNumber: IPaymentDetailsBillNumber;
    billSplit?: IPaymentSplitUI;
    billLoyalty: IPaymentDetailsLoyalty;
    restaurantUnique: string;
    tableID: string;
    parties?: IPaymentPartyUI[];
    currency: string;
    diningSessionID: string;
    currencyPrecision: number;
    tableName?: string;
    yourSplitSettings: IPaymentPartyUI;
    otherSplitSettings: IPaymentPartyUI;
    jwt?: string;
    tablePreferredName?: string;
    campaignVoucher?: IVoucher;
    campaignVoucherVisibilityState?: CampaignVoucherStateEnum;
    promoConditions?: IPromoCode;
    explodedItems?: IExplodedItemView[];
    staff?: IStaff;
}

export interface ISetAmountV2Response {
    paymentDetails: IPaymentDetailsResponse;
    error?: { items: string };
}

export interface IRateRequestPayload {
    diningSessionID: string;
    id: string;
    rate: number;
    reference: string;
}

export interface IEmailReceiptRequestPayload {
    diningSessionID: string;
    email: string;
    id: string;
    reference: string;
    lang: string;
    countryCode: string;
}

export interface IEmailReceiptRequestNewPayload {
    countryCode: string;
    diningSessionID: string;
    email?: string;
    phone?: string;
    id: string;
    lang: string;
    reference: string;
    timezone: string;
}

export interface IEmailReceiptResponse {
    status?: string;
    meta: { como: string };
    message: string;
}

export interface IPaymentStatusPayload {
    diningSessionID: string;
    reference: string;
    id: string;
    force?: boolean;
}

export enum GatewayPaymentMethod {
    Unknown = 'unknown',
    Card = 'card',
    ApplePay = 'apple_pay',
    GooglePay = 'google_pay',
    StcPay = 'stc_pay',
    SavedCard = 'saved_card',
    Softpos = 'softpos',
    Pix = 'pix',
}

export interface IPaymentRecord {
    accepted: boolean;
    billAmount: string;
    createdAt: BigInt64Array;
    gatewayVendor: string;
    gatewayPaymentMethod?: GatewayPaymentMethod;
    reference: string;
    status: RecordStatusEnum;
    tipAmount: string;
    total: string;
}

export interface Voucher {
    transactionID?: string;
    amount: string;
    tenderID?: string;
    redemptionID?: number;
    code: string;
    extraInfo?: any;
    commissions?: any[];
    partyID: string;
    redeemed: boolean;
}

export interface IPaymentStatus {
    amount: string;
    currency: string;
    bill: {
        currency: string;
        orderID: string;
        remainingAmount: string;
        status: BillStatusEnum;
        tableID: string;
        tableName: string;
        totalAmount: string;
    };
    loyalty?: ILoyaltyRedemptions;
    campaign: {
        appliedVouchers: [
            {
                amount: string;
                code: string;
                partyID: string;
                redeemed: boolean;
            },
        ];
        vouchers: Voucher[];
    };
    record?: IPaymentRecord;
    verified: boolean;
}

export interface IPaymentStatusClient extends IPaymentStatus {
    activeCampaign?: IVoucher;
}

export interface IUpdatePaymentDetails {
    details?: IPaymentDetailsResponse;
    yourTotal: string;
}

export interface IAddPartPayload {
    country: string;
    restaurantUnique: string;
    diningSessionID: string;
    id: string;
    jwt?: string;
}

export interface IPaymentDetailsCache {
    time: number;
    details: IPaymentDetails;
    force: boolean;
}

export interface ILockPayment {
    diningSessionID: string;
    id: string;
    gatewayID: string;
}

export interface IPaymentLockResponse {
    sessions: Array<{
        reference: string;
        status: string;
        timestamp: number;
        tipAmount: string;
        billAmount: string;
        gatewayVendor: string;
    }>;
    state: 'acquired' | 'failedPartial' | 'failedFull' | 'duplicatePaidItems';
}

export interface IUnlockPayment {
    diningSessionID: string;
    id: string;
    gatewayID: string;
}

export interface IPaymentUnlockResponse {
    result: boolean;
}

export interface IVoucherValidatePayload {
    code: string | null;
    diningSessionID: string;
    id: string;
}

export interface IPreferredCurrency {
    code: string;
    forex: number;
    tempCalculatedAmountWithoutTip: number; // this will be removed with exact implementation
}

export interface IVentureReviewPayload {
    body: string;
    diningSessionID: string;
    id: string;
    rate: number;
    reference: string;
    isDraft?: boolean;
}

export interface ICalculateCommission {
    country: string;
    restaurantUnique: string;
    amount: number;
}

export interface ICalculateCommissionResponse {
    customerCommission: string;
}

export interface ICalculateCommissionV2 {
    country: string;
    restaurantUnique: string;
    dsi: string;
    id: string;
    amount: number;
}

export interface ICalculateCommissionResponseV2 {
    customerCommission: string;
}
