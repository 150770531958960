import React, { useEffect, useState, useCallback, useRef } from 'react';
import { cloneDeep, isEqual, debounce } from 'lodash';
import { useTranslation } from '@/hooks/translations';
import { Autocomplete, TextField, CircularProgress, MenuItem, ListItemText } from '@mui/material';
import RestaurantService from '@/services/restaurant';
import { IRestaurant, ITinyRestaurant } from '@/views/OrdersTableView/types';
import { onPushEvent } from '@/services/lib/gtm';

const DEBOUNCE_DELAY = 1000;

export const convertRestaurantToTiny = (val: IRestaurant | null): ITinyRestaurant | null => {
    if (!val) {
        return null;
    }
    return {
        id: val.id,
        country_code: val.country_code,
        restaurant_unique: val.restaurant_unique,
        name: val.name,
        title: val.title,
        logo_big_url: val.logo_big_url,
        logo_small_url: val.logo_small_url,
        pos_vendor_name: val.pos_vendor || '',
    };
};

interface IProps {
    value: ITinyRestaurant | null;
    onChange: (value: ITinyRestaurant | null) => void;
}

export const SelectRestaurant = ({ value, onChange }: IProps) => {
    const restaurantService = RestaurantService.getInstance();
    const { t } = useTranslation('common');
    const [selectedRestaurant, setSelectedRestaurant] = useState<ITinyRestaurant | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [choices, setChoices] = useState<ITinyRestaurant[]>([]);
    const [open, setOpen] = useState(false);
    const abortControllerRef = useRef<AbortController | null>(null);

    useEffect(() => {
        if (!isEqual(selectedRestaurant, value)) {
            setSelectedRestaurant(cloneDeep(value));
        }
    }, [value]);

    const getRestaurantList = (val: string) => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        abortControllerRef.current = new AbortController();
        const { signal } = abortControllerRef.current;

        setLoading(true);
        restaurantService
            .searchRestaurants({ search: val }, signal)
            .then((data) => {
                setChoices(data || []);
            })
            .catch((err) => {
                if (err.name === 'AbortError') {
                    console.log('Request was aborted');
                } else {
                    setError(t('Something went wrong'));
                }
            })
            .finally(() => {
                setLoading(false);
                abortControllerRef.current = null;
            });
    };

    const handleInputChange = (val: ITinyRestaurant | null) => {
        if (val) {
            setSelectedRestaurant(val);
            onChange(val);
        }
    };

    useEffect(() => {
        getRestaurantList('');
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setLoading(false);
        }
    }, [open]);

    const debounceFn = useCallback(debounce(getRestaurantList, DEBOUNCE_DELAY), []);

    const handleOnChange = (ev: any) => {
        if (ev.target.value !== '' || ev.target.value !== null) {
            debounceFn(ev.target.value);
        }
    };

    return (
        <Autocomplete
            options={choices}
            getOptionLabel={(option: ITinyRestaurant) => option.name || option.title || ''}
            id="select-restaurant-autocomplete"
            fullWidth
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onChange={(event, val) => {
                handleInputChange(val);
                if (event && event.type === 'click') {
                    onPushEvent('user_search_restaurant');
                }
            }}
            value={selectedRestaurant}
            onClose={() => {
                setOpen(false);
            }}
            loading={loading}
            filterOptions={(options) => options}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t('Vendor')}
                    variant="outlined"
                    onChange={handleOnChange}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                    helperText={error}
                />
            )}
            renderOption={(props, option, { selected }) => (
                <MenuItem {...props} selected={selected}>
                    <ListItemText primary={option.name || option.title} secondary={option.restaurant_unique} />
                </MenuItem>
            )}
        />
    );
};
