import { useRef } from 'react';
import Script from 'next/script';
import { useRestaurantContext } from '@/contexts/restaurant';

export default function ZenDeskWidget() {
    const triesRef = useRef(0);
    const { restaurant } = useRestaurantContext();

    const fixZendeskDisplay = () => {
        const zDeskButtonFrame = document.querySelector('[title="Button to launch messaging window"]');
        const zDeskWindowFrame = document.querySelector('[title="Messaging window"]');
        if (!zDeskButtonFrame || !zDeskWindowFrame) {
            return false;
        }
        const zDeskButtonStyle = zDeskButtonFrame.getAttribute('style');
        const zDeskChatStyle = zDeskWindowFrame.getAttribute('style');

        const zDeskWindowDiv = zDeskWindowFrame?.parentElement;
        const zDeskWindowDivStyle = zDeskWindowDiv?.getAttribute('style');

        if (zDeskButtonStyle) {
            zDeskButtonFrame.setAttribute('style', zDeskButtonStyle.replace('bottom: 16px', 'bottom: 4rem'));
        }
        if (zDeskChatStyle && zDeskWindowDivStyle) {
            zDeskWindowFrame.setAttribute(
                'style',
                zDeskChatStyle.replace(
                    'border: 0px',
                    'height: 95%; border: 0px; bottom: 2.5rem; background-color: rgb(255, 255, 255)',
                ),
            );
            zDeskWindowDiv?.setAttribute('style', zDeskWindowDivStyle.replace('', 'background: white;'));
        }
        return true;
    };

    const tryFixWidget = () => {
        if (!fixZendeskDisplay()) {
            triesRef.current++;
            if (triesRef.current < 10) {
                setTimeout(() => {
                    tryFixWidget();
                }, 500);
            }
        }
    };

    if (restaurant?.restaurant_country?.config?.zendeskKey) {
        return (
            <Script
                id="ze-snippet"
                src={`https://static.zdassets.com/ekr/snippet.js?key=${restaurant?.restaurant_country?.config?.zendeskKey}`}
                onLoad={() => {
                    tryFixWidget();
                }}
            />
        );
    }
    return null;
}
