import { IKeyValueOrder } from './types';

export function applyThousandSeparator(num: any, fix?: number): string {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(num)) {
        num = 0.0;
    }

    fix = fix || 0;

    if (num === null) {
        return '0';
    }

    if (!Number.isInteger(num)) {
        num = Number(num).toFixed(fix);
    } else {
        num = String(num);
    }

    return num.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1,`);
}

export function getIdFromOrder(order: IKeyValueOrder, index?: number, dontUseTime?: boolean) {
    if (dontUseTime) {
        return `${order.title}_${order.qty}_${order.value}`;
    }
    if (index !== undefined) {
        return `${order.title}_${order.qty}_${order.value}_${order.updatedAt}_${index}`;
    }
    return `${order.title}_${order.qty}_${order.value}_${order.updatedAt}`;
}

export const getDateAndTime = (dateString: string | undefined, lang?: string): string => {
    if (!dateString) {
        return '';
    }

    const isTimeIncluded = dateString.includes(':');
    const localeLang = lang === 'ja' ? 'ja-JP' : 'en-GB';
    const [datePart, timePart] = dateString.split(' ');

    const convertedDate = new Date(isTimeIncluded ? datePart : dateString).toLocaleDateString(localeLang);

    return isTimeIncluded ? `${convertedDate} ${timePart}` : convertedDate;
};
