import { cloneDeep } from 'lodash';
import { userNameKey, userRoleKey, restaurantDataKey } from '@/config/axios';
import { getDeviceType } from '@/common/utility';
import { parseJSON } from '@/components/TicketModal/utils';

declare global {
    interface Window {
        gtag: (...args: any[]) => void;
        dataLayer: Record<string, any>;
    }
}

interface GtmEventData {
    restaurant_unique: string;
    country_code: string;
    country: string;
    city: string;
    ops_mode: string;
    pos_vendor_name: string;
    brand_name: string;
    user: string;
    role: string;
    language: string;
    client_type: string;
}

export const onPushEvent = (event: string, params?: Record<string, unknown>) => {
    const restaurantData = parseJSON(localStorage.getItem(restaurantDataKey) || '{}');

    const clonedParams = cloneDeep(params);
    const clonedGtmEventData: GtmEventData = {
        restaurant_unique: restaurantData?.restaurant_unique || '',
        country_code: restaurantData?.country_code || '',
        country: restaurantData?.country || '',
        city: restaurantData?.city || '',
        ops_mode: restaurantData?.ops_mode || '',
        pos_vendor_name: restaurantData?.posAccess?.posVendor?.pos_vendor_name || '',
        brand_name: restaurantData?.brand?.name || '',
        user: localStorage.getItem(userNameKey) || '',
        role: localStorage.getItem(userRoleKey) || '',
        language: restaurantData?.restaurantCountry?.language_code || '',
        client_type: getDeviceType() || '',
        ...clonedParams,
    };
    window?.dataLayer?.push({
        event,
        clonedGtmEventData,
    });
};
