import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import type { Dispatch, SetStateAction } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'next-export-i18n';
import { getDateAndTime, getRelativeTime } from '@/services/utils/k_time';
import { getCurrencyWithAmount } from '@/common/utility';
import type { OrderPriceRoundingModeEnum } from '@/views/OrdersTableView/types';
import { QsrCardViewIndicatorEnum } from '@/views/OrdersTableView/types';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { canDisplay } from '@/views/QsrOrders/utils';
import { useRestaurantContext } from '@/contexts/restaurant';
import { type IQsrOrder, OrderStatusEnum, statusStylesType } from './types';
import styles from './index.module.scss';

interface IProps {
    setSelectedRow: Dispatch<SetStateAction<IQsrOrder | null>>;
    currencySymbol: string;
    currencyCode: string;
    priceRoundingMode?: OrderPriceRoundingModeEnum;
}

export const useColumns = ({ setSelectedRow, currencySymbol, currencyCode, priceRoundingMode }: IProps) => {
    const { t } = useTranslation('common');
    const { restaurant } = useRestaurantContext();
    const canShow = useMemo(() => canDisplay(restaurant), [restaurant]);

    const statusStyles: statusStylesType = {
        pending: {
            backgroundColor: '#FF7A00',
            color: '#fff',
        },
        approved: {
            backgroundColor: '#C7FBE5',
            color: '#1C8659',
        },
        declined: {
            backgroundColor: '#FF0000',
            color: '#fff',
        },
        cancelled: {
            backgroundColor: '#FF7A00',
            color: '#fff',
        },
        failed: {
            backgroundColor: '#FF0000',
            color: '#fff',
        },
        syncFailed: {
            backgroundColor: '#FF0000',
            color: '#fff',
        },
    };

    const getOrderStatusText = (status?: OrderStatusEnum) => {
        switch (status) {
            case OrderStatusEnum.Pending:
                return t('Pending');
            case OrderStatusEnum.Approved:
                return t('Approved');
            case OrderStatusEnum.Declined:
                return t('Declined');
            case OrderStatusEnum.Cancelled:
                return t('Cancelled');
            case OrderStatusEnum.Failed:
                return t('Failed');
            case OrderStatusEnum.Active:
                return t('Active');
            case OrderStatusEnum.PickUp:
                return t('Ready for Pick up');
            case OrderStatusEnum.SyncFailed:
                return t('Sync Failed');
            default:
                return status;
        }
    };

    const columns = [
        ...(canShow(QsrCardViewIndicatorEnum.Ticket)
            ? [
                  {
                      name: 'Ticket Id',
                      selector: (row: IQsrOrder) => row.ticketId || '-',
                      key: 'ticketId',
                      width: '8rem',
                  },
              ]
            : []),
        ...(canShow(QsrCardViewIndicatorEnum.RefId)
            ? [
                  {
                      name: 'Ref Id',
                      selector: (row: IQsrOrder) => row.refId,
                      key: 'refId',
                  },
              ]
            : []),
        {
            name: 'Total Price',
            selector: (row: IQsrOrder) =>
                getCurrencyWithAmount(row.total, currencySymbol, currencyCode, true, priceRoundingMode),
            key: 'total',
            center: true,
        },
        {
            name: 'Time',
            cell: (row: IQsrOrder) => (
                <Tooltip title={getDateAndTime(row.paidAt || row.createdAt)}>
                    <span>{getRelativeTime(row.paidAt || row.createdAt)}</span>
                </Tooltip>
            ),
            center: true,
            key: 'date',
        },
        {
            name: 'Details',
            width: '10rem',
            cell: (row: any) => {
                const hasComment = Boolean(
                    row.customerComment ||
                        row?.orderData?.items?.filter((item: { note: string }) => item.note).length > 0,
                );
                return (
                    <Box className={styles.buttonContainer}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setSelectedRow(row);
                            }}
                        >
                            {t('Details')}
                        </Button>
                        {hasComment && <NotificationsIcon className={styles.chatIcon} color="success" />}
                    </Box>
                );
            },
            center: true,
            key: 'details',
        },
        {
            name: 'Order Status',
            cell: (row: IQsrOrder) => {
                return (
                    <Chip
                        label={getOrderStatusText(row.orderStatus?.name)}
                        sx={{
                            ...statusStyles[row.orderStatus?.name || 'pending'],
                            borderRadius: '0.2rem',
                            width: '6rem',
                        }}
                    />
                );
            },
            center: true,
            key: 'orderStatus',
        },
    ];

    return columns;
};
