/*
    Creation Time: 2021 - Sep - 26
    Created by:  (hamidrezakk)
    Maintainers:
       1.  HamidrezaKK (hamidrezakks@gmail.com)
    Auditor: HamidrezaKK
    Copyright Qlub_ 2022
*/

import createCache from '@emotion/cache';

export default function createEmotionCache() {
    return createCache({ key: 'css' });
}
