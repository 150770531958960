import {
    startOfYesterday,
    endOfDay,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfQuarter,
    endOfToday,
    formatISO,
    startOfToday,
} from 'date-fns';
import { useFormikContext } from 'formik';

import { TimeRangeTypes } from '../types';

export const useActions = ({ toClick }: { toClick: () => void }) => {
    const { setFieldValue } = useFormikContext();

    const timeRangeChangeHandler = (type: TimeRangeTypes) => {
        const date = new Date();

        let fromChange: Date | undefined;
        let toChange: Date | undefined;

        switch (type) {
            case TimeRangeTypes.TODAY:
                fromChange = startOfDay(date);
                toChange = undefined;
                break;
            case TimeRangeTypes.ALL:
                fromChange = undefined;
                toChange = undefined;
                break;
            case TimeRangeTypes.YESTERDAY:
                fromChange = startOfYesterday();
                toChange = undefined;
                break;
            case TimeRangeTypes.WEEK:
                fromChange = startOfWeek(date, { weekStartsOn: 1 });
                toChange = undefined;
                break;
            case TimeRangeTypes.LAST_7_DAYS:
                fromChange = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7);
                toChange = undefined;
                break;
            case TimeRangeTypes.MONTH:
                fromChange = startOfMonth(date);
                toChange = undefined;
                break;
            case TimeRangeTypes.LAST_MONTH:
                fromChange = startOfMonth(new Date(date.getFullYear(), date.getMonth() - 1, date.getDate()));
                toChange = undefined;
                break;
            case TimeRangeTypes.LAST_30_DAYS:
                fromChange = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30);
                toChange = undefined;
                break;
            case TimeRangeTypes.QUARTER:
                fromChange = startOfQuarter(date);
                toChange = undefined;
                break;
            case TimeRangeTypes.CUSTOM:
                fromChange = startOfToday();
                toChange = endOfToday();
                break;
            default:
                break;
        }

        setFieldValue('from', fromChange ? formatISO(fromChange) : null);
        setFieldValue('to', toChange ? formatISO(toChange) : null);
    };

    const customChangeHandler = (name: string) => (rangeDate: Date | null) => {
        let toBeFormatDate = rangeDate || endOfDay(new Date());
        if (name === 'from') {
            toClick();
            toBeFormatDate = rangeDate || startOfDay(new Date());
        }
        setFieldValue(name, formatISO(toBeFormatDate));
    };

    return { customChangeHandler, timeRangeChangeHandler };
};
