import {
    ToggleButton,
    ToggleButtonGroup as MuiToggleButtonGroup,
    Tooltip,
    toggleButtonGroupClasses,
} from '@mui/material';

interface ToggleButtonGroupProps<T extends string> {
    options: {
        disabled?: boolean;
        tooltip: string;
        value: T;
        text: string;
    }[];
    size?: 'small' | 'medium';
    value: T;
    disabled?: boolean;
    onChange: (value: T) => void;
}

function ToggleButtonGroup<T extends string>({ onChange, options, size, value, disabled }: ToggleButtonGroupProps<T>) {
    return (
        <MuiToggleButtonGroup
            exclusive
            onChange={(e, val) => {
                if (val !== null) onChange(val);
            }}
            size={size}
            sx={{
                width: '100%',
                height: 40,
                borderWidth: 1,
                borderColor: '#e6e8f0',
                borderStyle: 'solid',

                backgroundColor: '#fff',
                [`& .${toggleButtonGroupClasses.grouped}`]: {
                    margin: 0.5,
                    border: 0,
                    '&:not(:first-of-type)': {
                        borderRadius: 1,
                    },
                    '&:first-of-type': {
                        borderRadius: 1,
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#7d00d414',
                        color: '#0000008a',
                        fontWeight: 600,
                        ':hover': {
                            backgroundColor: '#7d00d414',
                            color: '#0000008a',
                        },
                    },
                },
            }}
            value={value}
            disabled={disabled}
        >
            {options.map((option) => (
                <ToggleButton
                    disabled={disabled || option.disabled}
                    key={option.value}
                    value={option.value}
                    sx={{ width: '50%' }}
                >
                    <Tooltip title={option.tooltip}>
                        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            {option.text}
                        </div>
                    </Tooltip>
                </ToggleButton>
            ))}
        </MuiToggleButtonGroup>
    );
}

export default ToggleButtonGroup;
