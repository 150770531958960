import { IconButton, SxProps } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { useNavbarContext } from '@/contexts/navbar';

interface Props {
    onClick?: () => void;
}

const IconButtonProps: SxProps = {
    color: '#616475',
    marginLeft: '1rem',
    '&:hover': {
        color: 'primary.main',
    },
};

export default function SettingsButton({ onClick }: Props) {
    const { isSettingsOpen, setIsSettingsOpen } = useNavbarContext();

    return (
        <IconButton
            sx={IconButtonProps}
            onClick={() => {
                setIsSettingsOpen(!isSettingsOpen);
                onClick?.();
            }}
        >
            <TuneIcon />
        </IconButton>
    );
}
