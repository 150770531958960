export const transformError = (err: any) => {
    const message = err?.response?.data?.message || null;
    if (!message) {
        throw Error(err.message);
    }
    if (Array.isArray(message)) {
        throw Error(message.join('\n'));
    }
    throw Error(message);
};
