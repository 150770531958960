import { Box as MBox, SxProps } from '@mui/material';
import { merge } from 'merge';
import useDividerStyle from './styles';

export interface IProps {
    type?: 'small' | 'medium' | 'large' | 'fullWidth';
    sx?: SxProps;
    text?: string;
}

const Divider = ({ type, sx, text }: IProps) => {
    const { dividerStyle } = useDividerStyle({ type });

    return <MBox sx={merge(dividerStyle, sx)}>{text && <p>{text}</p>}</MBox>;
};

export default Divider;
