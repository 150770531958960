import { Container, ContainerProps, Grid, type GridProps } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { merge } from 'merge';

import { ISize, useContainerStyle } from './styles';

export interface IContainerProps extends ContainerProps {
    padding?: ISize | string;
    margin?: ISize | string;
    children: React.ReactNode;
    centered?: boolean;
    direction?: 'row' | 'column';
    gap?: ISize | string;
}
export const CustomerContainer: FC<IContainerProps> = ({
    children,
    centered,
    direction,
    gap,
    margin,
    padding,
    ...props
}) => {
    const { mainContainerStyles } = useContainerStyle({ centered, direction, gap, margin, padding, children });
    return (
        <Container
            disableGutters
            {...props}
            maxWidth={props.maxWidth ? props.maxWidth : false}
            sx={merge(true, mainContainerStyles, props.sx)}
        >
            {children}
        </Container>
    );
};

export const CustomerGrid = ({ children, ...props }: GridProps & { children: ReactNode }) => {
    return (
        <Grid container {...props}>
            {children}
        </Grid>
    );
};
