import { endOfToday, formatISO, startOfToday } from 'date-fns';
import { TimeRangeTypes } from '@/components/TimeRange/Form/types';
import { FileFormats } from './types';

export const commonExportInitialValues = {
    fileFormat: FileFormats.CSV,
    type: TimeRangeTypes.TODAY,
    from: formatISO(startOfToday()),
    to: formatISO(endOfToday()),
};
