const EventBus = {
    on(event: string, callback: (() => void) | ((a: { detail: any }) => void)) {
        if (typeof window === 'object') {
            document.addEventListener(event, callback as any);
        }
    },
    dispatch(event: string, data?: any) {
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    remove(event: string, callback?: any) {
        document.removeEventListener(event, callback);
    },
};

export default EventBus;
