import { useEffect, useState } from 'react';
import MultipleSelectChips from '@/components/MultipleSelectChips';
import { ITableParams } from '@/views/OrdersTableView/types';
import CommonModal from '@/components/CommonModal';

interface IProps {
    options: ITableParams[];
    selectedItems: ITableParams[];
    onClose: () => void;
    onChange: (items: ITableParams[]) => void;
}

export default function SelectTables({ options, onClose, selectedItems, onChange }: IProps) {
    const [values, setValues] = useState<ITableParams[]>([]);

    useEffect(() => {
        setValues(selectedItems);
    }, [selectedItems]);

    const changeHandler = (val: ITableParams[]) => {
        setValues(val);
    };

    const confirmHandler = () => {
        onChange(values);
        onClose();
    };

    return (
        <CommonModal onClose={onClose} onConfirm={confirmHandler}>
            <MultipleSelectChips values={values} onChange={changeHandler} options={options} />
        </CommonModal>
    );
}
