import axios from '@/config/axios';
import { transformError } from '.';
import { RestaurantReportPreferences, BrandReportPreferences } from './utils/schedulerReport_helper';

class ScheduledReportsService {
    public static getInstance() {
        if (!this.instance) {
            this.instance = new ScheduledReportsService();
        }

        return this.instance;
    }

    private static instance: ScheduledReportsService;

    public getRestaurantReports = async (requestData: any) => {
        try {
            const res = await axios.get<any>(`/cronicle/restaurant/${requestData.restaurant_id}`, requestData);
            return res.data;
        } catch (err) {
            throw transformError(err);
        }
    };

    public createRestaurantReport = async (
        data: (RestaurantReportPreferences | BrandReportPreferences) & { timezoneId: string },
        restaurantId: string,
    ) => {
        try {
            const res = await axios.post<any>(`/cronicle/restaurant/${restaurantId}/create`, data);
            return res.data;
        } catch (err) {
            throw transformError(err);
        }
    };

    public updateRestaurantReport = async (
        data: (RestaurantReportPreferences | BrandReportPreferences) & { timezoneId: string },
        restaurantId: string,
        reportId: string,
    ) => {
        try {
            const res = await axios.put<any>(`/cronicle/restaurant/${restaurantId}/${reportId}`, data);
            return res.data;
        } catch (err) {
            throw transformError(err);
        }
    };

    public deleteRestaurantReport = async (restaurantId: string, reportId: string) => {
        try {
            const res = await axios.delete<any>(`/cronicle/restaurant/${restaurantId}/report/${reportId}`);
            return res.data;
        } catch (err) {
            throw transformError(err);
        }
    };

    public getBrandReports = async (requestData: any) => {
        try {
            const res = await axios.get<any>(`/cronicle/brand/${requestData.brandId}`, requestData);
            return res.data;
        } catch (err) {
            throw transformError(err);
        }
    };

    public createBrandReport = async (
        data: (RestaurantReportPreferences | BrandReportPreferences) & { timezoneId: string },
        brandId: string,
    ) => {
        try {
            const res = await axios.post<any>(`/cronicle/brand/${brandId}/create`, data);
            return res.data;
        } catch (err) {
            throw transformError(err);
        }
    };

    public updateBrandReport = async (
        data: (RestaurantReportPreferences | BrandReportPreferences) & { timezoneId: string },
        brandId: string,
        reportId: string,
    ) => {
        try {
            const res = await axios.put<any>(`/cronicle/brand/${brandId}/${reportId}`, data);
            return res.data;
        } catch (err) {
            throw transformError(err);
        }
    };

    public deleteBrandReport = async (brandId: string, reportId: string) => {
        try {
            const res = await axios.delete<any>(`/cronicle/brand/${brandId}/report/${reportId}`);
            return res.data;
        } catch (err) {
            throw transformError(err);
        }
    };

    public getReportHistory = async (baseName: string, id: string, requestData: { page: number; limit: number }) => {
        try {
            const res = await axios.get<any>(`vendor/scheduled-reports/${baseName}/${id}/history`, {
                params: requestData,
            });
            return res.data;
        } catch (err) {
            throw transformError(err);
        }
    };

    public getReportHistoryStatus = async (baseName: string, id: string, reportIds: string[]) => {
        try {
            const res = await axios.get<any>(
                `vendor/scheduled-reports/${baseName}/${id}/status?reportIds=${reportIds}`,
            );
            return res.data;
        } catch (err) {
            throw transformError(err);
        }
    };
}

export default ScheduledReportsService;
