import { customerAppUrl } from '@/config/axios';
import { clone } from 'lodash';
import { IVendorGetOrderReq, IVendorGetOrderRes } from '@/services/iframeBridge/type';

const client = 'vendor_panel';

export const IframeCmdEnum = {
    Bool: 'bool',
    Close: 'close',
    Error: 'error',
    Loaded: 'loaded',
};

interface IMessageListener {
    cmd: string;
    reject: any;
    resolve: any;
    timeout?: any;
}

interface IMessage {
    client: string;
    cmd: string;
    data: any;
    mode: 'req' | 'res';
    reqId: number;
}

interface IResMessage {
    cmd: string;
    data: any;
    reqId: number;
}

interface IReq {
    data: any;
}

interface IRes {
    data: any;
}

class IframeBridge {
    public static getInstance(iframe: HTMLIFrameElement) {
        if (!this.instance) {
            this.instance = new IframeBridge(iframe);
        }

        return this.instance;
    }

    private static instance: IframeBridge;

    private iframe: HTMLIFrameElement | null = null;

    private fnQueue: any = {};

    private fnIndex = 0;

    private reqId = 0;

    private messageListeners: { [key: number]: IMessageListener } = {};

    private loadFn: any[] = [];

    constructor(iframe: HTMLIFrameElement) {
        this.iframe = iframe;
        this.init();
        this.api('/init/load', () => {
            this.loadFn.forEach((fn) => {
                fn?.();
            });
            return Promise.resolve({
                data: {
                    loaded: true,
                },
            });
        });
    }

    private init() {
        window.addEventListener('message', (e) => {
            if (!e.data) {
                return;
            }

            try {
                const data: IMessage = JSON.parse(e.data);
                if (data.mode === 'res') {
                    this.response(data);
                } else if (data.mode === 'req') {
                    this.callHandlers(data.cmd, data);
                }
            } catch (err) {
                // window.console.warn(err);
            }
        });
    }

    public onLoad = (fn: any) => {
        this.loadFn.push(fn);
    };

    /* API handler */
    public api(path: string, fn: (e: IReq) => Promise<IRes>) {
        if (!path) {
            return null;
        }

        return this.listen(path, (payload) => {
            fn({
                data: payload.data,
            })
                .then((res) => {
                    this.sendResponse({
                        cmd: path,
                        data: res,
                        reqId: payload.reqId,
                    });
                })
                .catch((err) => {
                    this.sendResponse({
                        cmd: IframeCmdEnum.Error,
                        data: err,
                        reqId: payload.reqId,
                    });
                });
        });
    }

    /* Listen to event */
    public listen(subject: string, fn: (e: IMessage) => void): (() => void) | null {
        if (!subject) {
            return null;
        }

        this.fnIndex++;
        const idx = this.fnIndex;
        if (!this.fnQueue.hasOwnProperty(subject)) {
            this.fnQueue[subject] = {};
        }
        this.fnQueue[subject][idx] = fn;
        return () => {
            delete this.fnQueue[subject][idx];
        };
    }

    public loadOrder(data: IVendorGetOrderReq, retry?: number): Promise<IVendorGetOrderRes> {
        return this.send('/order/load', data).catch((err) => {
            retry = retry || 1;
            if (retry < 3) {
                return this.loadOrder(data, retry + 1);
            }
            return Promise.reject(err);
        });
    }

    /* Call queue handler */
    private callHandlers(subject: string, payload: IMessage) {
        if (!this.fnQueue.hasOwnProperty(subject)) {
            return;
        }

        Object.values(clone(this.fnQueue[subject])).forEach((fn: any) => {
            fn?.(payload);
        });
    }

    private sendResponse(data: IResMessage) {
        this.iframe?.contentWindow?.postMessage(
            JSON.stringify({
                client,
                cmd: data.cmd,
                data: data.data,
                mode: 'res',
                reqId: data.reqId,
            }),
            customerAppUrl,
        );
    }

    // @ts-ignore
    private send(cmd: string, data: any) {
        let internalResolve = null;
        let internalReject = null;

        const reqId = ++this.reqId;

        const promise = new Promise<any>((res, rej) => {
            internalResolve = res;
            internalReject = rej;
        });

        this.messageListeners[reqId] = {
            cmd,
            reject: internalReject,
            resolve: internalResolve,
        };

        this.iframe?.contentWindow?.postMessage(
            JSON.stringify({
                client,
                cmd,
                data,
                mode: 'req',
                reqId,
            }),
            customerAppUrl,
        );

        this.messageListeners[reqId].timeout = setTimeout(() => {
            this.dispatchTimeout(reqId);
        }, 10000);

        return promise;
    }

    private response(data: IMessage) {
        if (!this.messageListeners.hasOwnProperty(data.reqId)) {
            return false;
        }
        if (data.cmd === IframeCmdEnum.Error) {
            this.messageListeners[data.reqId].reject(data.data);
        } else {
            this.messageListeners[data.reqId].resolve(data.data);
        }
        if (this.messageListeners[data.reqId].timeout) {
            clearTimeout(this.messageListeners[data.reqId].timeout);
        }
        delete this.messageListeners[data.reqId];
        return true;
    }

    private dispatchTimeout(reqId: number) {
        const item = this.messageListeners[reqId];
        item?.reject?.({
            err: 'timeout',
            reqId,
        });
    }
}

export default IframeBridge;
