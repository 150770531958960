import { IRestaurant, QsrCardViewIndicatorEnum } from '@/views/OrdersTableView/types';

export const canDisplay = (restaurant: IRestaurant | undefined | null) => {
    return (item: QsrCardViewIndicatorEnum): boolean => {
        return (
            !restaurant?.order_config?.vendorQsrCardViewHiddenIndicators?.length ||
            !restaurant?.order_config?.vendorQsrCardViewHiddenIndicators?.includes(item)
        );
    };
};
