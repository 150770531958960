import { useTranslation } from '@/hooks/translations';
import type { IRestaurantCountryConfig, IRestaurant } from '@/views/OrdersTableView/types';
import type { ITableData } from '@/views/Payments';

import { BillPageDescriptionModeEnum, IPaymentDetails } from '../../types';

const getTableTitle = (vendor: IRestaurant | null, tableName: string | undefined, id: string) => {
    const getText = () => {
        if (vendor) {
            if (vendor.config?.hideTableName && vendor.config?.hideTableId) {
                return '';
            }
            if (vendor.config?.hideTableName !== true && vendor.config?.hideTableId !== true && tableName) {
                return `${id}${tableName !== '' ? ` (${tableName})` : ''}`;
            }
            if (vendor.config?.hideTableId === true && tableName) {
                return tableName;
            }
            if (!vendor.config.previewBill && vendor.config?.hideTableName !== true && vendor.config?.hideTableId) {
                return '';
            }
            return id;
        }
        return undefined;
    };
    const text = getText();
    if (text?.startsWith('-')) {
        return {
            pager: true,
            text: text?.substring(1) || '',
        };
    }
    return {
        pager: false,
        text: text || '',
    };
};

interface ITableNameProps {
    config?: IRestaurantCountryConfig;
    vendor: IRestaurant | null;
    table?: ITableData;
}

const NewTableName = ({ vendor, config, table }: ITableNameProps) => {
    const { t } = useTranslation('common');
    switch (vendor?.config.billPageDescriptionMode || config?.billPageDescriptionMode) {
        case BillPageDescriptionModeEnum.PreferredTableName:
            // FIXME: use preferred table name instead of name
            return t(`Table: {{name}}`, { name: table?.name || table?.id || '' });
        // return t(`Table: {{name}}`, { name: table?.preferredName || table?.id || '' });
        case BillPageDescriptionModeEnum.TableId:
            return t(`Table: {{name}}`, { name: table?.id || '' });
        case BillPageDescriptionModeEnum.TableName:
            return t(`Table: {{name}}`, { name: table?.name || table?.id || '' });
        default:
            return null;
    }
};

interface IProps {
    config?: IRestaurantCountryConfig;
    vendor: IRestaurant | null;
    details?: IPaymentDetails;
    placeholder?: any;
    table?: ITableData;
}

export const TableName = ({ config, vendor, details, table, placeholder }: IProps) => {
    const { t } = useTranslation('common');
    if (vendor?.config.billPageDescriptionMode || config?.billPageDescriptionMode) {
        return <NewTableName config={config} vendor={vendor} table={table} />;
    }
    const { pager, text } = getTableTitle(
        vendor,
        table?.name || details?.tableName || '',
        table?.id || details?.tableID || placeholder || '',
    );
    if (text) {
        if (text === '0' && vendor?.order_config?.pagerEnable) {
            if (!vendor?.order_config?.enablePagerAssignMessage) {
                return null;
            }
            return t('Pager will be assigned after the submit');
        }
        return `${pager ? t('Pager') : t('Table')} ${text}`;
    }
    return placeholder || null;
};

export default TableName;
