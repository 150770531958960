import { getTotalByKey, covertUtcToRestaurantDateTime } from '@/common/utility';
import { FC } from 'react';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { getCurrencyPrecision } from '@/services/utils/k_currency';
import { IPaymentRecord, PaymentRecordType } from '@/views/Payments';
import { IPaymentTransaction } from '@/views/Orders/types';
import { IRestaurant } from '@/views/OrdersTableView/types';
import classNames from 'classnames';

import styles from './index.module.scss';

interface IProps {
    payment: IPaymentTransaction;
    restaurant: IRestaurant | null;
    currencySymbol: string;
    currencyCode: string;
    showTip: boolean;
    innerRef?: any;
    t?: any;
}

const PrintableReceipt: FC<IProps> = ({ payment, restaurant, currencySymbol, currencyCode, innerRef, showTip, t }) => {
    const getTypeName = (type?: PaymentRecordType) => {
        switch (type) {
            default:
            case PaymentRecordType.User:
            case PaymentRecordType.NonQlubUser:
                return t('Bill Payment');
            case PaymentRecordType.Discount:
                return t('Discount Payment');
            case PaymentRecordType.Voucher:
                return t('Voucher Payment');
            case PaymentRecordType.Refund:
                return t('Refund Payment');
            case PaymentRecordType.Loyalty:
                return t('Loyalty Payment');
        }
    };
    return (
        <div className={styles.container} ref={innerRef}>
            <div id={`${payment.restaurant_unique}-${payment.id}-print-receipt`} className={styles.paper}>
                <div className={styles.header}>
                    <div>{t('Payment Receipt')}</div>
                    <div>
                        <img src="/logo.png" alt="Qlub logo" height="20" />
                    </div>
                </div>
                <Table className={styles.table}>
                    <TableBody>
                        <TableRow>
                            <TableCell className={styles.label}>{t('Restaurant Name')}</TableCell>
                            <TableCell className={styles.value}>{restaurant?.name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}>{t('Restaurant Address')}</TableCell>
                            <TableCell className={styles.value}>{restaurant?.address1}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}>{t('Order ID')}</TableCell>
                            <TableCell className={classNames(styles.value, styles.wordBreak)}>
                                {payment.order_id}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}>{t('Date')}</TableCell>
                            <TableCell className={styles.value}>
                                {covertUtcToRestaurantDateTime(payment.created_at, 'MM / DD / YYYY HH : mm a')}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Table className={styles.table}>
                    <TableBody>
                        <TableRow
                            style={{
                                height: '30px',
                            }}
                        >
                            <TableCell className={styles.heading} colSpan={4}>
                                {t('Order Details')}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label} colSpan={2}>
                                {t('Item')}
                            </TableCell>
                            <TableCell className={styles.value}>{t('QTY')}</TableCell>
                            <TableCell className={styles.value}>{t('Price')}</TableCell>
                        </TableRow>
                        {payment.order_data && payment.order_data.items
                            ? payment?.order_data.items.map((i, idx) => (
                                  <TableRow key={`${i.title}_${idx}`}>
                                      <TableCell className={styles.label} colSpan={2}>
                                          {i.title}
                                      </TableCell>
                                      <TableCell className={styles.value}>{i.qty}</TableCell>
                                      <TableCell className={styles.value}>
                                          {`${currencySymbol} `}
                                          {parseFloat(i.unitPrice || '0').toFixed(getCurrencyPrecision(currencyCode))}
                                      </TableCell>
                                  </TableRow>
                              ))
                            : null}
                        <TableRow>
                            <TableCell colSpan={4}>&nbsp;</TableCell>
                        </TableRow>
                        <TableRow
                            style={{
                                height: '30px',
                            }}
                        >
                            <TableCell className={styles.heading} colSpan={4}>
                                {t('Payment Details')}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}>{t('Time')}</TableCell>
                            <TableCell className={styles.value}>{t('Status')}</TableCell>
                            {showTip ? <TableCell className={styles.value}>{t('Tips')}</TableCell> : <TableCell />}
                            <TableCell className={styles.value}>{t('Total')}</TableCell>
                        </TableRow>
                        {payment?.paymentRecord &&
                            payment?.paymentRecord?.map((r, idx) => {
                                console.log('r', r);

                                if (r?.status !== 'accepted') return null;
                                return (
                                    <TableRow key={idx}>
                                        <TableCell className={classNames(styles.label, styles.noWrap)}>
                                            {covertUtcToRestaurantDateTime(r.created_at, 'HH : mm')}
                                        </TableCell>
                                        <TableCell className={classNames(styles.value, styles.wrap)}>
                                            {getTypeName(r.type)}
                                        </TableCell>
                                        {showTip ? (
                                            <TableCell className={styles.value}>
                                                {`${currencySymbol} `}
                                                {parseFloat(r.tip_amount || '0').toFixed(
                                                    getCurrencyPrecision(currencyCode),
                                                )}
                                            </TableCell>
                                        ) : (
                                            <TableCell />
                                        )}
                                        <TableCell className={styles.value}>
                                            {`${currencySymbol} `}
                                            {parseFloat(r.paid_amount || '0').toFixed(
                                                getCurrencyPrecision(currencyCode),
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        <TableRow>
                            <TableCell colSpan={4}>&nbsp;</TableCell>
                        </TableRow>
                        <TableRow
                            style={{
                                height: '30px',
                            }}
                        >
                            <TableCell />
                            <TableCell className={styles.heading} colSpan={3} align="center">
                                {t('Payment Summary')}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>&nbsp;</TableCell>
                            <TableCell className={styles.value} colSpan={2} align="left">
                                {t('Bill Amount')}
                            </TableCell>
                            <TableCell className={styles.value}>
                                {`${currencySymbol} `}
                                {parseFloat(payment.order_data.amount).toFixed(getCurrencyPrecision(currencyCode))}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>&nbsp;</TableCell>
                            <TableCell className={styles.value} colSpan={2} align="left">
                                {t('Paid by qlub_')}
                            </TableCell>
                            <TableCell className={styles.value}>
                                {`${currencySymbol} `}
                                {getTotalByKey(
                                    payment.paymentRecord ? payment.paymentRecord : [],
                                    'paid_amount',
                                    (pr: IPaymentRecord) => pr.status === 'accepted',
                                ).toFixed(getCurrencyPrecision(currencyCode))}
                            </TableCell>
                        </TableRow>
                        {showTip && (
                            <TableRow>
                                <TableCell>&nbsp;</TableCell>
                                <TableCell className={styles.value} colSpan={2} align="left">
                                    {t('Tip Payment')}
                                </TableCell>
                                <TableCell className={styles.value}>
                                    {`${currencySymbol} `}
                                    {getTotalByKey(
                                        payment.paymentRecord ? payment.paymentRecord : [],
                                        'tip_amount',
                                        (pr: IPaymentRecord) => pr.status === 'accepted',
                                    ).toFixed(getCurrencyPrecision(currencyCode))}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};

export default PrintableReceipt;
