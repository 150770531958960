import { Divider, Grid, Typography } from '@mui/material';

const Header = ({ title }: { title: string }) => {
    return (
        <Grid item xs={12}>
            <Typography variant="h6" sx={{ fontWeight: 400 }}>
                {title}
            </Typography>
            <Divider sx={{ marginBlockStart: 1, marginBlockEnd: 2 }} />
        </Grid>
    );
};

export default Header;
