import React, { Fragment } from 'react';
import type { IRestaurant } from '@/views/OrdersTableView/types';

import { useBillItemTranslation, useOrderItemTranslation } from './hooks/translations_hook';
import InvoiceItem from './InvoiceItem';
import { checkVisibilityCondition } from './utils';
import type { IPaymentDetails } from '../../types';
import { getIdFromOrder } from '../../utils';

export interface IInvoiceBillProps {
    vendor: IRestaurant | null;
    details: IPaymentDetails;
    lang: string | null;
}

export const InvoiceBill = ({ details, vendor, lang }: IInvoiceBillProps) => (
    <>
        {details._billItems.map((item, i) => {
            if (!checkVisibilityCondition(item, String(details.billNumber.qlubDiscount))) {
                return null;
            }
            return (
                // in case we wanna add any kind of condistions or conditional props
                // on invoiceItem level we can use this hight order component
                <InvoiceItem
                    key={`${item.key}_${item.value}_${i}`}
                    val={item}
                    currencyCode={vendor?.restaurant_country?.currency_code || ''}
                    currencySymbol={vendor?.restaurant_country?.currency_symbol || ''}
                    currencyPrecision={details.currencyPrecision || 0}
                    lang={lang}
                    useBillItemTranslation={useBillItemTranslation}
                    useOrderItemTranslation={useOrderItemTranslation}
                />
            );
        })}
    </>
);

interface IInvoiceOrderProps {
    vendor: IRestaurant | null;
    details: IPaymentDetails;
    lang: string | null;
}

export const InvoiceOrder = ({ details, vendor, lang }: IInvoiceOrderProps) => (
    <>
        {details._orderItems.map((item, i) => {
            if (!checkVisibilityCondition(item.item)) {
                return null;
            }

            const orderId = getIdFromOrder(item.item, undefined, true);

            return (
                <Fragment key={`${orderId}_${i}`}>
                    <InvoiceItem
                        val={item.item}
                        currencyCode={vendor?.restaurant_country?.currency_code || ''}
                        currencySymbol={vendor?.restaurant_country?.currency_symbol || ''}
                        currencyPrecision={details.currencyPrecision || 0}
                        lang={lang}
                        type="order"
                        useBillItemTranslation={useBillItemTranslation}
                        useOrderItemTranslation={useOrderItemTranslation}
                    />
                    {item.items.map((innerItem, j) => {
                        if (!checkVisibilityCondition(innerItem)) {
                            return null;
                        }

                        return (
                            <InvoiceItem
                                key={`${orderId}_${i}_${j}`}
                                val={innerItem}
                                currencyCode={vendor?.restaurant_country?.currency_code || ''}
                                currencySymbol={vendor?.restaurant_country?.currency_symbol || ''}
                                currencyPrecision={details.currencyPrecision || 0}
                                lang={lang}
                                type="topping"
                                useBillItemTranslation={useBillItemTranslation}
                                useOrderItemTranslation={useOrderItemTranslation}
                            />
                        );
                    })}
                </Fragment>
            );
        })}
    </>
);
