import { useEffect, useState } from 'react';
import { Field, FormikProvider, FormikValues, useFormik, type FieldProps } from 'formik';
import * as Yup from 'yup';
import { ButtonProps } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import CloseOutlined from '@mui/icons-material/CloseOutlined';
import IosShareIcon from '@mui/icons-material/IosShare';
import OpenInNewRounded from '@mui/icons-material/OpenInNewRounded';

import { onPushEvent } from '@/services/lib/gtm';
import { useTranslation } from '@/hooks/translations';
import TimeRangeForm from '@/components/TimeRange/Form';
import { CustomButton, PrimaryButton } from '@/components/common';

import { FileFormats, type Props } from './types';
import { TimeRangeTypes } from '../TimeRange/Form/types';

export default function ExportModal<T extends FormikValues>({
    buttonType,
    children,
    extraValidations,
    initialValues,
    onConfirm,
    reportTypes,
    title,
    ...rest
}: Props<T>) {
    const { t } = useTranslation('common');
    const [open, setOpen] = useState<boolean>(false);

    let buttonProps: ButtonProps = {
        endIcon: <OpenInNewRounded />,
        size: 'large',
        sx: { color: 'primary.main' },
    };

    if (buttonType === 'new') {
        buttonProps = {
            endIcon: undefined,
            size: undefined,
            startIcon: <IosShareIcon />,
            sx: {
                color: 'primary.main',
                borderRadius: 32,
                padding: '10px 24px 10px 16px;',
            },
        };
    }

    const formik = useFormik<T>({
        initialValues,
        validationSchema: Yup.object({
            fileFormat: Yup.string()
                .oneOf(Object.values(FileFormats), t('Please select one of the given values'))
                .required(t('File type is required')),
            // @ts-ignore
            type: Yup.string()
                .oneOf(Object.values(TimeRangeTypes), t('Please select one of the given values'))
                .required(t('Time range is required')),
            from: Yup.string().nullable(),
            to: Yup.string().nullable(),
            ...extraValidations,
        }),
        onSubmit: (values, { resetForm, setSubmitting }) => {
            onPushEvent('user_use_export_function');
            onConfirm(values).finally(() => {
                setSubmitting(false);
                setOpen(false);
                resetForm();
            });
        },
    });

    const { handleSubmit, handleReset, isSubmitting, resetForm, values } = formik;
    const { fileFormat, reportType, from, to, type } = values;

    const timeRange = { from, to, type };

    useEffect(() => {
        onPushEvent(`user_choose_${fileFormat}_view_${timeRange}${reportType ? `_${reportType}` : ''}`);
    }, [fileFormat, type, from, to, reportType]);

    const onClose = () => {
        resetForm();
        setOpen(false);
    };

    return (
        <>
            <CustomButton {...buttonProps} onClick={() => setOpen(true)} fullWidth>
                {t('Export')}
            </CustomButton>
            <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
                <DialogTitle
                    sx={{
                        position: 'sticky',
                        top: '0px',
                        backgroundColor: '#fff',
                        zIndex: 100,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBlockEnd: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    {title}
                    <IconButton onClick={onClose}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
                <FormikProvider value={formik}>
                    <form onSubmit={handleSubmit} onReset={handleReset}>
                        <DialogContent sx={{ paddingTop: '20px !important' }}>
                            <Grid container spacing={2}>
                                {reportTypes && (
                                    <Grid item xs={12}>
                                        <FormControl>
                                            <FormLabel>{t('Report Type')}</FormLabel>
                                            <Field name="reportType">
                                                {({ field }: FieldProps) => (
                                                    <RadioGroup {...field} row sx={{ display: 'flex' }}>
                                                        {reportTypes.map((rType) => (
                                                            <FormControlLabel {...rType} control={<Radio />} />
                                                        ))}
                                                    </RadioGroup>
                                                )}
                                            </Field>
                                        </FormControl>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel>{t('Date Range')}</FormLabel>
                                        <TimeRangeForm {...rest} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel>{t('File Type')}</FormLabel>
                                        <Field name="fileFormat">
                                            {({ field }: FieldProps) => (
                                                <RadioGroup {...field} row sx={{ display: 'flex' }}>
                                                    <FormControlLabel
                                                        value="csv"
                                                        control={<Radio />}
                                                        label={t('.csv')}
                                                    />
                                                    <FormControlLabel
                                                        value="xlsx"
                                                        control={<Radio />}
                                                        label={t('.xlsx')}
                                                    />
                                                </RadioGroup>
                                            )}
                                        </Field>
                                    </FormControl>
                                </Grid>
                                {children}
                            </Grid>
                        </DialogContent>
                        <DialogActions
                            sx={{
                                position: 'sticky',
                                bottom: '0px',
                                backgroundColor: '#fff',
                                zIndex: 100,
                                borderBlockStart: '1px solid',
                                borderColor: 'divider',
                                paddingBlock: 2,
                            }}
                        >
                            <PrimaryButton
                                id="cancel-btn"
                                styles={{ backgroundColor: '#C4C4C4' }}
                                color="grey"
                                onClick={onClose}
                                text={t('Cancel')}
                            />
                            <PrimaryButton id="action-btn" text={t('Export')} disabled={isSubmitting} type="submit" />
                        </DialogActions>
                    </form>
                </FormikProvider>
            </Dialog>
        </>
    );
}
