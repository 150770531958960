import { parseJSON } from '@/components/TicketModal/utils';
import { TimeRangeTypes } from '@/components/TimeRange/Form/types';

const filterKey = 'qsr_order_filter';

interface IFilter {
    paymentStatus?: string[];
    posStatus?: string[];
    selectedStatus?: string[];
    selectedTable?: { ids: string[]; qrs: string[]; qrGroups: string[] };
    timeRange?: { type: string; from: string | null; to: string | null };
    view?: string;
}

const filters: {
    val: IFilter | null;
} = {
    val: null,
};

export const getLsValue = (): IFilter => {
    if (filters.val) {
        return filters.val;
    }

    const v = parseJSON(localStorage.getItem(filterKey));
    if (v) {
        filters.val = v || {};
    }
    return filters.val || {};
};

export const getLsItemValue = (key: keyof IFilter): any => {
    const v = getLsValue();
    if (key === 'timeRange' && v[key]?.type !== TimeRangeTypes.CUSTOM) {
        return {
            ...v[key],
            to: null,
        };
    }
    return v[key];
};

export const setLsValue = (val: IFilter) => {
    filters.val = val;
    localStorage.setItem(filterKey, JSON.stringify(val));
};

export const setLsItemValue = (key: keyof IFilter, val: any) => {
    const v = getLsValue();
    setLsValue({
        ...v,
        [key]: val,
    });
};
