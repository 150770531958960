import { Typography as MUITypography, TypographyProps } from '@mui/material';
import { merge } from 'merge';
import { FC, ReactNode } from 'react';
import { typography as qlubTypo } from './typographyTypo';
import useTypographyStyle from './styles';

export interface IProps extends TypographyProps {
    weight?: string;
    size?: string;
    typo?: keyof typeof qlubTypo;
    children: ReactNode;
    component?: React.ElementType;
    secondColor?: boolean;
}

const TypographyT: FC<IProps> = ({ weight, size, typo, children, component, secondColor, sx, ...rest }) => {
    const { main } = useTypographyStyle({ weight, size, secondColor });
    return (
        // @ts-ignore
        <MUITypography component={component} {...rest} sx={merge(true, qlubTypo[typo], main, sx)}>
            {children}
        </MUITypography>
    );
};
export default TypographyT;
