import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { useRestaurantContext } from '@/contexts/restaurant';
import { useTranslation } from '@/hooks/translations';
import { ITableData } from '@/views/Payments';

import type { IPaymentDetails } from './types';
import SubHeader from './components/SubHeader';
import { CustomerContainer } from './components/Container';
import Divider from './components/Divider';
import TableName from './components/TableName';
import { InvoiceBill, InvoiceOrder } from './components/InvoiceItem';

import styles from './index.module.scss';

interface IProps {
    details: IPaymentDetails | undefined;
    lang: string | null;
    table?: ITableData;
}

function InvoiceTable({ details, lang, table }: IProps) {
    const { t } = useTranslation('common');
    const { restaurant } = useRestaurantContext();
    const config = restaurant?.restaurant_country?.config;

    return (
        <Paper elevation={0}>
            <CustomerContainer centered direction="column" padding="0" className={styles.invoice}>
                <SubHeader
                    subTitle={restaurant?.title || ''}
                    title={
                        <TableName
                            config={config || undefined}
                            vendor={restaurant}
                            details={details}
                            placeholder={t('Your bill')}
                            table={table}
                        />
                    }
                    sx={{
                        paddingBlock: '.5rem',
                    }}
                    qaIds={{
                        subTitleId: 'billing-rest-name',
                    }}
                />

                <Box sx={{ mt: 1, width: '100%' }}>
                    <Divider />
                </Box>

                {details && (
                    <>
                        {!restaurant?.posAccess?.pos_vendor_data?.config?.disableItemView && (
                            <>
                                <div className={styles.orderTable}>
                                    <InvoiceOrder vendor={restaurant} details={details} lang={lang} />
                                </div>
                                {details._orderItems.length > 0 && (
                                    <Box sx={{ width: '100%' }}>
                                        <Divider />
                                    </Box>
                                )}
                            </>
                        )}
                        <div className={styles.orderTable}>
                            <InvoiceBill vendor={restaurant} details={details} lang={lang} />
                        </div>
                    </>
                )}
            </CustomerContainer>
        </Paper>
    );
}

export default InvoiceTable;
