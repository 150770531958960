import { ReactNode } from 'react';
import { Breakpoint } from '@mui/system';
import CloseOutlined from '@mui/icons-material/CloseOutlined';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import { useTranslation } from '@/hooks/translations';
import { PrimaryButton } from './common';

type CustomModalProps = {
    open: boolean;
    onClose: () => void;
    title?: string;
    actionText?: string;
    actionText2?: string;
    children: ReactNode;
    maxWidth?: Breakpoint | false;
    noDialogActions?: boolean;
    actionMethod?: () => void;
    actionMethod2?: () => void;
    disableButton?: boolean;
    isFullScreen?: boolean;
    keepMounted?: boolean;
};

export default function CustomModal({
    open,
    onClose,
    title,
    actionText,
    actionText2,
    children,
    actionMethod,
    actionMethod2,
    maxWidth,
    noDialogActions,
    disableButton,
    isFullScreen,
    keepMounted,
}: CustomModalProps) {
    const { t } = useTranslation('common');
    return (
        <Dialog
            onClose={onClose}
            open={open}
            fullWidth
            maxWidth={maxWidth || 'md'}
            fullScreen={isFullScreen}
            keepMounted={keepMounted}
        >
            {!!title && (
                <DialogTitle
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '30px' }}
                >
                    {title}
                    <IconButton onClick={onClose}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
            )}
            <DialogContent>{children}</DialogContent>

            {!noDialogActions && (
                <DialogActions
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: ' 1rem 1.6rem 1.6rem 1.6rem',
                        backgroundColor: '#F8F8F8',
                    }}
                >
                    <PrimaryButton
                        id="cancel-btn"
                        styles={{ backgroundColor: '#C4C4C4' }}
                        color="grey"
                        onClick={onClose}
                        text={t('Cancel')}
                    />
                    {!!actionText && (
                        <PrimaryButton
                            id="action-btn"
                            onClick={actionMethod}
                            text={actionText}
                            disabled={disableButton}
                        />
                    )}
                    {!!actionText2 && (
                        <PrimaryButton
                            id="action-btn2"
                            onClick={actionMethod2}
                            text={actionText2}
                            disabled={disableButton}
                        />
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
}
