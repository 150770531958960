import axios from '@/config/axios';
import { NotificationPayload, NotificationSettingResponse } from '@/interfaces/user/types';
import { transformError } from '.';

const endpoints = {
    USER_GET_CONFIG: '/vendor/user/config',
    USER_SET_CONFIG: '/vendor/user/config',
};

class UserService {
    public static getInstance() {
        if (!this.instance) {
            this.instance = new UserService();
        }

        return this.instance;
    }

    private static instance: UserService;

    public getRemoteNotificationSettings() {
        return axios
            .get<NotificationSettingResponse>(endpoints.USER_GET_CONFIG)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public setRemoteNotificationSettings(payload: NotificationPayload) {
        return axios
            .put<NotificationSettingResponse>(endpoints.USER_SET_CONFIG, payload)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }
}

export default UserService;
