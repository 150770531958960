import { useTranslation } from '@/hooks/translations';
import Dialog from '@mui/material/Dialog';
import { Box, Button, CircularProgress, FormHelperText, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FC, useEffect, useState } from 'react';
import QsrOrdersService from '@/services/qsrOrders';
import { useSnackbar } from 'notistack';
import TextField from '@mui/material/TextField';
import { OrderStatusEnum } from '@/views/QsrOrders/types';
import * as yup from 'yup';
import { Field, type FieldProps, FormikHelpers, FormikProvider, useFormik } from 'formik';
import FormGroup from '@mui/material/FormGroup';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import styles from './index.module.scss';

interface IReasonList {
    name: string;
    id: string;
}

interface IProps {
    open: boolean;
    onClose: () => void;
    onDone: (status: OrderStatusEnum, hasReason: boolean, reason: IReasonDetail) => void;
}

export interface IReasonDetail {
    reason_id: string;
    reason_message: string;
    reason_name?: string;
}

const defaultValues: IReasonDetail = {
    reason_id: '',
    reason_message: '',
    reason_name: '',
};

const OTHER_REASON_NAME = 'other';

const QsrReasonModal: FC<IProps> = ({ open, onClose, onDone }) => {
    const [reasonList, setReasonList] = useState<IReasonList[] | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const qsrOrdersService = QsrOrdersService.getInstance();
    const { t } = useTranslation('common');
    const { enqueueSnackbar } = useSnackbar();

    const validationSchema = yup.object({
        reason_id: yup.string().required(t('Please select a reason')),
        reason_message: yup.string(),
    });

    const getReasonList = () => {
        qsrOrdersService
            .getReasonList()
            .then((res) => {
                setReasonList(
                    res?.rows.sort((a: IReasonList, b: IReasonList) =>
                        a.name.toLowerCase() === OTHER_REASON_NAME
                            ? 1
                            : b.name.toLowerCase() === OTHER_REASON_NAME
                            ? -1
                            : 0,
                    ),
                );
            })
            .catch(() => {
                setReasonList(null);
                onClose();
                enqueueSnackbar(t('Something went wrong!'), {
                    variant: 'error',
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onSubmit = (values: IReasonDetail, { setSubmitting }: FormikHelpers<IReasonDetail>) => {
        setSubmitting(true);
        const { reason_id, reason_message } = values;
        onDone(OrderStatusEnum.Declined, true, { reason_id, reason_message });
    };

    const formik = useFormik({
        initialValues: defaultValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit,
    });

    useEffect(() => {
        if (open && !reasonList) {
            getReasonList();
        }
        formik.resetForm();
    }, [open, reasonList]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                classes: {
                    root: isLoading || !reasonList?.length ? styles.loadingContent : styles.reasonDialog,
                },
            }}
        >
            {isLoading ? (
                <Box m="auto">
                    <CircularProgress />
                </Box>
            ) : reasonList?.length ? (
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        <Typography variant="body1" className={styles.headerStyle}>
                            {t('Why are you declining the order?')}
                        </Typography>

                        <Box className={styles.checkboxList}>
                            <Field name="reason_id">
                                {({ field, form }: FieldProps) => (
                                    <FormGroup>
                                        <RadioGroup
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => {
                                                const reason = reasonList.find((r) => r.id === e.target.value);
                                                const reasonName = reason?.name || '';
                                                form.setFieldValue('reason_name', reasonName);
                                                form.setFieldValue(field.name, e.target.value);
                                            }}
                                        >
                                            {reasonList?.map((reason) => (
                                                <FormControlLabel
                                                    key={reason.id}
                                                    value={reason.id}
                                                    control={<Radio />}
                                                    label={reason.name}
                                                    className={styles.reasonLabel}
                                                />
                                            ))}
                                        </RadioGroup>
                                        {form.errors.reason_id &&
                                            form.touched.reason_id &&
                                            typeof form.errors.reason_id === 'string' && (
                                                <FormHelperText error>{form.errors.reason_id}</FormHelperText>
                                            )}
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            label={t('Reason message...')}
                                            variant="outlined"
                                            className={styles.reasonTextInput}
                                            name="reason_message"
                                            autoComplete="off"
                                            minRows={2}
                                            multiline
                                            error={Boolean(
                                                form.touched.reason_message &&
                                                    form.errors.reason_message &&
                                                    form.errors.reason_message,
                                            )}
                                            helperText={
                                                form.errors.reason_message &&
                                                form.touched.reason_message &&
                                                form.errors.reason_message
                                            }
                                        />
                                    </FormGroup>
                                )}
                            </Field>
                        </Box>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={formik.isSubmitting}
                            className={styles.errorBtn}
                            size="large"
                        >
                            {t('Submit and decline')}
                        </Button>
                        <Button fullWidth variant="outlined" onClick={onClose} size="large">
                            {t('Cancel')}
                        </Button>
                    </form>
                </FormikProvider>
            ) : null}
        </Dialog>
    );
};

export default QsrReasonModal;
