const usePriceStyle = (secondStyle?: boolean) => {
    const container = {
        fontSize: 'inherit',
        display: 'inline-block',
        textDecoration: secondStyle ? 'line-through' : 'unset',
        color: secondStyle ? '#9B9EAB' : 'inherit',
        '>span': {
            fontSize: 10,
            marginRight: '2px',
            display: 'inline-block',
            "html[dir='rtl'] &": {
                marginLeft: '2px',
            },
        },
    };
    const boldPrice = {
        fontWeight: 500,
    };
    return {
        container,
        boldPrice,
    };
};

export default usePriceStyle;
