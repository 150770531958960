import { useCallback, useEffect, useState } from 'react';
import PrintIcon from '@mui/icons-material/Print';
import { CustomButton } from '@/components/common';
import {
    EpsonPrinterService,
    PrintConnectionStatusEnum,
    PrintEventEnum,
} from '@/components/PosPrinteCenter/Printers/Epson';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from '@/hooks/translations';
import QsrOrderSettings, {
    PosPrintModeEnum,
    transformPrinterConfig,
} from '@/components/PosPrinteCenter/QsrOrderSettings';
import CustomModal from '@/components/CustomModal';
import { IVendorQsrOrderConfig } from '@/views/OrdersTableView/types';
import { isEqual } from 'lodash';
import RestaurantService from '@/services/restaurant';
import { useRestaurantContext } from '@/contexts/restaurant';
import PrintIndicator from '@/components/PrintIndicator';

interface IProps {
    loading?: boolean;
    mode?: PosPrintModeEnum;
    label?: any;
}

const PrintSettingsButton = ({ loading, mode, label }: IProps) => {
    const { t } = useTranslation('common');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(650));
    const { restaurant, setRestaurant } = useRestaurantContext();
    const [config, setConfig] = useState<IVendorQsrOrderConfig>({});
    const [printerConnectionStatus, setPrinterConnectionStatus] = useState<PrintConnectionStatusEnum>(
        PrintConnectionStatusEnum.Disconnected,
    );
    const [enablePrinter, setEnablePrinter] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const posPrintService = EpsonPrinterService.getInstance();
        setPrinterConnectionStatus(posPrintService.getConnectionStatus());
        const statusChangeFn = posPrintService.on(
            PrintEventEnum.StatusChange,
            ({ status }: { status: PrintConnectionStatusEnum }) => {
                setPrinterConnectionStatus(status);
            },
        );
        return () => {
            statusChangeFn();
        };
    }, []);

    const openHandler = useCallback(() => {
        setOpen(true);
    }, []);

    const closeHandler = useCallback(() => {
        setOpen(false);
    }, []);

    const actionHandler = useCallback(() => {
        closeHandler();
        const posPrintService = EpsonPrinterService.getInstance();
        if (enablePrinter) {
            posPrintService.init(config);
        }
        if (!isEqual(config, {}) && restaurant) {
            RestaurantService.getInstance()
                .editRestaurantOrderConfig(restaurant.id || '', {
                    orderConfig: transformPrinterConfig(config),
                })
                .then((res) => {
                    setRestaurant(res);
                });
        }
    }, [config, enablePrinter, restaurant]);

    return (
        <>
            <CustomButton
                onClick={openHandler}
                startIcon={isMobile ? null : <PrintIcon />}
                endIcon={
                    <PrintIndicator
                        status={printerConnectionStatus}
                        onReconnect={() => {
                            EpsonPrinterService.getInstance().reconnect();
                        }}
                    />
                }
                fullWidth
                sx={{
                    color: 'primary.main',
                    borderRadius: '32px',
                    padding: '10px 24px 10px 16px;',
                }}
            >
                {label || t('Print')}
            </CustomButton>
            <CustomModal
                open={open}
                title={t('Settings')}
                actionText={t('Apply')}
                maxWidth="sm"
                disableButton={loading}
                onClose={closeHandler}
                actionMethod={actionHandler}
            >
                <QsrOrderSettings
                    mode={mode}
                    onChange={(cfg, active) => {
                        setEnablePrinter(active);
                        setConfig(cfg);
                    }}
                />
            </CustomModal>
        </>
    );
};

export default PrintSettingsButton;
