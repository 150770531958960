import { useTranslation as useTranslationStatic } from 'next-export-i18n';

export const useTranslation = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ns?: string,
) => {
    const { t } = useTranslationStatic();

    return {
        t,
    };
};
