/*
    Creation Time: 2022 - April - 18
    Created by:  (hamidrezakk)
    Maintainers:
       1.  HamidrezaKK (hamidrezakks@gmail.com)
    Auditor: HamidrezaKK
    Copyright Qlub_ 2022
*/

import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import createCache from '@emotion/cache';

export default function createEmotionRtlCache() {
    return createCache({
        key: 'muirtl',
        stylisPlugins: [prefixer, rtlPlugin],
    });
}
