import { useEffect, useMemo, useState } from 'react';
import EventBus from '@/config/event-handler';
import { vendorOrderEvent, vendorOrderInvoiceEvent } from '@/contexts/event.const';
import { IOrderNotificationEvent } from '@/views/Payments';
import { convertOrderToTableRow } from '@/views/QsrOrders';
import { useLanguageQuery } from 'next-export-i18n';
import { useRestaurantContext } from '@/contexts/restaurant';
import { IQsrOrderNotification } from '@/views/QsrOrders/types';
import { MenuCategoryResponse, MenuProductResponse } from '@/services/menu/types';
import QsrOrdersService from '@/services/qsrOrders';
import { EpsonPrinterService, PrintEventEnum } from '@/components/PosPrinteCenter/Printers/Epson';
import Script from 'next/script';
import { getAllCategoriesFromOrderData, getAllProductsFromOrderData } from '@/services/utils/qsr';
import { PrintLayout } from '@/components/PosPrinteCenter/Layouts/Common';
import { activePrinterKey, getPrinterConfig } from '@/components/PosPrinteCenter/QsrOrderSettings';
import { debounce } from 'lodash';
import SSLModal from '@/components/PosPrinteCenter/SSLModal';
import { IPaymentDetailsResponse } from '@/components/InvoiceTable/types';
import { OrderPriceRoundingModeEnum, QSRPrintLayoutEnum, QSRPrintSizeEnum } from '@/views/OrdersTableView/types';

const fixCurrency = (code: string, symbol: string) => {
    if (code.toLowerCase() === 'sgd') {
        return 'SGD';
    }
    return symbol;
};

export enum ItemModeEnum {
    Order = 'order',
    InvoiceOrder = 'invoiceOrder',
}

export interface IPrintItem {
    type: ItemModeEnum;
    val: IQsrOrderNotification | IPaymentDetailsResponse;
    layout?: QSRPrintLayoutEnum;
}

export default function PosPrintCenter() {
    const qsrOrdersService = QsrOrdersService.getInstance();
    const epsonPrinterService = EpsonPrinterService.getInstance();
    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';
    const { restaurant } = useRestaurantContext();
    const [list, setList] = useState<IPrintItem[]>([]);
    const [productMap, setProductMap] = useState<{ [key: string]: MenuProductResponse }>({});
    const [categoryMap, setCategoryMap] = useState<{ [key: string]: MenuCategoryResponse }>({});
    const [scriptReady, setScriptReady] = useState(false);
    const [inTab, setInTab] = useState(false);
    const [sslModalOpen, setSslModalOpen] = useState(false);

    const loadHandler = () => {
        setScriptReady(true);
    };

    const config = useMemo(() => {
        return getPrinterConfig(restaurant);
    }, [restaurant]);

    useEffect(() => {
        const focusHandler = debounce(() => {
            setInTab(!document.hidden);
        }, 512);
        const printerFailedHandler = ({ status }: { status: string }) => {
            if (status === 'ERROR_TIMEOUT' && config.printerEncrypt) {
                setSslModalOpen(true);
            }
        };

        window.addEventListener('visibilitychange', focusHandler);
        const cancellerFn = epsonPrinterService.on(PrintEventEnum.Failed, printerFailedHandler);
        return () => {
            window.removeEventListener('visibilitychange', focusHandler);
            cancellerFn();
        };
    }, []);

    useEffect(() => {
        if (!scriptReady || !config.printerIP) {
            return;
        }

        if (sessionStorage.getItem(activePrinterKey) !== 'true') {
            return;
        }

        epsonPrinterService.init(config);
    }, [scriptReady, config]);

    useEffect(() => {
        if (!restaurant) {
            return () => {
                //
            };
        }

        const fetchDataHandler = ({ detail: msgList }: IOrderNotificationEvent) => {
            const orders = msgList.reduce<IQsrOrderNotification[]>((acc, msg) => {
                if (msg.skipPrint) {
                    return acc;
                }
                acc.push(
                    convertOrderToTableRow(
                        msg,
                        lang,
                        restaurant?.restaurant_country?.currency_symbol || '',
                        restaurant?.restaurant_country?.currency_code || '',
                        restaurant?.order_config?.priceRoundingMode || OrderPriceRoundingModeEnum.Round,
                    ),
                );
                return acc;
            }, []);

            const productIds = orders.map((order) => getAllProductsFromOrderData(order.orderData)).flat();
            const categoryIds = orders.map((order) => getAllCategoriesFromOrderData(order.orderData)).flat();
            if (productIds.length > 0 || categoryIds.length > 0) {
                Promise.all([
                    qsrOrdersService.getProductMap(restaurant.id, { ids: productIds }),
                    qsrOrdersService.getCategoryMap(restaurant.id, { ids: categoryIds }),
                ]).then(([productRes, categoryRes]) => {
                    setProductMap((o) => {
                        return {
                            ...o,
                            ...productRes,
                        };
                    });
                    if (Object.keys(categoryRes).length > 0) {
                        setCategoryMap((o) => {
                            return {
                                ...o,
                                ...categoryRes,
                            };
                        });
                    }
                    setList((li) => {
                        const tempLi = [...li];
                        orders.forEach((order) => {
                            const obj = {
                                type: ItemModeEnum.Order,
                                val: order,
                            };
                            const idx = tempLi.findIndex((o) => {
                                return (o.val as IQsrOrderNotification).id === order.id;
                            });
                            if (idx > -1) {
                                tempLi[idx] = obj;
                            } else {
                                tempLi.unshift(obj);
                            }
                        });
                        return tempLi;
                    });
                });
            }
        };

        const orderInvoiceHandler = ({ detail: orderInvoice }: { detail: IPaymentDetailsResponse }) => {
            setList((li) => {
                const tempLi = [...li];
                const obj = {
                    type: ItemModeEnum.InvoiceOrder,
                    val: orderInvoice,
                };
                const idx = tempLi.findIndex((o) => {
                    return (o.val as IPaymentDetailsResponse).diningSessionID === orderInvoice.diningSessionID;
                });
                if (idx > -1) {
                    tempLi[idx] = obj;
                } else {
                    tempLi.unshift(obj);
                }
                return tempLi;
            });
        };

        EventBus.on(vendorOrderEvent, fetchDataHandler);
        EventBus.on(vendorOrderInvoiceEvent, orderInvoiceHandler);
        return () => {
            EventBus.remove(vendorOrderEvent, fetchDataHandler);
            EventBus.remove(vendorOrderInvoiceEvent, orderInvoiceHandler);
        };
    }, [restaurant]);

    const doneHandler = (order: IPrintItem, layout?: QSRPrintLayoutEnum) => {
        setList((l) => {
            const li = [...l];
            const idx = li.findIndex((o) => {
                if (o.type === ItemModeEnum.Order) {
                    return (
                        (o.val as IQsrOrderNotification).id === (order.val as IQsrOrderNotification).id &&
                        o.layout === layout
                    );
                }
                return (
                    (o.val as IPaymentDetailsResponse).diningSessionID ===
                    (order.val as IPaymentDetailsResponse).diningSessionID
                );
            });
            if (idx > -1) {
                li.splice(idx, 1);
                return li;
            }
            return li;
        });
    };

    const buffer = useMemo<IPrintItem[]>(() => {
        const items = (
            !config?.printerAutoPrintStatus?.length
                ? list.filter((o) => {
                      if (o.type === ItemModeEnum.Order) {
                          return (o.val as IQsrOrderNotification).force || !config.printerDisableAutoPrint || false;
                      }
                      return true;
                  })
                : list.filter((o) => {
                      if (o.type === ItemModeEnum.Order) {
                          return (
                              (o.val as IQsrOrderNotification).force ||
                              (!config.printerDisableAutoPrint &&
                                  config?.printerAutoPrintStatus?.includes(
                                      (o.val as IQsrOrderNotification).orderStatus?.name || '',
                                  ))
                          );
                      }
                      return true;
                  })
        ).slice(0, 4);
        if (!config.printerLayout?.length) {
            return items;
        }
        return config.printerLayout
            .map((layout) => {
                return items.map((item) => ({
                    ...item,
                    layout,
                }));
            })
            .flat();
    }, [list, config]);

    return (
        <>
            <SSLModal
                config={config}
                open={sslModalOpen}
                onClose={() => {
                    setSslModalOpen(false);
                }}
            />
            {!config?.printerDisableAutoPrint && !inTab && (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <audio style={{ display: 'none' }} autoPlay loop src="/audio/silence.mp3" />
            )}
            <Script src="/lib/epos-2.27.0.js" onLoad={loadHandler} />
            {buffer.map((item, idx) => {
                return (
                    <PrintLayout
                        key={`${(item.val as any).id || (item.val as any).dsi || idx}_${item.layout}`}
                        vendor={restaurant}
                        order={item}
                        productMap={productMap}
                        categoryMap={categoryMap}
                        currencyCode={restaurant?.restaurant_country?.currency_code || ''}
                        currencySymbol={fixCurrency(
                            restaurant?.restaurant_country?.currency_code || '',
                            restaurant?.restaurant_country?.currency_symbol || '',
                        )}
                        roundingMode={restaurant?.order_config?.priceRoundingMode || OrderPriceRoundingModeEnum.Round}
                        itemSize={
                            config.printerSize?.[item.layout || QSRPrintLayoutEnum.Cashier] || QSRPrintSizeEnum.Normal
                        }
                        encoding={config.printerEncoding}
                        lang={config.printerLang || 'en'}
                        onDone={doneHandler}
                    />
                );
            })}
        </>
    );
}
