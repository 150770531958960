import { useEffect, useMemo, useRef, useState } from 'react';
import { Backdrop, Button, IconButton, useMediaQuery } from '@mui/material';
import Link from 'next/link';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from '@/hooks/translations';
import EventBus from '@/config/event-handler';
import { notificationSettingsUpdated, vendorOrderEvent, vendorPaymentEvent } from '@/contexts/event.const';
import { IOrderNotificationEvent, IPaymentNotificationEvent } from '@/views/Payments';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useTheme } from '@mui/material/styles';
import { clone, uniqueId } from 'lodash';
import {
    getNotificationSettings,
    INotificationSettings,
    setNotificationSettings,
} from '@/views/Settings/content/notification-settings-content';
import { NotificationType, useNotification } from '@/common/notification';
import { PopupNotification, useNotificationTranslation } from '@/components/PopupNotificiation';
import { useUserContext } from '@/contexts/user';
import UserService from '@/services/user';
import { useReactToPrint } from 'react-to-print';
import PrintableReceipt from '@/components/PrintableReceipt';
import { useRestaurantContext } from '@/contexts/restaurant';
import { VendorRole } from '@/constants/roleRestrictions';
import { IQsrOrder, NOTIFICATION_TYPES } from '@/views/QsrOrders/types';
import { IPaymentTransaction } from '@/views/Orders/types';
import { hasQlubPosSection } from '@/hooks/useSiderbarRoutes';

export default function NotificationActionCenter() {
    const userService = UserService.getInstance();
    const { t } = useTranslation('common');
    const { requestPermission, notify, setSound, stopPlaying, setLoop, setNotificationTimeout } = useNotification();
    const { user } = useUserContext();
    const { restaurant } = useRestaurantContext();
    const { getMessage } = useNotificationTranslation();
    const [open, setOpen] = useState(true);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [notifySettings, setNotifySettings] = useState<INotificationSettings>(getNotificationSettings());
    const theme = useTheme();
    const notSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const showTip = !restaurant?.config?.disableTipForVendorUsers || false;

    const enableShiftMaskForVendorAnalyst =
        (user?.userData?.role === VendorRole.VendorAnalyst && restaurant?.config?.enableShiftMaskforVendorAnalyst) ||
        false;

    // Notification
    const [messages, setMessages] = useState<IPaymentTransaction[]>([]);
    const [notifCount, setNotifCount] = useState(0);

    // Print
    const [printQueue, setPrintQueue] = useState<IPaymentTransaction[]>([]);
    const printRef = useRef<any>(undefined);
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    const printHandler = (ref: any) => {
        if (!ref) {
            return;
        }
        printRef.current = ref;
        handlePrint();
        setPrintQueue([...printQueue.slice(1)]);
    };

    const handleClose = (key: SnackbarKey) => {
        setOpen(false);
        closeSnackbar(key);
    };

    const actionComponent = (key: SnackbarKey, notificationType: NOTIFICATION_TYPES) => (
        <>
            {notSmallScreen && !enableShiftMaskForVendorAnalyst && (
                <Link
                    href={{
                        pathname:
                            notificationType === NOTIFICATION_TYPES.PAYMENT_RECEIVED
                                ? '/payments'
                                : notificationType === NOTIFICATION_TYPES.ORDER_RECEIVED
                                ? '/qsr-orders'
                                : '',
                    }}
                >
                    <Button
                        color="inherit"
                        size="small"
                        onClick={() => {
                            handleClose(key);
                        }}
                    >
                        {t('Show')}
                    </Button>
                </Link>
            )}
            <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={() => {
                    closeSnackbar(key);
                    stopPlaying();
                }}
            >
                <CloseIcon />
            </IconButton>
        </>
    );

    const handleNotification = (type: NOTIFICATION_TYPES, data: IPaymentTransaction | IQsrOrder) => {
        setNotifCount((o) => o++);
        enqueueSnackbar(getMessage(type, data), {
            action: (key) => actionComponent(key, type),
            variant: 'success',
            preventDuplicate: true,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'left',
            },
            autoHideDuration: notifySettings.type === 'sticky' ? null : notifySettings.timeout * 1000,
            persist: notifySettings.type === 'sticky',
            onClose: () => {
                if (notifCount <= 1) {
                    stopPlaying();
                }
                setNotifCount((o) => o--);
            },
        });
    };

    useEffect(() => {
        if (!user) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return () => {};
        }
        requestPermission();
        const getRemoteNotificationSettings = async () => {
            await userService
                .getRemoteNotificationSettings()
                .then((res) => {
                    if (res?.data?.config) {
                        setNotifySettings(res.data.config);
                        setNotificationSettings(res.data.config);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        getRemoteNotificationSettings();
        EventBus.on(notificationSettingsUpdated, getRemoteNotificationSettings);
        return () => {
            EventBus.remove(notificationSettingsUpdated, getRemoteNotificationSettings);
        };
    }, [user]);

    useEffect(() => {
        setSound(notifySettings.sound);
        setLoop(notifySettings.loop);
        setNotificationTimeout(notifySettings.timeout);

        const paymentHandler = ({ detail: msgList }: IPaymentNotificationEvent) => {
            if (msgList.length === 0) {
                return;
            }

            if (notifySettings.type === 'popup') {
                setMessages((o) => [...o, ...msgList]);
                setOpen(true);
            } else {
                handleNotification(NOTIFICATION_TYPES.PAYMENT_RECEIVED, msgList[0]);
            }

            if (notifySettings.printMode === 'printAfter') {
                setPrintQueue((o) => [
                    ...o,
                    ...msgList.map((mi) => ({
                        ...mi,
                        id: uniqueId(),
                    })),
                ]);
            }

            notify(NotificationType.NewPayment, t('New Payment'), t('New Payment'));
        };

        const orderHandler = ({ detail: msgList }: IOrderNotificationEvent) => {
            if (msgList.length === 0) {
                return;
            }

            if (!msgList.some((o) => !o.silent) || !hasQlubPosSection(restaurant)) {
                return;
            }

            handleNotification(NOTIFICATION_TYPES.ORDER_RECEIVED, msgList[0]);

            notify(NotificationType.NewOrder, t('New Order'), t('New Order'));
        };

        EventBus.on(vendorPaymentEvent, paymentHandler);
        EventBus.on(vendorOrderEvent, orderHandler);
        return () => {
            EventBus.remove(vendorPaymentEvent, paymentHandler);
            EventBus.remove(vendorOrderEvent, orderHandler);
        };
    }, [notifySettings]);

    useEffect(() => {
        if (messages.length === 0) {
            setOpen(false);
        }
    }, [messages]);

    useEffect(() => {
        if (!open) {
            stopPlaying();
        }
    }, [open]);

    const renderPopupNotification = useMemo(() => {
        return messages.map((o, index: number) => {
            return (
                <PopupNotification
                    key={index}
                    msg={o}
                    eventName={NOTIFICATION_TYPES.PAYMENT_RECEIVED}
                    notifySettings={notifySettings}
                    onClose={() => {
                        setMessages((li) => {
                            const clonedLi = clone(li);
                            clonedLi.splice(index, 1);
                            return clonedLi;
                        });
                    }}
                />
            );
        });
    }, [messages, open]);

    return (
        <>
            {printQueue.length > 0 && (
                <PrintableReceipt
                    key={printQueue[0].id}
                    payment={printQueue[0]}
                    restaurant={restaurant}
                    currencySymbol={restaurant?.restaurant_country?.currency_symbol || ''}
                    currencyCode={restaurant?.restaurant_country?.currency_code || ''}
                    innerRef={printHandler}
                    showTip={showTip}
                    t={t}
                />
            )}
            <Backdrop open={open && messages.length > 0} sx={{ zIndex: '100' }}>
                {renderPopupNotification}
            </Backdrop>
        </>
    );
}
