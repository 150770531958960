import { createContext, ReactNode, useState, useEffect, useContext } from 'react';
import jwtDecode from 'jwt-decode';
import {
    accessTokenKey,
    chosenRestaurantIdKey,
    restaurantPosVendorKey,
    userNameKey,
    userRoleKey,
} from '@/config/axios';

const UserContext = createContext<any>(null);

type Props = {
    children?: ReactNode;
};

interface IUser {
    restaurantId: string | null;
    posVendor: string | null;
    userData?: {
        name?: string | null;
        role?: string | null;
        email?: string | null;
    };
}

export function UserContextProvider({ children }: Props) {
    const [user, setUser] = useState<IUser | null>(null);
    useEffect(() => {
        const token = localStorage.getItem(accessTokenKey);
        if (token) {
            const restaurantId = localStorage.getItem(chosenRestaurantIdKey);
            const posVendor = localStorage.getItem(restaurantPosVendorKey);
            const name = localStorage.getItem(userNameKey);
            const role = localStorage.getItem(userRoleKey);
            setUser({
                ...jwtDecode(token),
                restaurantId,
                posVendor,
                userData: {
                    name,
                    role,
                },
            });
        }
    }, []);

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    return <UserContext.Provider value={{ user, setUser }}> {children} </UserContext.Provider>;
}

export const useUserContext = () => useContext(UserContext);
