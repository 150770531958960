import { useMemo, useState } from 'react';
import { Typography, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from '@/hooks/translations';

import { useLanguageQuery } from 'next-export-i18n';

import EventBus from '@/config/event-handler';

const FooterContainer = styled('div')({
    padding: '0.5rem 1.4rem',
    borderTop: '4px #f8f8f8 solid',
    display: 'flex',
    justifyContent: 'space-between',
});

// const links = [
//     {
//         title: 'Privacy policy',
//         link: '#',
//     },
//     {
//         title: 'Terms Of Use',
//         link: '#',
//     },
//     {
//         title: 'Legal',
//         link: '#',
//     },
// ];

const countryContactUrls: { [key: string]: string } = {
    default: 'https://join.qlub.io/uae/?utm_source=foodics&utm_medium=marketplace',
};

interface IProps {
    isAuth: boolean;
}

export default function Footer({ isAuth }: IProps) {
    const { t } = useTranslation('common');

    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';
    const [showFooterHelper, setShowFooterHelper] = useState(isAuth);

    const contactUrl = useMemo(() => {
        return countryContactUrls[lang] || countryContactUrls.default;
    }, [lang]);

    EventBus.on('login', () => {
        setShowFooterHelper(false);
    });
    EventBus.on('logout', () => {
        setShowFooterHelper(true);
    });

    return showFooterHelper ? (
        <FooterContainer>
            <Typography
                variant="subtitle1"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {t('Don’t have an account?')}&nbsp;
                <Link key={1} href={contactUrl} underline="none" sx={{ marginRight: '3rem' }}>
                    {t('Contact us now')}
                </Link>
            </Typography>

            {/* <div>
                <Typography variant="subtitle1">{t('Do you have any Questions? Please contact us.')}</Typography>
                <div style={{ display: 'flex' }}>
                    {links.map((link) => (
                        <Link key={link.title} href={link.link} underline="none" sx={{ marginRight: '3rem' }}>
                            <Typography sx={{ color: '#000', fontWeight: 700 }}>{link.title}</Typography>
                        </Link>
                    ))}
                </div>
            </div> */}
        </FooterContainer>
    ) : null;
}
