import axios from '@/config/axios';
import {
    DownloadBrandOrderParams,
    DownloadRestaurantOrderParams,
    OrdersResponse,
    PosApiCallRecordParams,
    PosApiCallRecordResponse,
    SendEmailRequestBody,
} from '@/interfaces/order/types';
import { transformError } from '.';

const endpoints = {
    TRANSACTIONS: 'vendor/table-session/:restaurant_id',
    DOWNLOAD_RESTAURANT_ORDER: 'vendor/order/download/:restaurant_id',
    DOWNLOAD_BRAND_ORDER: 'vendor/order/brand/download/:brand_id',
    ORDER_NOTIFICATION: 'vendor/payment-record/:restaurant_id',
    ORDER_NOTIFICATION_SEARCH: 'vendor/order-notification-search/:restaurant_id',
    POS_API_CALL_RECORDS: 'vendor/transaction/pos-api-call-record/:restaurant_id',
    SEND_ORDER_LINK: 'admin/mail/vpos',
};

class OrdersService {
    public static getInstance() {
        if (!this.instance) {
            this.instance = new OrdersService();
        }

        return this.instance;
    }

    private static instance: OrdersService;

    public async getOrders(
        restaurantId: string,
        { page = 0, limit = 10, ...requestData }: any,
    ): Promise<OrdersResponse> {
        try {
            const res = await axios.get<any>(endpoints.TRANSACTIONS.replace(':restaurant_id', restaurantId), {
                params: {
                    page,
                    limit,
                    ...(requestData.type ? { type: requestData.type } : {}),
                    ...(requestData.tableName ? { tableName: requestData.tableName } : {}),
                    ...(requestData.orderId ? { orderId: requestData.orderId } : {}),
                    ...(requestData.sortBy
                        ? {
                              sortColumn: requestData.sortBy.column,
                              sortBy: requestData.sortBy.order,
                          }
                        : {}),
                    ...(requestData.startDate ? { startDate: requestData.startDate } : {}),
                    ...(requestData.endDate ? { endDate: requestData.endDate } : {}),
                    ...(requestData.period ? { period: requestData.period } : {}),
                    ...(requestData.noPayment ? { noPayment: requestData.noPayment } : {}),
                },
            });
            return res.data?.data;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async downloadRestaurantOrder(restaurantId: string, params: DownloadRestaurantOrderParams) {
        try {
            const res = await axios({
                url: endpoints.DOWNLOAD_RESTAURANT_ORDER.replace(':restaurant_id', restaurantId),
                method: 'GET',
                responseType: 'blob',
                params: {
                    ...params,
                    ...(params.startDate ? { startDate: params.startDate } : {}),
                    ...(params.endDate ? { endDate: params.endDate } : {}),
                },
            });
            return res;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async downloadBrandOrder(brandId: string, params: DownloadBrandOrderParams) {
        try {
            const res = await axios({
                url: endpoints.DOWNLOAD_BRAND_ORDER.replace(':brand_id', brandId),
                method: 'GET',
                responseType: 'blob',
                params,
            });
            return res;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async orderNotification(restaurantId: string, { page = 0, limit = 10, ...requestData }: any): Promise<any> {
        try {
            const res = await axios.get<any>(endpoints.ORDER_NOTIFICATION.replace(':restaurant_id', restaurantId), {
                params: {
                    page,
                    limit,
                    ...(requestData.type ? { type: requestData.type } : {}),
                    ...(requestData.tableName ? { tableName: requestData.tableName } : {}),
                    ...(requestData.orderId ? { orderId: requestData.orderId } : {}),
                    ...(requestData.sortBy
                        ? {
                              sortColumn: requestData.sortBy.column,
                              sortBy: requestData.sortBy.order,
                          }
                        : {}),
                    ...(requestData.startDate ? { startDate: requestData.startDate } : {}),
                    ...(requestData.endDate ? { endDate: requestData.endDate } : {}),
                    ...(requestData.period ? { period: requestData.period } : {}),
                    ...(requestData.noPayment ? { noPayment: requestData.noPayment } : {}),
                    ...(requestData.status ? { paymentStatus: requestData.status } : {}),
                    ...(requestData.search ? { search: requestData.search } : {}),
                },
            });
            return res.data?.data;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async orderNotificationSearch(restaurantId: string) {
        try {
            const res = await axios({
                url: endpoints.ORDER_NOTIFICATION_SEARCH.replace(':restaurant_id', restaurantId),
                method: 'GET',
            });
            return res;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async getPosApiCallRecords(
        restaurantId: string,
        params: PosApiCallRecordParams,
    ): Promise<PosApiCallRecordResponse> {
        try {
            const res = await axios.get<any>(endpoints.POS_API_CALL_RECORDS.replace(':restaurant_id', restaurantId), {
                params,
            });
            return res.data?.data;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async sendEmailOrderLink(data: SendEmailRequestBody) {
        try {
            const res = await axios.post(endpoints.SEND_ORDER_LINK, data);
            return res;
        } catch (err) {
            throw transformError(err);
        }
    }
}

export default OrdersService;
