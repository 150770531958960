import { useTheme, SxProps } from '@mui/material';

const useTypographyStyle = ({
    weight,
    size,
    secondColor,
}: {
    weight?: string;
    size?: string;
    secondColor?: boolean;
}) => {
    const theme = useTheme();
    const main: SxProps = {
        ...(weight && { fontWeight: weight }),
        ...(size && { fontSize: size }),
        color: secondColor ? theme.palette.primary.main : 'inherit',
    };

    return { main };
};

export default useTypographyStyle;
