import { v4 as uuidv4 } from 'uuid';

import { IRestaurant } from '@/views/OrdersTableView/types';

import { getDefaultBillLayout, getDefaultOrderLayout } from './defaults';
import type {
    IExplodedItemView,
    IGetPaymentDetailConfig,
    IKeyValue,
    ILayout,
    IOrderItemView,
    IPaymentDetails,
    IPaymentDetailsBill,
    IPaymentDetailsOrderItems,
    IPaymentDetailsResponse,
    IPaymentPartyUI,
    IPromoCode,
} from './types';
import { AdditiveCategoryEnum, CampaignVoucherStateEnum, ColorEnum, SplitModeTypeEnum, StyleEnum } from './types';

const session: {
    id: string | undefined;
} = {
    id: undefined,
};

const hasCookieAccess = () => {
    if (typeof window === 'undefined' || !navigator.cookieEnabled) {
        return false;
    }
    try {
        return window.localStorage;
    } catch {
        return false;
    }
};

const sessionStorageMap: { [key: string]: any } = {};

const qlubSessionStorageSet = (key: string, value: any) => {
    if (hasCookieAccess()) {
        sessionStorage.setItem(key, value);
    }
    sessionStorageMap[key] = value;
};

const qlubSessionStorageGet = (key: string) => {
    if (hasCookieAccess()) {
        return sessionStorage.getItem(key);
    }
    return sessionStorageMap[key];
};

// const qlubSessionStorageRemove = (key: string) => {
//     if (hasCookieAccess()) {
//         return sessionStorage.removeItem(key);
//     }
//     delete sessionStorageMap[key];
// };

export const qlubSessionStorage = {
    set: qlubSessionStorageSet,
    get: qlubSessionStorageGet,
    // remove: qlubSessionStorageRemove,
};

export const extractBillTax = (bill: IPaymentDetailsBill): number => {
    return Number(
        bill?.tax ??
            bill?.additives
                ?.reduce((acc, item) => {
                    if (item.category === 'tax') {
                        if (item.valueType === 'fixed') {
                            acc += Number(item.value ?? '0');
                        } else {
                            acc += (Number(item.value ?? '0') / 100) * Number(bill?.total);
                        }
                    }
                    return acc;
                }, 0)
                ?.toFixed(2) ??
            0,
    );
};

export const extractLoyaltyPaidAmount = (parties: IPaymentPartyUI[] = []): number => {
    return parties.reduce((acc, party) => {
        if (party.id === '_QlubLoyalty' && party.paymentDone) {
            acc += Number(party.amount);
        }
        return acc;
    }, 0);
};

function getSessionKey() {
    return 'qlub.session';
}

export function getSession(defaultSessionId?: string) {
    if (defaultSessionId) {
        session.id = defaultSessionId;
        return defaultSessionId;
    }
    if (session.id) {
        return session.id || '1';
    }
    const s = qlubSessionStorage.get(getSessionKey());
    if (s) {
        session.id = s;
    } else {
        session.id = uuidv4();
        qlubSessionStorage.set(getSessionKey(), session.id);
    }
    return session.id || '1';
}

export const getNumberOfPaidItems = (parties: IPaymentPartyUI[], index: number, sig: string): number => {
    return parties?.reduce<number>((a, party) => {
        if (party.paymentDone) {
            const tmp = party.billSplit?.items?.find((item) => item.index === index && item.sig === sig);
            if (tmp) {
                a += Number(tmp?.qty);
            }
        }
        return a;
    }, 0);
};

const decideSplitMode = (
    parties: IPaymentPartyUI[],
    sessionId: string,
    orderItems?: IOrderItemView[],
): SplitModeTypeEnum => {
    if (parties.length === 0) {
        return SplitModeTypeEnum.empty;
    }
    let paidSplitMode: SplitModeTypeEnum = SplitModeTypeEnum.empty;
    let firstSplitMode: SplitModeTypeEnum = SplitModeTypeEnum.empty;
    parties.forEach((party) => {
        if (!paidSplitMode && party.billSplit?.splitMode && party.paidAt) {
            paidSplitMode = party.billSplit?.splitMode;
        }
        if (!firstSplitMode && party.billSplit?.splitMode && party.id === sessionId) {
            firstSplitMode = party.billSplit?.splitMode;
        }
    });
    // @ts-ignore
    if (paidSplitMode && paidSplitMode !== SplitModeTypeEnum.custom && orderItems?.some((o) => o.updatedAt)) {
        return SplitModeTypeEnum.custom;
    }

    return paidSplitMode || firstSplitMode;
};

const getYourSplitSettings = (parties: IPaymentPartyUI[], sessionId: string): IPaymentPartyUI => {
    const party = parties.find((o) => o.id === sessionId);
    if (party) {
        return party;
    }
    return {
        id: sessionId,
        name: '',
        amount: '0',
        amountNumber: 0,
        billSplit: {
            splitMode: SplitModeTypeEnum.unknown,
            items: [],
            share: 0,
            totalShares: 0,
            meta: null,
        },
    };
};

const getOtherSplitSettings = (parties: IPaymentPartyUI[], sessionId: string): IPaymentPartyUI => {
    return parties.reduce<IPaymentPartyUI>(
        (prevValue, party) => {
            if (!party.paymentDone || party.id === sessionId) {
                return prevValue;
            }
            prevValue.amount += party.amount;
            prevValue.billSplit.totalShares = party.billSplit.totalShares;
            if (party.billSplit.share) {
                prevValue.billSplit.share += party.billSplit.share;
            }
            if (!prevValue.billSplit.items) {
                prevValue.billSplit.items = [];
            }

            party.billSplit.items?.forEach((item) => {
                const idx = prevValue.billSplit.items?.findIndex((o) => o.index === item.index) || -1;
                if (idx > -1 && prevValue.billSplit.items && prevValue.billSplit.items[idx]) {
                    prevValue.billSplit.items[idx].qty += item.qty;
                } else {
                    prevValue.billSplit.items?.push(item);
                }
            });
            prevValue.paymentDone = party.paymentDone;

            return prevValue;
        },
        {
            id: 'other',
            amount: '0',
            amountNumber: 0,
            paymentDone: false,
            paidAt: 0,
            name: '',
            nonQlub: false,
            billSplit: {
                splitMode: SplitModeTypeEnum.unknown,
                items: [],
                share: 0,
                totalShares: 0,
                meta: null,
            },
        },
    );
};

const checkYourShare = (details: IPaymentDetails, yourSplitSettings: IPaymentPartyUI) => {
    if (
        details.billSplit?.mode === SplitModeTypeEnum.byShare &&
        !yourSplitSettings.paymentDone &&
        yourSplitSettings.billSplit.totalShares !== details?.billSplit.totalSharesNumber &&
        yourSplitSettings.billSplit.share &&
        details.billSplit?.shareAmount
    ) {
        const newShare = details.billSplit.shareAmountNumber * yourSplitSettings.billSplit.share;
        details.billNumber.yourShare = newShare;
        details.bill.yourShare = String(newShare);
    }
    return details;
};

export const getRemainingSplitItems = (details: IPaymentDetails) => {
    const allItems = details._orderItems.reduce<{ [key: number]: number }>((a, b, idx) => {
        a[idx] = Number(b.item.qty);
        return a;
    }, {});
    details.otherSplitSettings.billSplit.items?.forEach((item) => {
        const a = allItems[item.index];
        if (a !== undefined) {
            allItems[item.index] -= item.qty;
        }
    });
    return allItems;
};

const removePaidItemFromPartiesSelected = (details: IPaymentDetails) => {
    if (!details.billSplit || details.billSplit?.mode !== SplitModeTypeEnum.byItem) {
        return details;
    }
    const newitems = details.yourSplitSettings.billSplit.items?.filter((item) => {
        const currentItem = details.explodedItems?.find(
            (eItem) => eItem.sig === item.sig && eItem.index === item.index,
        );
        return currentItem?.paidItemsQty === 0;
    });
    if (newitems?.length !== details.yourSplitSettings.billSplit.items?.length) {
        details.billSplit.conflict = true;
        details.yourSplitSettings.billSplit.items = newitems;
    }
    return details;
};

const getCampaignVoucherVisibilityState = (details: IPaymentDetails): CampaignVoucherStateEnum => {
    // if one party split the bill and pay but don't apply the voucher others can't see the voucher box
    if (details.otherSplitSettings.paymentDone && !details.campaignVoucher?.redeemed) {
        return CampaignVoucherStateEnum.Hidden;
    }

    // in case the party splitted the bill and voucher is not applied
    if (details.yourSplitSettings.paymentDone) {
        return CampaignVoucherStateEnum.Hidden;
    }
    // in case bill is fully paid and voucher not applied
    if (details.billNumber.remaining === 0 && !details.campaignVoucher) {
        return CampaignVoucherStateEnum.Hidden;
    }

    // in case another party paid a share and current party chose the share
    if (
        (details.campaignVoucher?.redeemed || details.campaignVoucher?.code) &&
        details.billSplit?.mode &&
        details.billNumber.remaining > 0 &&
        details.otherSplitSettings.paymentDone &&
        details.yourSplitSettings.billSplit.splitMode &&
        details.yourSplitSettings.amount
    ) {
        return CampaignVoucherStateEnum.Used;
    }

    // if voucher is redeemed by other parties or other parties applied the voucher(hasn't redeemed yet)
    if (
        (details.campaignVoucher?.redeemed && !details.campaignVoucher?.isVoucherOwner) ||
        (details.campaignVoucher?.code && !details.campaignVoucher?.isVoucherOwner)
    ) {
        return CampaignVoucherStateEnum.Hidden;
    }

    return CampaignVoucherStateEnum.Visible;
};

const getExplodedItems = (details: IPaymentDetails): IExplodedItemView[] => {
    const itemMap = details._orderItems?.reduce<{ [key: string]: IPaymentDetailsOrderItems }>((a, b) => {
        a[b.item.sig || ''] = b;
        return a;
    }, {});
    return (
        details.explodedItems?.map((o) => {
            return {
                ...o,
                item: itemMap[o.sig].item,
                selectedItemsQty:
                    (!details.yourSplitSettings.paymentDone &&
                        details.yourSplitSettings.billSplit.items?.find((i) => i.index === o.index)?.qty) ||
                    0,
                paidItemsQty: getNumberOfPaidItems(details.parties || [], o.index, o.sig),
            };
        }) || []
    );
};

const transformKeys = (details: IPaymentDetailsResponse): IPaymentDetails => {
    const sessionId = getSession();
    const parties: IPaymentPartyUI[] =
        details.parties?.map((party) => ({
            ...party,
            amountNumber: Number(party.amount),
            billSplit: {
                ...party?.billSplit,
                share: Number(party.billSplit?.share),
                totalShares: Number(party.billSplit?.totalShares),
                items: party.billSplit?.items?.map((item) => ({
                    ...item,
                    qty: Number(item.qty),
                })),
            },
        })) || [];
    const yourSplitSettings = getYourSplitSettings(parties, sessionId);
    let yourShare = Number(details.bill?.yourShare || '0');
    if (yourSplitSettings.loyalty?.status === 'ok' && yourSplitSettings.amountNumber) {
        yourShare += Number(yourSplitSettings.loyalty?.amount ?? 0);
    }
    const transformedDetails: IPaymentDetails = {
        ...details,
        bill: {
            ...details.bill,
            subTotal: details.bill?.billAmount || '0',
            total: details.bill?.payableAmount || '0',
            isDinerFeeEnabled: details.bill?.additives?.some(
                (additive) => additive.category === AdditiveCategoryEnum.Commision,
            ),
        },
        billNumber: {
            billAmount: Number(details.bill?.billAmount || '0'),
            paid: Number(details.bill?.paid || '0'),
            payableAmount: Number(details.bill?.payableAmount || '0'),
            tax: Number(details.bill?.tax || '0'),
            vat: Number(details.bill?.vat || '0'),
            qlubDiscount: Number(details.bill?.qlubDiscount || '0'),
            remaining: Number(details.bill?.remaining || '0'),
            yourShare,
            yourShareCents: Number((yourShare * 100).toFixed(0)),
            yourCommission: Number(details.bill?.yourCommission || '0'),
            subTotal: Number(details.bill?.billAmount || '0'),
            total: Number(details.bill?.payableAmount || '0'),
            voucherAmount: Number(details?.bill?.additives?.find((a) => a.key === 'qlub-voucher')?.value) || 0,
        },
        billSplit: details.billSplit
            ? {
                  ...details.billSplit,
                  totalSharesNumber: Number(details.billSplit.totalShares),
                  shareAmountNumber: Number(details.billSplit.shareAmount),
                  mode: details.billSplit.mode || decideSplitMode(parties, sessionId, details.orderItems),
              }
            : undefined,
        billLoyalty: {
            tax: extractBillTax(details.bill),
            paid: extractLoyaltyPaidAmount(parties as any),
        },
        parties,
        yourSplitSettings,
        otherSplitSettings: getOtherSplitSettings(parties, sessionId),
        _billItems: [
            {
                key: 'def_qlubDiscount',
                value: details.bill.qlubDiscount,
            },
            {
                key: 'def_paid',
                value: details.bill.paid,
            },
            {
                key: 'def_remaining',
                value: details.bill.remaining,
            },
            {
                key: 'def_subTotal',
                value: details.bill.billAmount,
            },
            {
                key: 'def_tax',
                value: details.bill.tax,
            },
            {
                key: 'def_vat',
                value: details.bill.vat,
            },
            {
                key: 'def_total',
                value: details.bill.payableAmount,
            },
            {
                key: 'def_yourShare',
                value: details.bill.yourShare,
            },
            {
                key: 'def_billAmount',
                value: details.bill.billAmount,
            },
            {
                key: 'def_payableAmount',
                value: details.bill.payableAmount,
            },
            ...(details.bill.additives?.map((item) => ({
                key: item.key,
                value: item.amount || item.value,
                title: item.title,
            })) || []),
        ],
        _orderItems:
            details.orderItems?.map((item) => ({
                item: {
                    key: 'def_order',
                    title: item.title,
                    value: Number(item.finalPrice || item.subTotal || '0')
                        ? item.finalPrice || item.subTotal
                        : item.unitPrice,
                    altValue: Number(item.finalPrice || item.subTotal || '0') ? item.unitPrice : undefined,
                    secondAltValue: item.finalUnitPrice,
                    qty: item.qty,
                    updatedAt: item.updatedAt,
                    sig: item.sig,
                },
                items: [
                    ...(item.toppings?.map((innerItem) => ({
                        key: 'def_order_topping',
                        title: innerItem.title,
                        value: innerItem.finalPrice || innerItem.subTotal,
                        altValue: innerItem.unitPrice,
                        qty: innerItem.qty,
                        updatedAt: innerItem.updatedAt,
                    })) || []),
                    ...(item.additives?.map((innerItem) => ({
                        key: innerItem.key || 'def_additives',
                        title: innerItem.title,
                        value: innerItem.value || innerItem.amount,
                    })) || []),
                ],
            })) || [],
    };
    transformedDetails.campaignVoucherVisibilityState = getCampaignVoucherVisibilityState(transformedDetails);
    transformedDetails.explodedItems = getExplodedItems(transformedDetails);
    return removePaidItemFromPartiesSelected(checkYourShare(transformedDetails, yourSplitSettings));
};

const sortKeys = (keys: IKeyValue[], layout: ILayout[]): IKeyValue[] => {
    return layout.reduce<IKeyValue[]>((a, b, idx) => {
        const items = keys.filter((o) => o.key === b.key);
        if (items.length > 0) {
            a.push(
                ...items.map((o) => ({
                    ...o,
                    layout: b,
                    order: idx,
                })),
            );
        }
        return a;
    }, []);
};
// temporary filter
const filterBillLayout = (layout: ILayout[]): ILayout[] => {
    return layout?.map((item) => ({
        ...item,
        style: StyleEnum.BodyMedium,
    }));
};

const filterOrderLayout = (layout: ILayout[]): ILayout[] => {
    return layout.map((item) => {
        if (item.key === 'def_order_topping') {
            return {
                ...item,
                color: ColorEnum.LowEmphasis,
            };
        }
        return item;
    });
};

const getCampaignData = (details: IPaymentDetailsResponse) => {
    const sessionId = getSession();
    const activeCampaign = details?.campaign?.appliedVouchers[0];
    if (!activeCampaign) {
        return undefined;
    }
    if (sessionId === activeCampaign.partyID) {
        return {
            ...activeCampaign,
            isVoucherOwner: true,
        };
    }
    return {
        ...activeCampaign,
        isVoucherOwner: false,
    };
};

export const checkIsPromoDisabled = (vendor: IRestaurant, details: IPaymentDetails, splitByOtherNotPaid: boolean) => {
    if (!vendor?.config?.disableSplitWithPromo) {
        return false;
    }

    // when another party split the bill
    if (
        details.billSplit?.mode &&
        details.billSplit?.mode !== SplitModeTypeEnum.unknown &&
        !(details.campaignVoucher?.code || details.campaignVoucher?.redeemed)
    ) {
        return true;
    }
    // when the current party split the bill
    if (
        details.yourSplitSettings.billSplit.splitMode &&
        details.yourSplitSettings.billSplit.splitMode !== SplitModeTypeEnum.unknown &&
        !(details.campaignVoucher?.code || details.campaignVoucher?.redeemed)
    ) {
        return true;
    }
    // when another party splitted the bill but has not paid yet
    return splitByOtherNotPaid;
};

export const checkPreventOpeningPromoModalInButtonView = (vendor: IRestaurant, details: IPaymentDetails) => {
    if (!vendor?.config?.disableSplitWithPromo) {
        return false;
    }
    if (
        (details.otherSplitSettings.paymentDone && details.campaignVoucher?.redeemed) ||
        ((details.campaignVoucher?.code || details.campaignVoucher?.redeemed) &&
            !details.campaignVoucher.isVoucherOwner)
    ) {
        return true;
    }
    return false;
};

const getPromoConditions = (
    vendor: IRestaurant | undefined | null,
    details: IPaymentDetails,
): IPromoCode | undefined => {
    if (!vendor || !vendor.config?.disableSplitWithPromo) {
        return undefined;
    }

    const hasSplit = details.parties?.some(
        (p) => p.billSplit.splitMode && p.billSplit.splitMode !== SplitModeTypeEnum.unknown,
    );
    const splitByOtherNotPaid =
        ((!details.billSplit?.mode || details.billSplit?.mode === SplitModeTypeEnum.unknown) && hasSplit) || false;
    const disableSplitButton =
        (vendor.config.disableSplitWithPromo &&
            (details.campaignVoucher?.redeemed || !!details.campaignVoucher?.code)) ||
        false;
    return {
        isPromoDisabled: checkIsPromoDisabled(vendor, details, splitByOtherNotPaid),
        splitByOtherNotPaid, // toast message texts varies upon this condition
        preventOpenModal: checkPreventOpeningPromoModalInButtonView(vendor, details),
        disableSplitButton,
    };
};

function parse(json: string) {
    try {
        return JSON.parse(json);
    } catch (ex) {
        return null;
    }
}

export const parsePaymentDetails = (
    details: IPaymentDetailsResponse,
    config?: IGetPaymentDetailConfig,
): IPaymentDetails => {
    const itemOrderLayout = parse(config?.vendor?.posAccess?.pos_vendor_data?.itemLayout);
    const itemBillLayout = parse(config?.vendor?.posAccess?.pos_vendor_data?.billLayout);

    const res = transformKeys(details);
    const orderLayout =
        (itemOrderLayout || []).length > 0
            ? filterOrderLayout(itemOrderLayout)
            : getDefaultOrderLayout(config?.cc || 'en');
    const defOrderLayout = orderLayout.find((o) => o.key === 'def_order');
    const billLayout =
        (itemBillLayout || []).length > 0 ? filterBillLayout(itemBillLayout) : getDefaultBillLayout(config?.cc || 'en');
    res._billItems = sortKeys(res._billItems, billLayout);
    res._orderItems = res._orderItems.map((o) => {
        return {
            ...o,
            item: {
                ...o.item,
                key: 'def_order',
                layout: defOrderLayout,
            },
            items: sortKeys(o.items, orderLayout),
        };
    });
    if (
        config?.vendor?.posAccess?.pos_vendor_data?.billLayout ||
        config?.vendor?.posAccess?.pos_vendor_data?.itemLayout
    ) {
        res._layoutInit = true;
    }
    res.campaignVoucher = getCampaignData(details);
    res.campaignVoucherVisibilityState = getCampaignVoucherVisibilityState(res);
    res.promoConditions = getPromoConditions(config?.vendor, res);
    return res;
};
