import MenuIcon from '@mui/icons-material/Menu';
import EventBus from '@/config/event-handler';
import { IconButton } from '@mui/material';
import { Logo } from '@/components/icon/logo';
import { styled } from '@mui/material/styles';

const NavBarMenuContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

interface Props {
    withLogo: boolean;
}

export default function HamburgerMenuIcon({ withLogo }: Props) {
    return (
        <NavBarMenuContainer>
            <IconButton
                sx={{
                    color: '#616475',
                    marginRight: '1rem',
                    '&:hover': {
                        color: 'primary.main',
                    },
                }}
                onClick={() => {
                    EventBus.dispatch('open_menu');
                }}
            >
                <MenuIcon />
            </IconButton>

            {withLogo && <Logo />}
        </NavBarMenuContainer>
    );
}
