import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

import { AdminRole, VendorRole } from '@/constants/roleRestrictions';
import { useRestaurantContext } from '@/contexts/restaurant';
import { useUserContext } from '@/contexts/user';
import { chosenRestaurantIdKey, countryCode, restaurantPosVendorKey } from '@/config/axios';
import type { ITinyRestaurant } from '@/views/OrdersTableView/types';
import RestaurantService from '@/services/restaurant';
import { convertRestaurantToTiny, SelectRestaurant } from '../SelectRestaurant';

const vendorSelectRoles = [
    AdminRole.SuperAdmin,
    AdminRole.Admin,
    AdminRole.Analyst,
    AdminRole.CountryMonitor,
    VendorRole.BrandAnalyst,
    AdminRole.Monitor,
    AdminRole.CountryAnalyst,
    VendorRole.BrandAdmin,
];

export const RestaurantSelection = () => {
    const restaurantService = RestaurantService.getInstance();

    const { user, setUser } = useUserContext();
    const { restaurant, setRestaurant } = useRestaurantContext();

    const changeHandler = (res: ITinyRestaurant | null) => {
        if (res) {
            localStorage.setItem(chosenRestaurantIdKey, res.id);
            setUser((prevValue: any) => ({
                ...prevValue,
                restaurantId: res.id,
                posVendor: res.pos_vendor_name,
            }));
            restaurantService
                .getRestaurant(res.id)
                .then((data) => {
                    setRestaurant(data);
                    localStorage.setItem(countryCode, data.country_code);
                    localStorage.setItem(restaurantPosVendorKey, data?.posAccess?.posVendor?.pos_vendor_name || '');
                })
                .catch((err) => console.log(err));
        }
    };

    if (vendorSelectRoles.includes(user.userData.role)) {
        return (
            <FormControl fullWidth>
                <SelectRestaurant value={convertRestaurantToTiny(restaurant)} onChange={changeHandler} />
            </FormControl>
        );
    }
    return <Typography variant="subtitle1">{restaurant?.name}</Typography>;
};
