import { ITransaction } from '@/views/Payments';

export type PaymentStatus = 'not_paid' | 'paid' | 'paid_partially';

export const getPaymentStatus = (
    transaction: ITransaction,
    enableExternalPaidFlagInTableView?: boolean,
): PaymentStatus => {
    if (!transaction) {
        return 'not_paid';
    }

    const filteredTransactions = {
        ...transaction,
        paymentRecord: transaction.paymentRecord
            ? transaction.paymentRecord.filter((item) => item.status !== 'rejected')
            : [],
    };

    let paidAmount = 0;
    let customerCommission = 0;
    if (filteredTransactions.paymentRecord && filteredTransactions.paymentRecord[0]) {
        filteredTransactions.paymentRecord.map((item) => {
            paidAmount += Number(item.paid_amount);
            customerCommission += item.customer_commission;
            return true;
        });
    }
    if (
        (filteredTransactions.paid && enableExternalPaidFlagInTableView) ||
        Number((paidAmount - customerCommission).toFixed(3)) >= Number(filteredTransactions.bill_amount)
    ) {
        return 'paid';
    }

    if (paidAmount > 0) {
        return 'paid_partially';
    }

    return 'not_paid';
};
