import { Box, CircularProgress } from '@mui/material';
import React from 'react';

export default function Loading() {
    return (
        <Box
            sx={{
                width: '100%',
                height: '15rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <CircularProgress />
        </Box>
    );
}
