/*
    Creation Time: 2021 - Sep - 26
    Created by:  (hamidrezakk)
    Maintainers:
       1.  HamidrezaKK (hamidrezakks@gmail.com)
    Auditor: HamidrezaKK
    Copyright Qlub_ 2022
*/

import { format, isAfter, subDays, formatDistance, fromUnixTime, addSeconds } from 'date-fns';
import { getLocale } from '@/services/utils/fns_locale';

export function timeUnix() {
    return Math.floor(Date.now() / 1000);
}

export function timeConversion(duration: number) {
    const portions: string[] = [];

    const msInHour = 1000 * 60 * 60;
    const hours = Math.trunc(duration / msInHour);
    if (hours > 0) {
        portions.push(`${hours}h`);
        duration -= hours * msInHour;
    }

    const msInMinute = 1000 * 60;
    const minutes = Math.trunc(duration / msInMinute);
    if (minutes > 0) {
        portions.push(`${minutes}m`);
        duration -= minutes * msInMinute;
    }

    const seconds = Math.trunc(duration / 1000);
    if (seconds > 0) {
        portions.push(`${seconds}s`);
        duration -= seconds * msInMinute;
    }

    const milliSeconds = Math.trunc(duration);
    if (milliSeconds > 0) {
        portions.push(`${milliSeconds}ms`);
    }

    return portions.join(' ');
}

export const getDateAndTime = (dateStr?: string | Date, lang?: string): string => {
    if (!dateStr) {
        return '';
    }

    const date = typeof dateStr === 'string' ? new Date(dateStr) : dateStr;
    if (String(date) === 'Invalid Date') {
        return String(dateStr);
    }
    return format(date, lang === 'ja' ? 'yyyy/MM/dd, HH:mm:ss' : 'dd/MM/yyyy, HH:mm:ss');
};

export const getRelativeTime = (dateStr?: string | Date, lang?: string): string => {
    if (!dateStr) {
        return '';
    }

    const date = typeof dateStr === 'string' ? new Date(dateStr) : dateStr;
    if (String(date) === 'Invalid Date') {
        return String(dateStr);
    }
    const now = new Date();
    if (isAfter(date, subDays(now, 15))) {
        return formatDistance(date, now, {
            locale: getLocale(lang || 'en'),
            includeSeconds: true,
            addSuffix: true,
        });
    }
    return getDateAndTime(date, lang);
};

export const getDateFromUnix = (unix: number) => {
    return fromUnixTime(unix);
};

export const addXSeconds = (x: number) => {
    const currentDate = new Date();
    const newDate = addSeconds(currentDate, x);
    return format(newDate, 'yyyy/MM/dd HH:mm:ss');
};
